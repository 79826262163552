import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { TextField, Button, FormControl } from "@material-ui/core";
import { useUserContext } from "../authentication";
import { createOne } from "../API";
import { useQueryClient } from 'react-query';
import { useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    publishBtn: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    deleteBtn: {
      width: "100%",
      marginTop: theme.spacing(2),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    alert: {
      marginTop: theme.spacing(2),
    },
    modale: {
      position: "absolute",
      width: 800,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modaleSpacing: {
      marginTop: theme.spacing(2),
    },
    textArea: {
      width: 700,
    },
  })
);




type Props = {
  id?: string;
  model: "locations" | "payments";
  setAlertReject?: any
  isValid: boolean,
  isRejected: boolean
  disabeld?: boolean
};

function RejectModal({
  isValid,
  isRejected,
  id,
  model,
  setAlertReject,
  disabeld
}: Props) {
  const intl=useIntl();

  const classes = useStyles();
  const authentication = useUserContext();
  const { params } = useRouteMatch<{ id: string }>();

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    RefuseLocation()
  };

  const [valueModaleReject, setValueModaleReject] = React.useState("");

  const handleChangeModale = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueModaleReject(event.target.value);
  };

  const queryClient = useQueryClient()
  const RefuseLocation = async () => {
    const response = await createOne(
      model,
      { attributes: { rejection_message: valueModaleReject } },
      authentication.accessToken,
      id,
      "reject")
    if (response) {
      setAlertReject(true);
      queryClient.invalidateQueries([
        "locations",
        {
          id: params.id,
          include: ["products", "salespeople", "products.payment", "products.salesperson", "owners", "categories"],
        },
      ])
      }
  }

  const body = (
    <div style={modalStyle} className={classes.modale}>
      <h2 id="simple-modal-title"><FormattedMessage id="REJECTMODAL.REFUS_SOUSCRIP" defaultMessage="Refus de souscription" /> </h2>
      <p id="simple-modal-description">
        <FormattedMessage id="REJECTMODAL.MSG_ENVOIMAIL" defaultMessage="Un email va être envoyé au client, expliquez lui pourquoi son
        inscription va être refusée."/>
      </p>

      <div>
        <div>
          <FormattedMessage id="REJECTMODAL.MSG_REFUS_SOUSCRIP" defaultMessage="Objet : Votre demande de souscription à MTN Y'ellow business est
          refusée"/>
        </div>
        <div className={classes.modaleSpacing}>
          <FormControl>
            <TextField
              id="standard-multiline-static"
              // label="Motivation du refus"
              multiline
              rows={10}
              defaultValue={intl.formatMessage({id:'REJECTMODAL.SAISIE_MAIL',defaultMessage:"Saisissez votre email"})}
              onChange={handleChangeModale}
              variant="outlined"
              className={classes.textArea}
            />
          </FormControl>
        </div>
      </div>


      <Button
        variant="outlined"
        className={classes.publishBtn}
        onClick={() => setOpen(false)}
      >
        <FormattedMessage
        id="REJECTMODAL.BTN_ANNULER"
        defaultMessage="Annuler"/>
      </Button>

      <Button
        variant="contained"
        color="secondary"
        className={classes.publishBtn}
        onClick={() => {
          handleClose();
        }}
      >
        <FormattedMessage id="REJECTMODAL.BTN_ENVOIMAIL" defaultMessage="Envoyer le mail"/>
      </Button>
    </div>
  );

  return (
    <div>
      <Button
        disabled={isValid || isRejected || disabeld}
        variant="outlined"
        className={classes.deleteBtn}
        onClick={handleOpen}
      >
        <FormattedMessage id="REJECTMODAL.BTN_REJECT" defaultMessage="Rejeter"/>
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default RejectModal;
