import React from 'react'
import EventChart from '../shared/EventChart'
import{useIntl} from "react-intl";

function LocationStats() {
  const intl =useIntl();

  return (
    <>
    <EventChart
    eventName="screen_view"
    title={intl.formatMessage({id:'LOCATIONSTATS.VISITE_HOMEPAGE_STAT',defaultMessage:"Nombre de visite de la homepage"})}
    filter={{ dimension: "unifiedScreenName", value: "DirectoryHome" }}
  />
  <EventChart
    eventName="screen_view"
    title={intl.formatMessage({id:'LOCATIONSTATS.VISITE_UNIQ_STAT',defaultMessage:"Nombre de visite uniques"})}
    filter={{ dimension: "unifiedScreenName", value: "DirectoryHome" }}
    uniq
  />

  <EventChart
    eventName="screen_view"
    title={intl.formatMessage({id:'LOCATIONSTATS.VISITE_CATEGORIE',defaultMessage:"Nombre de visite par catégorie"})}
    extraDim="unifiedScreenName"
    filter={{
      dimension: "unifiedScreenClass",
      value: "DirectoryLocationList",
    }}
    chartType="bar"
    labelTransformerFn={(label) => `${label}`}
  />
  </>
  )
}

export default LocationStats
