import React, { useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SortingRule } from "react-table";
import ActionsIcon from "@material-ui/icons/MoreVert";
import { actionFnWithAnyReturn, fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import PhonePreview from "../shared/PhonePreview";
import Table from "../Table";
import ColoredTag from "../shared/ColoredTag";
import moment from "moment";
import { getSortValue, isNotAuthorized } from "../../utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Article } from "../../types";
import TableActionBar from "../TableActionBar";
import AddIcon from "@material-ui/icons/Add";
import TableLink from "../Table/TableLink";
import TabPanel from "../shared/TabPanel";
import EventChart from "../shared/EventChart";
import OffersPhone from "./OffersPhone";
import appConfig from "../../appConfig";

import { FormattedMessage } from "react-intl";
import { useIntl} from "react-intl";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "80vw",
    },
    spinner: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    tab: {
      color: theme.palette.tab.main
    }
  })
);

function OffersHome() {
  
  const intl = useIntl();

  const classes = useStyles();
  const authentication = useUserContext();
  const queryClient = useQueryClient();

  // ROUTING
  const { url, path } = useRouteMatch();
  const { state } = useLocation<{
    sortBy: SortingRule<object>[];
    pageSize: number;
    pageIndex: number;
    searchWith: null | string;
  } | null>();
  // NAVIGATION STATE
  // Les éléments liés à la navigation sont tiré de l'état de location
  // Où ils sont directement définis par Table || ou par un Link / goBack() etc avec state
  const pageIndex = state?.pageIndex ? state.pageIndex : 0;
  const pageSize = state?.pageSize ? state.pageSize : 10;
  const sortBy = state?.sortBy
    ? state.sortBy
    : [{ id: "displayed_status", desc: false }];
  const searchWith = state?.searchWith ? state.searchWith : "";
  const [controlledSearchWith, setControlledSearchWith] = useState(searchWith);

  // COMPONENT STATE
  // État lié au composant (indépendant de la navigation)
  // const [selectionList, setSelectionList] = useState<string[]>([]);
  // const [isSelectionNegative, setIsSelectionNegative] = useState(false);

  console.log('in offers home')

  const history = useHistory();

  // DATA
  // définition des headers
  const columns = [
    {
      accessor: "actions",
      Header: "",
      disableSortBy: true,
    },
    { accessor: "title", Header: intl.formatMessage({id:'OFFERSHOME.LABEL_TITRE',defaultMessage:"Titre"}), style:{minWidth: 100}, width: "100" },
    {
      accessor: "displayed_status",
      Header: intl.formatMessage({id:'OFFERSHOME.LABEL_STATUT',defaultMessage:"Statut"}),
    },
    {
      accessor: "date_start",
      Header: intl.formatMessage({id:'OFFERSHOME.LABEL_DATE_DEBUT',defaultMessage:"Début"}),
    },
    {
      accessor: "date_end",
      Header: intl.formatMessage({id:'OFFERSHOME.LABEL_DATE_FIN',defaultMessage:"Fin"}),
    },
    {
      accessor: "format",
      Header: intl.formatMessage({id:'OFFERSHOME.LABEL_FORMAT',defaultMessage:"Format"}),
    },
    {
      accessor: "salesperson",
      Header: intl.formatMessage({id:'OFFERSHOME.LABEL_COMMERCIAL',defaultMessage:"Commercial"}),
      sortValue: "salesperson.last_name",
    },
    {
      accessor: "id",
      Header: "Id",
      disableSortBy: true,
    },

  ];

  // REQUEST (à partir des états et de columns)
  const fetchObject = {
    include: ["editor", "salesperson"],
    fields: {
      users: ["name", "id", "email"],
    },
    page: {
      number: pageIndex + 1,
      size: pageSize,
    },
    filters: {
      ...(searchWith && searchWith.length > 2 && { search: searchWith }),
      firebase_collection: "Offers",
      displayed_status: ["online", "programmed", "draft", "expired"],
    },
    sort: getSortValue(sortBy[0], columns),
  };

  interface ArticlePreview extends Partial<Article> {
    id: string
    editor: { name: string; id: string };
  }
  const { data, status, isPreviousData } = useQuery<{
    results: ArticlePreview[];
    pageCount: number;
    total: number;
  }>(
    ["offers", fetchObject],
    () => fetchList("articles", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );


  const [actionsAnchorEl, setActionsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [actionsMenuItem, setActionsMenuItem] = useState<ArticlePreview | null>(null);
  const getItemMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    article: ArticlePreview
  ) => {
    setActionsMenuItem(article);
    setActionsAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setActionsAnchorEl(null);
    setActionsMenuItem(null);
  };
  const mutation = useMutation(
    ({ action }: any) =>
      actionFnWithAnyReturn(
        "articles",
        action,
        actionsMenuItem?.id || null,
        authentication.accessToken
      ),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: ({ data }) => {
        history.push("/offers/" + data.id);
        // setIsValidate(true)
        // successAlert();
      },
      onSettled: () => {
        console.log("queriesInvalidated");
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("offers");
      },
    }
  );

  const formatDate = (date: any) => {
    return moment(date).format("L");
  };

  const transformedData = React.useMemo(() => {
    if (data?.results) {
      return data.results.map((offer: ArticlePreview) => ({
        title: (
          <TableLink
            style={{width: 120}}
            onClick={() => {
              history.push(`${path}/${offer.id}`, { action: "edit" });
            }}
          >
            {offer.title ? offer.title : intl.formatMessage({id :'OFFERSHOME.MSG_SANSTITRE',defaultMessage:"sans titre"})}
          </TableLink>
        ),
        displayed_status: <ColoredTag value={offer.displayed_status} />,
        date_start: formatDate(offer.date_start),
        date_end: formatDate(offer.date_end),
        format: <ColoredTag value={offer.format} />,
        salesperson: offer?.salesperson
          ? `${offer?.salesperson.first_name} ${offer?.salesperson.last_name}`
          : " - ",
        id: offer.id,
        actions: (
          <IconButton
            onClick={(e) => getItemMenu(e, offer)}
            size="small"
            color="primary"
          >
            <ActionsIcon />
          </IconButton>
        ),
      }));
    } else {
      return [];
    }
  }, [data]);
  // pagination
  const pageCount =
    status === "success" && data?.pageCount ? data.pageCount : 0;
  const totalElements = status === "success" ? data?.total : undefined;

  const actionBar = (
    <TableActionBar
      title={appConfig.sections.offers}
      value={controlledSearchWith}
      setValue={(p) => setControlledSearchWith(p)}
    >
      <Button
        variant="contained"
        color="secondary"
        endIcon={<AddIcon />}
        onClick={() => {
          history.push(`${path}/create`, {
            action: "create",
          });
        }}
      >
        <FormattedMessage 
          id="OFFERSHOME.BTN_CREER"
          defaultMessage="Créer"/>
      </Button>
    </TableActionBar>
  );

  // Tab navigation
  const [tab, setTab] = React.useState(0);

  return (
    <div className={classes.tab}>
      <Toolbar />
      <Tabs
        value={tab}
        onChange={(event: any, value: number) => setTab(value)}
        aria-label="Tabs"
      >
        <Tab label={
              <FormattedMessage
              id="OFFERSHOME.TAB_GENERAL"
              defaultMessage="Général"/>} />
        <Tab label={
              <FormattedMessage
              id="OFFERSHOME.TAB_STATISTIQUES"
              defaultMessage="Statistiques"/>} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <div className={classes.container}>
          <Menu
            id="basic-menu"
            anchorEl={actionsAnchorEl}
            open={Boolean(actionsAnchorEl)}
            onClose={closeMenu}
            MenuListProps={{
              dense: true,
            }}
          >
            <MenuItem
              onClick={() => {
                if (actionsMenuItem) {
                  mutation.mutate({ action: "duplicate" });
                }
                closeMenu();
              }}
            >
              <FormattedMessage
              id="OFFERSHOME.ITEM_MENU_DUPLIC"
              defaultMessage="Dupliquer"/>
            </MenuItem>
            {actionsMenuItem?.firebase_id && <MenuItem
              onClick={() => {
                actionsMenuItem?.firebase_id && navigator.clipboard.writeText(`${appConfig.deeplinkPrefix}offers/${actionsMenuItem.firebase_id}`)
              }}
            >
              <FormattedMessage id="OFFERSHOME.MENUITEM_COPYDEEP" defaultMessage="Copier le deeplink"/>
            </MenuItem>}
          </Menu>
          <Table
            data={transformedData}
            columns={columns}
            initialPageIndex={pageIndex}
            initialPageSize={pageSize}
            initialSortBy={sortBy}
            pageCount={pageCount}
            totalElements={totalElements}
            actionBar={actionBar}
            searchWith={controlledSearchWith}
            isFetching={isPreviousData}
          />
          <OffersPhone />
          {/* {plopek && <PhonePreview collection="Offers" articles={plopek} />} */}
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <div>
          <EventChart
            eventName="screen_view"
            title={intl.formatMessage({id:'OFFERSHOME.VISIT_HOMEPAGE_BONPLAN',defaultMessage:"Nombre de visite de la homepage Bons Plans"})}
            filter={{ dimension: "unifiedScreenName", value: "OffersHome" }}
          />
          <EventChart
            eventName="screen_view"
            title={intl.formatMessage({id:'OFFERSHOME.VISIT_UNIQ',defaultMessage:"Nombre de visite uniques"})}
            filter={{ dimension: "unifiedScreenName", value: "OffersHome" }}
            uniq
          />
        </div>
      </TabPanel>
    </div>
  );
}

export default OffersHome;
