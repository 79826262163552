import { useState, useEffect, useContext } from "react";
import appConfig from "./appConfig";
import React from "react";
import { User } from "./types";
import { postFn, fetchOne } from "./API";
import { useQuery } from "react-query";
import { isNotAuthorized } from "./utils";

export const useAuth = () => {
  // strings liées au token (doublées dans localStorage)
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  const query = useQuery(
    ["users", { id: userId }],
    () => {
      if (accessToken && userId) {
        return fetchOne("users", { id: userId }, accessToken);
      }
    },
    {
      enabled: Boolean(userId && accessToken),
      retry: false,
      onError: (e: any) => {
        isNotAuthorized(e) && logOut();
      },
    }
  );

  const user = query?.data?.result || null;
  const userStatus = query.status;

  const [initialized, setInitialized] = useState(false); // Sert pour le reloading, on attend d'être sûr que les valeurs soient prises

  useEffect(() => {
    // Au montage du composant, on récupère les éventuelles valeurs dans localStorage
    hydrateToken();
    setInitialized(true);
    // Danger, à terme stocker le jwt ailleurs
  }, []);

  const logOut = async () => {
    setUserId(null);
    setAccessToken(null);
    localStorage.removeItem("user_id");
    localStorage.removeItem("access_token");
  };

  const hydrateToken = () => {
    setAccessToken(localStorage.getItem("access_token"));
    setUserId(localStorage.getItem("user_id"));
  };

  // Méthode pour Set le token (et user_id), seule à utiliser pour synchro avec LS
  const writeToken = (access_token: string, user_id: string) => {
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("access_token", access_token);
    setAccessToken(access_token);
    setUserId(user_id);
  };

  const createToken = async (email: string, password: string, expiration: string) => {

      const response = await postFn("tokens", {
        attributes: { email, password, expiration },
      });
      const authorization = (await response) as {
        access_token: string;
        email: string;
        id: number;
        expiration: string;
      };
      writeToken(authorization.access_token, authorization.id.toString());
      return { ...authorization, user_id: authorization.id.toString() };

  };

  const signUp = async (login: string, password: string) => {
    // const { data, statusText } = await createUser(login, password);
    // if (data) {
    //   const token = await getToken(login, password);
    //   if (token.data) {
    //     // setUser(data);
    //     // setToken(token.data);
    //     setInitialized(true);
    //     // Danger, remplacer à terme
    //     localStorage.setItem("user", JSON.stringify(data));
    //     localStorage.setItem("token", token.data);
    //     return token.statusText;
    //   } else {
    //     return token.statusText;
    //   }
    // } else {
    //   return statusText;
    // }
  };

  return {
    user,
    accessToken,
    logIn: createToken,
    logOut,
    signUp,
    initialized,
    userStatus,
  };
};

export type UserContextType = {
  user: User | null;
  // token: string | null;
  // setToken: ((token: string) => void) | null;
  // setUser: (user: any) => void;
  accessToken: string | null;
  logIn: (
    login: any,
    password: string,
    expiration: string
  ) => Promise<
    | { access_token: string; email: string; id: number; expiration: string }
    | { error: any }
  >;
  signUp: (login: any, password: string) => Promise<any>;
  initialized: boolean;
  logOut: () => void;
  userStatus: string;
};
export const UserContext = React.createContext<UserContextType | null>(null);

export const useUserContext = () => {
  const ctx = useContext(UserContext);
  if (!ctx) {
    throw new Error(
      "Erreur : n'utilisez le contexte d'utilisateur qu'au sein d'un provider d'utilisateur"
    );
  }
  return ctx;
};
