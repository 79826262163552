import React, { ChangeEvent, useEffect, useState } from 'react'
import SimpleSelect from './SimpleSelect'
import { TextField } from '@material-ui/core'
import { Article } from "../../types/article"
import { Action } from "../../types/action"
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { SSL_OP_CISCO_ANYCONNECT } from 'constants'
import { FormattedMessage} from 'react-intl';
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(3),
    },
    textField: {
      marginTop: theme.spacing(2),
    }
  }))

type Props = {
  setValueWrapper: (object: Article | Action) => void,
  setValue: React.Dispatch<React.SetStateAction<Article>>,
  value: Article,
  action: Action,
  index: number,
  checkField: (event: string, field: string) => void,
  errorMessage: any,
  setErrorMessage: React.Dispatch<React.SetStateAction<any>>
}

export default function ActionForm({ checkField, errorMessage, setErrorMessage, setValueWrapper, setValue, value, action, index }: Props) {
  const classes = useStyles()
  const intl=useIntl();

  const handleActionValues = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    switch (true) {
      case field === "actionFormat":
        value.actions[index].format = event.target.value
        setValue({ ...value, actions: [...value.actions] })
        break;
      case field === "rawTitle":
        value.actions[index].title = event.target.value
        setValue({ ...value, actions: [...value.actions] })
        break;
      case field === "rawDescription":
        value.actions[index].description = event.target.value
        setValue({ ...value, actions: [...value.actions] })
        break;
      case field === "email":
        value.actions.find((x, i) => {
          if (i === index && x.mail) {
            x.mail.address = event.target.value
            setValue({ ...value, actions: [...value.actions] })
          }
        })
        break;
      case field === "object":
        value.actions.find((x, i) => {
          if (i === index && x.mail) {
            x.mail.object = event.target.value
            setValue({ ...value, actions: [...value.actions] })
          }
        })
        break;
      case field === "emailText":
        value.actions.find((x, i) => {
          if (i === index && x.mail) {
            x.mail.content = event.target.value
            setValue({ ...value, actions: [...value.actions] })
          }
        })
        break;

      case field === "phoneNumber":
        value.actions.find((x, i) => {
          if (i === index && x.phone) {
            x.phone.number = event.target.value
            setValue({ ...value, actions: [...value.actions] })
          }
        })

        break;
      case field === "webUrl":
          setErrorMessage({ ...errorMessage, ["web"+index]: null })
          setWebError(false)

        value.actions.find((x, i) => {
          if (i === index && x.web) {
            x.web.url = event.target.value
            setValue({ ...value, actions: [...value.actions] })
          }
        })

        break;
      case field === "smsMessage":
        value.actions.find((x, i) => {
          if (i === index && x.sms) {
            x.sms.content = event.target.value
            setValue({ ...value, actions: [...value.actions] })
          }
        })
        break;
      case field === "smsPhoneNumber":
        value.actions.find((x, i) => {
          if (i === index && x.sms) {
            x.sms.number = event.target.value
            setValue({ ...value, actions: [...value.actions] })
          }
        })
        break;
      case field === "buttonTitle":
        value.actions[index].custom_text = event.target.value
        setValue({ ...value, actions: [...value.actions] })
        break;
      default:
        break;
    }
  }

  const deleteAction = () => {
    value.actions.filter((action) => {
      return action !== value.actions[index]
    })
  }

  const [webError, setWebError] = useState(false)
  useEffect(() => {
     if (errorMessage["web" + index]) setWebError(true)

  }, [errorMessage])

  return (
    <div key={value.id} className={classes.actionContainer}>
      <SimpleSelect
        index={index}
        object={action}
        setValue={setValueWrapper}
        value={action.type}
        objectKey="type"
        title={intl.formatMessage({id:'ACTIONFORM.TITRE_BOUTON',defaultMessage:"Bouton d'actions"})}
        items={[{ value: "web", name: "Web" }, { value: "sms", name: "sms" }, { value: "mail", name: "Email" }, { value: "phone", name: intl.formatMessage({id:'ACTIONFORM.APPEL',defaultMessage:"Appel"}) }]}
      />

      {action.type === "mail" && (<>
        <TextField defaultValue={(action.mail && action.mail.address) && action.mail.address} className={classes.textField}
          onChange={event => handleActionValues(event, "email")} label="email" />
        <TextField defaultValue={(action.mail && action.mail.object) && action.mail.object} className={classes.textField}
          onChange={event => handleActionValues(event, "object")} label="object" />
        <TextField defaultValue={(action.mail && action.mail.content) && action.mail.content} className={classes.textField}
          onChange={event => handleActionValues(event, "emailText")} label={intl.formatMessage({id:'ACTIONFORM.LABEL_TEXTE',defaultMessage:"Texte"})} multiline rows={4} />
      </>)}

      {action.phone && <TextField className={classes.textField}
        defaultValue={action.phone.number} onChange={event => handleActionValues(event, "phoneNumber")} label={intl.formatMessage({id:'ACTIONFORM.LABEL_NUMTEL_1',defaultMessage:"numéro de téléhone"})} />}

      {action.type === "web" && <>
        <TextField
          type="url"
          defaultValue={action.web && action.web.url}
          className={classes.textField}
          onChange={event => handleActionValues(event, "webUrl")}
          onBlur={(event) => checkField(event.target.value, `web${index.toString()}`)}
          error={webError}
          helperText={errorMessage["web"+ index]}
          label="url"
        />
      </>}

      {action.type === "sms" && (<>
        <TextField defaultValue={(action.sms && action.sms.number) && action.sms.number} className={classes.textField}
          onChange={event => handleActionValues(event, "smsPhoneNumber")} label={intl.formatMessage({id:"ACTIONFORM.LABEL_NUMTEL_2",defaultMessage:"numéro de téléhone"})} />
        <TextField defaultValue={(action.sms && action.sms.content) && action.sms.content} className={classes.textField}
          onChange={event => handleActionValues(event, "smsMessage")} label="message" />
      </>)}

      <>
        {action.type === "web" && <TextField className={classes.textField}
          defaultValue={action.custom_text ? action.custom_text : intl.formatMessage({id:'ACTIONFORM.TEXTFIELD_DECOUVRIR',defaultMessage:"Découvrir"})} onChange={event => handleActionValues(event, "buttonTitle")} label={intl.formatMessage({id:'ACTIONFORM.TITRE_BTN_1',defaultMessage:"Titre du bouton"})} />}
        {action.type === "phone" && <TextField className={classes.textField}
          defaultValue={action.custom_text ? action.custom_text : intl.formatMessage({id:'ACTIONFORM.TEXTFIELD_APPELER',defaultMessage:"Appeler"})} onChange={event => handleActionValues(event, "buttonTitle")} label={intl.formatMessage({id:'ACTIONFORM.TITRE_BTN_2',defaultMessage:"Titre du bouton"})} />}
        {(action.type === "sms" || action.type === "mail") && <TextField className={classes.textField}
          defaultValue={action.custom_text ? action.custom_text : intl.formatMessage({id:'ACTIONFORM.TEXTFIELD_ENVOYER',defaultMessage:"Envoyer"})} onChange={event => handleActionValues(event, "buttonTitle")} label={intl.formatMessage({id:'ACTIONFORM.TITRE_BTN_3',defaultMessage:"Titre du bouton"})} />}
      </>

      {/* <SimpleSelect
        handleValue={handleActionValues}
        value={action.format ? action.format : "cta"}
        name="actionFormat"
        title="Format du bouton"
        items={[{ value: "cta", name: "call-to-action" }, { value: "row", name: "rang" }]}
      /> */}

      {action.format === "row" && (
        <>
          <TextField className={classes.textField} onChange={event => handleActionValues(event, "rawTitle")} label={intl.formatMessage({id:'ACTIONFORM.LABEL_TITREACTION',defaultMessage:"Titre de l'action"})} />
          <TextField className={classes.textField} onChange={event => handleActionValues(event, "rawDescription")} label={intl.formatMessage({id:'ACTIONFORM.LABEL_DESCRIPTACTION',defaultMessage:"Descriptions de l'action"})} />
        </>
      )}

      {/* {(action !== value.actions[index] && value.actions.length > 1) && (
        <Button variant="outlined" size="medium" onClick={deleteAction} >
          enlever l'action
        </Button>
      )} */}

    </div >
  )
}
