import React, { ReactEventHandler, useRef } from "react";
import {
  useTable,
  usePagination,
  Column,
  useSortBy,
  SortingRule,
} from "react-table";
import {
  Typography,
  Toolbar,
  Paper,
  TableContainer,
  Table as MaterialTable,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableSortLabel,
  Link,
  Button,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
// import classes from "*.module.css";
// import {
//   TableHead,
//   TableRow,
//   TableCell,
//   TableSortLabel,
// } from "@material-ui/core";
// import { orderBy } from "lodash";
import TableLink from "./TableLink";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

type Props = {
  columns: Column<Record<string, any>>[];
  data: Record<string, any> & { id: string }[];
  // loading: boolean;
  pageCount: number;
  // selection?: {
  //   setSelectionList: (arg: string[]) => void;
  //   setIsSelectionNegative: (arg: boolean) => void;
  // };
  // pagination?: {
  //   // setPageIndex: (arg: number) => void;
  //   // setPageSize: (arg: number) => void;
  //   initialPageSize: number;
  //   initialPageIndex: number;
  //   totalElements: number;
  // };
  initialPageSize?: number;
  initialPageIndex?: number;
  initialSortBy?: SortingRule<object>[];
  totalElements?: number;
  searchWith?: string;
  isFetching?: boolean;
  // sorting: {
  //   // setSortBy:(s: SortingRule<object>[]) => void;
  //   initialSortBy: SortingRule<object>[];
  // }
  // totalElements?: number;
  actionBar: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      overflow: "hidden",
    },
    cell: {
      maxWidth: 700,
      // wordBreak: "break-word",
    },
  })
);

export default function Table({
  columns,
  data,
  // loading,
  pageCount: controlledPageCount,
  // selection,
  actionBar: actionBar,
  initialPageSize = 10,
  initialPageIndex = 0,
  initialSortBy = [],
  totalElements,
  searchWith,
  isFetching,
}: // totalElements,
Props) {
  
  const intl = useIntl();

  const classes = useStyles();
  const { url, path } = useRouteMatch();

  const history = useHistory();
  const { state } = useLocation<{
    sortBy: SortingRule<object>[];
    pageSize: number;
    pageIndex: number;
  } | null>();

  const locationPageIndex = state?.pageIndex
    ? state.pageIndex
    : initialPageIndex;
  const locationPageSize = state?.pageSize ? state.pageSize : initialPageSize;
  const locationSortBy = state?.sortBy ? state.sortBy : initialSortBy;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: locationPageIndex,
        pageSize: locationPageSize,
        sortBy: locationSortBy,
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    history.push(url, { pageIndex, pageSize, sortBy, searchWith });
  }, [pageIndex, pageSize]);

  // Listen for sortBy changes without reinitializing on first render
  const sortByRef = useRef<SortingRule<object>[]>();
  React.useEffect(() => {
    if (sortByRef.current && sortByRef.current !== sortBy) {
      history.push(url, { pageIndex: 0, pageSize, sortBy, searchWith });
    }
    sortByRef.current = sortBy;
  }, [sortBy]);

  // Revenir à 0 quand on change la recherche
  const searchWithRef = useRef<string>();
  React.useEffect(() => {
    if (
      searchWith !== null &&
      searchWith !== undefined &&
      searchWithRef.current &&
      searchWithRef.current !== searchWith
    ) {
      history.push(url, { pageIndex: 0, pageSize, sortBy, searchWith });
    }
    searchWithRef.current = searchWith;
  }, [searchWith]);

  // Render the UI for your table
  return (
    <Paper className={classes.paper}>
      {actionBar}
      <TableContainer>
        <MaterialTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    sortDirection={
                      (column.sortValue || column.id) === sortBy[0]?.id
                        ? sortBy[0]?.desc
                          ? "desc"
                          : "asc"
                        : false
                    }
                  >
                    {column.disableSortBy ? ( // on gagne un peu d'espace en enlevant la flèche vide
                      column.render("Header")
                    ) : (
                      <TableSortLabel
                        disabled={column.disableSortBy}
                        active={
                          sortBy[0]?.id === (column.id || column.sortValue)
                        }
                        direction={sortBy[0]?.desc ? "desc" : "asc"}
                      >
                        {column.render("Header")}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          {/* Apply the table body props */}

          <TableBody
            {...getTableBodyProps()}
            style={{ opacity: isFetching ? 0.5 : 1 }}
          >
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <TableRow hover {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell
                        className={classes.cell}
                        {...cell.getCellProps()}
                        scope="row"
                        // padding="none"
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        // component="div"
        count={totalElements ? totalElements : 0}
        rowsPerPage={pageSize}
        page={locationPageIndex}
        onPageChange={(event, page) => {
          gotoPage(page);
        }}
        labelDisplayedRows={({ from, to, count }) => (
          <FormattedMessage
            id="TABLE.RESULTS_COUNT"
            description="table results count"
            defaultMessage="Résultats {from} à {to} sur {stringifiedCount, select, none {plus de {to}} other {{stringifiedCount}}}"
            values={{
              from: from,
              to: to,
              stringifiedCount: count === -1 ? 'none' : count.toString()
            }}
          />
        )}
        labelRowsPerPage={intl.formatMessage({id:'TABLE.NBRE_PAR_PAGE',defaultMessage:"Nombre par page"})}
        onChangeRowsPerPage={(e) => setPageSize(parseInt(e.target.value, 10))}
      />
    </Paper>
  );
}
