import { ArticleCollection } from "./index"

export function isArticleCollection(tbd: string): tbd is ArticleCollection {
  if (
    (tbd as ArticleCollection === "Offers") || 
    (tbd as ArticleCollection === "Stories") || 
    (tbd as ArticleCollection === "Services") || 
    (tbd as ArticleCollection === "LocationNews")
  ) {
    return true;
  }
  return false;
}