import React, { useCallback, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Node, Text } from 'slate'
import { withHistory } from 'slate-history'
import { Button, Icon, Toolbar } from './SlateComponents'
import { FormatBold, FormatItalic, FormatListBulleted, FormatStrikethrough } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useEffect } from 'react';
import { Plans } from '../../types/plans'
import { FormattedMessage } from 'react-intl'
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editable: {
      borderBottom: "1px grey solid",
    }
  }))

const HOTKEYS: any = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const SlateEditor = ({ valueArticle, setValueArticle, field, text }: {
  text?: string;
  valueArticle: any // Article | Plans;
  setValueArticle: any // React.Dispatch<Article> | React.Dispatch<Plans>
  field: any // keyof Article | keyof Plans
}) => {
  const intl = useIntl();

  const classes = useStyles();
  const initialValue = valueArticle[field] ? valueArticle[field] : [
    {
      type: 'paragraph',
      children: [
        { text: valueArticle.text ? valueArticle.text : "" },
      ],
    },
  ]
  useEffect(() => {
    setValueArticle({ ...valueArticle, [field]: initialValue })
  }, [])

  const [value, setValue] = useState<Node[]>(initialValue)
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])


  const serialize = (nodes: any): string => {
    return nodes.map((n: any) => {
      if (n.type === "bulleted-list") {
        return n.children.map((x: any) => Node.string(x)).join('\n')
      }
      else return Node.string(n)
    }
    ).join('\n')
  }

  const updateText = (newValue: any) => {
    setValue(newValue)
    setValueArticle({ ...valueArticle, [field]: newValue, text: text && serialize(value) })
  }



  return (
    <>
      <Slate editor={editor} value={value} onChange={value => updateText(value)}>
        <Toolbar>
          <MarkButton format="bold" icon={<FormatBold />} />
          <MarkButton format="italic" icon={<FormatItalic />} />
          <MarkButton format="del" icon={<FormatStrikethrough />} />

          {/* <MarkButton format="underline" icon={<FormatUnderlined />} /> */}
          {/* <MarkButton format="code" icon="code" />
        <BlockButton format="heading-one" icon="looks_one" /> */}
          {/* <BlockButton format="heading-two" icon={<TitleIcon />} /> */}
          {/* <BlockButton format="block-quote" icon="format_quote" /> */}
          {/* <BlockButton format="numbered-list" icon={<FormatListNumbered />} /> */}
          <BlockButton format="bulleted-list" icon={<FormatListBulleted />} />
        </Toolbar>
        <Editable
          className={classes.editable}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={intl.formatMessage({id:'SLATEEDITOR.PLACEHOLDER_TEXTEFICHE',defaultMessage:"Écrivez le texte de la fiche"})}
          spellCheck
          autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </Slate>

    </>
  )
}

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type as string),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: any, format: any) => {
  const [match]: any = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor: any, format: any) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }: { attributes: any, children: any, element: any }) => {
  switch (element.type) {
    // case 'block-quote':
    //   return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}><Typography>{children}</Typography></ul>
    // case 'heading-one':
    //   return <h1 {...attributes}><Typography>{children}</Typography></h1>
    case 'heading-two':
      return <h2 {...attributes}><Typography>{children}</Typography></h2>
    case 'list-item':
      return <li {...attributes}><Typography>{children}</Typography></li>
    // case 'numbered-list':
    //   return <ol {...attributes}><Typography>{children}</Typography></ol>
    default:
      return <p {...attributes}><Typography>{children}</Typography></p>
  }
}

const Leaf = ({ attributes, children, leaf }: { attributes: any, children: any, leaf: any }) => {

  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.del) {
    children = <del>{children}</del>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  // if (leaf.underline) {
  //   children = <u>{children}</u>
  // }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }: { format: any, icon: any }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }: { format: any, icon: any }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>

    </Button>
  )
}



export default SlateEditor