import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import moment from "moment"

import { Notification } from "../../types"
import { FormattedMessage } from 'react-intl';

require("moment/locale/fr.js");


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6)
    },
    container: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    notifPreview: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    },
    cardTitle: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    notifMetadata: {
      marginTop: theme.spacing(2),
      display: "flex",

    }
  })
);

type Props = {
  notifications: Notification[],
}

const NotificationsHistory = ({ notifications }: Props) => {
  const classes = useStyles();
  const reversedNotifications = [...notifications].reverse()

  return (
    <>
      
        <Paper className={classes.paper}>
          <div className={classes.container}>
            <Typography className={classes.cardTitle} color="textSecondary" variant="h6">
              <FormattedMessage id="NOTIFICATION_HISTORY.TITLE" defaultMessage="Historique des notifications" />
            </Typography>
            {reversedNotifications.map((notification: Notification) =>
              <div key={notification.created_at}>
                <div className={classes.notifMetadata}>
                  <Typography>{moment(notification.created_at).format('LLL')}</Typography>
                </div>
                <Paper key={notification.title} className={classes.notifPreview} elevation={3}>
                  <Typography variant="h6">{notification.title}</Typography>
                  <Typography>{notification.text}</Typography>
                </Paper>
              </div>
            )}
          </div>
        </Paper >
      
    </>
  )
}

export default NotificationsHistory
