import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Tabs, Tab, Toolbar } from "@material-ui/core";
import CategoryForm from "../Categories/CategoryForm";
import TabPanel from "../shared/TabPanel";
import { FormattedMessage } from "react-intl";

require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    tab: {
      color: theme.palette.tab.main
    }
  })
);

const CategoryCreate = () => {
  const classes = useStyles();

  const [tab, setTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  return (
    <div  className={classes.tab}>
      <Toolbar />
        <Typography className={classes.title} variant="h4">
          <FormattedMessage
          id="CATEGORYCREATE.TOOLBAR_ITEM_NOUVEAU"
          defaultMessage="Nouvelle Catégorie"/>
        </Typography>

      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label={
          <FormattedMessage
          id="CATEGORYCREATE.TAB_CREA"
          defaultMessage="Création"/>} />
      </Tabs>
      <TabPanel value={tab} index={0}>
          <CategoryForm  action={"create"} />
      </TabPanel>
    </div>
  );
};
// CategoryCreate.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default CategoryCreate;