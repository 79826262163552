import React, { useState } from "react";
import {
  Typography,
  Tabs,
  Tab,
  Divider,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TabPanel from "../shared/TabPanel";
import LocationsForm from "./LocationsForm";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      marginTop: 100,
      color: theme.palette.tab.main
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    marginB: {
      marginBottom: theme.spacing(4),
    },
    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
  })
);

const LocationsCreate = () => {
  const classes = useStyles();
  const action = "create";

  const [tab, setTab] = useState(0);

 

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div className={classes.main}>
      {/* {status === "success" && location?.name && (
        <Typography className={classes.title} variant="h4">
          {location?.name !== "" ? location.name : `Établissement sans nom`}
        </Typography>
      )} */}
        <Typography className={classes.title} variant="h4">
          <FormattedMessage
          id="CATEGORYFORM.NEW_ETABLISSEMENT"
          defaultMessage="Nouvel établissement"/>
        </Typography>

      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label={
                    <FormattedMessage
                    id="LOCATIONSCREATE.TAB_LABEL_PARAM"
                    defaultMessage="Paramètres"/>} />
        <Tab label={
                    <FormattedMessage
                    id="LOCATIONSCREATE.TAB_LABEL_ACTU"
                    defaultMessage="Actualités"/>} />
        <Tab label={
                   <FormattedMessage
                   id="LOCATIONSCREATE.TAB_LABEL_FORFAIT" 
                   defaultMessage="Forfait"/>} />
        <Tab label={
                    <FormattedMessage
                    id="LOCATIONSCREATE.TAB_LABEL_CLIENT"
                    defaultMessage="Client"/>} />
        {/* {location &&location.firebase_id &&   <Tab label="Statistiques" />} */}
      </Tabs>
      <Divider />
      <div className={classes.marginB} />
      <TabPanel value={tab} index={0}>

            <LocationsForm
              // locations={locations}
              action={action}
            />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        {/* <LocationNewsTable /> */}
      
          <Alert severity="warning" className={classes.alert}>
            <FormattedMessage
            id="LOCATIONSCREATE.ALERTE_CREA_ACTU"
            defaultMessage="L'établissement doit être créé avant les actualités"/>
          </Alert>
      </TabPanel>

      <TabPanel value={tab} index={2}>

          <Alert severity="warning" className={classes.alert}>
            <FormattedMessage
            id="LOCATIONSCREATE.ALERTE_CREA_FORFAIT"
            defaultMessage="L'établissement doit être créé avant le forfait"/>
          </Alert>
      </TabPanel>

      <TabPanel value={tab} index={3}>

          <Alert severity="warning" className={classes.alert}>
            <FormattedMessage
            id="LOCATIONSCREATE.ALERTE_CREA_CLIENT"
            defaultMessage="L'établissement doit être créé avant le client"/>
          </Alert>
      </TabPanel>
     

     
    </div>
  );
};

// LocationsCreate.whyDidYouRender = {
//   logOnDifferentValues: true,
// }

export default LocationsCreate;
