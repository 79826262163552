import { QueryClient, useQuery } from "react-query";
import { Deserializer } from "jsonapi-serializer";
import { UserContextType } from "./authentication";
import { Article } from "./types";
import appConfig from "./appConfig";

const OLD_API = appConfig.OLD_API;

export function useOneLocation({
  authentication,
  id,
  skip,
}: {
  authentication: UserContextType;
  id: string;
  skip: boolean;
}) {
  const config = {
    method: "GET",
    headers: {
      Authorization: authentication.accessToken
        ? authentication.accessToken
        : "",
    },
  };
  return useQuery(
    !skip ? ["Location", id] : "réecrire",
    async () => {
      if (skip) {
        return null;
      }
      const data = await fetch(`${OLD_API}/locations/${id}`, config);
      const result = await data
        .json()
        .then((res) =>
          new Deserializer({ keyForAttribute: "snake_case" }).deserialize(res)
        )
        .catch((err) => {
          return err;
        });

      let location = null;
      if (result && result.articles) {
        const articles = result.articles.map(articleDeserializer);
        location = { ...result, articles: [...articles] };
      }
      return location;
    },
    {
      cacheTime: 0,
    }
  );
}

const articleDeserializer = (article: any): Article => {
  const newArticle: any = {
    ...article,
    actions:
      typeof article.actions === "string"
        ? JSON.parse(article.actions)
        : article.actions,
    rich_text:
      typeof article.rich_text === "string"
        ? JSON.parse(article.rich_text)
        : article.rich_text,
  };
  return newArticle;
};

// API V2 ---------------------------------------

const API = appConfig.API;

type modelType =
  | "app_configurations"
  | "articles"
  | "locations"
  | "users"
  | "tokens"
  | "categories"
  | "payments"
  | "notifications"
  | "salespeople"
  | "products"
  | "plans";
type optionsType = {
  id?: string;
  include?: string[];
  fields?: {
    [model: string]: string[];
  };
  page?: { size: number; number: number };
  sort?: string;
  filters?: {
    [attribute: string]: string | number | boolean | string[] | number[];
  };

  deserializerOptions?: {
    [key: string]: {
      valueForRelationship: (relationShip: object) => object;
    };
  };
};

const postDeserialize = (res: any, model: modelType) => {
  if (model === "articles") {
    if (res.actions) {
      res.actions = JSON.parse(res.actions);
    }
    if (res.rich_text) {
      res.rich_text = JSON.parse(res.rich_text);
    }
  }
  if (model === "plans") {
    if (res.sub_title) {
      res.sub_title = JSON.parse(res.sub_title);
    }
    if (res.description) {
      res.description = JSON.parse(res.description);
    }
  }

  return res;
};

// GENERIC ACTIONS
const fetchFn = async (
  model: modelType,
  {
    id,
    include: rawInclude,
    fields: rawFields,
    page: rawPage,
    sort: rawSort,
    filters: rawFilters,
  }: optionsType,
  accessToken?: string | null
) => {
  const fields = rawFields
    ? Object.entries(rawFields)
        .map(([key, val]) => `&fields[${key}]=${val.join(",")}`)
        .join("&")
    : "";
  const include = rawInclude ? `&include=${rawInclude.join(",")}` : "";
  const page = `${
    rawPage
      ? `${rawPage.size ? `&page[size]=${rawPage.size}` : ""}${
          rawPage.number !== undefined && rawPage.number !== null
            ? `&page[number]=${rawPage.number}`
            : ""
        }`
      : ""
  }`;
  const sort = rawSort ? `&sort=${rawSort}` : "";
  const filters = rawFilters
    ? Object.entries(rawFilters)
        .map(([key, val]) => `&filter[${key}]=${val}`)
        .join("&")
    : "";
  const url = `${API}/${model}${
    id ? `/${id}` : ""
  }?${include}${fields}${page}${sort}${filters}`;
  const headers = {
    ...(accessToken && { Authorization: accessToken }),
  };

  const response = await fetch(url, { headers });

  // To be handled by react-query !
  if (!response.ok) {
    const text = await response.text();
    throw { status: response.status, statusMessage: response.statusText, text };
  }

  return response.json();
};

export const fetchList = async (
  model: modelType,
  {
    include,
    fields,
    deserializerOptions = {},
    page,
    sort,
    filters,
  }: optionsType,
  accessToken?: string | null
) => {


  const data = await fetchFn(
    model,
    { include, fields, page, sort, filters },
    accessToken
  );


  const deserializedData = await new Deserializer({
    keyForAttribute: "snake_case",
    ...deserializerOptions,
  }).deserialize(data, (err, res) => postDeserialize(res, model));

  return {
    results: deserializedData as unknown as any[],
    pageCount: data.meta?.page_count || null,
    total: data.meta?.record_count || null,
  };

};

export const fetchOne = async (
  model: modelType,
  {
    id,
    include,
    fields,
    deserializerOptions = {},
  }: optionsType & { id: string },
  accessToken?: string | null
) => {
  const data = await fetchFn(model, { include, fields, id }, accessToken);
  
  const deserializedData = await new Deserializer({
    keyForAttribute: "snake_case",
    ...deserializerOptions,
  }).deserialize(data, (err, res) => postDeserialize(res, model));  
  return {
    result: deserializedData as any,
    // pageCount: data.meta.page_count,
    // total: data.meta.record_count,
  };
};

// Post

export const postFn = async (
  model: modelType,
  { attributes, relationships }: any,
  accessToken?: string | null,
  id?: string,
  action?: "validate" | "reject" | "duplicate"
) => {
  const url =
    id && action ? `${API}/${model}/${id}/${action}` : `${API}/${model}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      ...(accessToken && { Authorization: accessToken }),
    },
    body: JSON.stringify({ data: { attributes, relationships, type: model } }),
  });

  if (!response.ok) {
    const text = await response.text();
    throw { status: response.status, statusMessage: response.statusText, text };
  }
  return await response.json();
};

export const createOne = async (
  model: modelType,
  { attributes, relationships }: any,
  accessToken?: string | null,
  id?: string,
  action?: "validate" | "reject"
) => {
  const data = await postFn(
    model,
    { attributes, relationships },
    accessToken,
    id,
    action
  );

  const deserializedData = await new Deserializer({
    keyForAttribute: "snake_case",
  }).deserialize(data, (err, res) => postDeserialize(res, model));
  return { result: deserializedData };
};

// Update
export const updateFn = async (
  model: modelType,
  { attributes, relationships, id }: any,
  accessToken?: string | null
) => {
  const response = await fetch(`${API}/${model}/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      ...(accessToken && { Authorization: accessToken }),
    },
    body: JSON.stringify({
      data: { id, attributes, relationships, type: model },
    }),
  });
  if (!response.ok) {
    const text = await response.text();
    throw { status: response.status, statusMessage: response.statusText, text };
  }
  return await response.json();
};

export const updateOne = async (
  model: modelType,
  { id, attributes, relationships }: any,
  accessToken?: string | null
) => {
  const data = await updateFn(
    model,
    { attributes, relationships, id },
    accessToken
  );
  const deserializedData = await new Deserializer({
    keyForAttribute: "snake_case",
  }).deserialize(data, (err, res) => postDeserialize(res, model));
  return { result: deserializedData };
};

///////////////////////////////////////////////////////////
/////////////////// DELETE LOCATION //////////////////////

export const deletePOST = async (
  model: modelType,
  accessToken?: string | null,
  id?: string
) => {
  const url = id ? `${API}/${model}/${id}` : `${API}/${model}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      ...(accessToken && { Authorization: accessToken }),
    },
  });

  if (!response.ok) {
    const text = await response.text();
    throw { status: response.status, statusMessage: response.statusText, text };
  }
  return response;
};

////////////////// Fin Delete Location ////////////////////
///////////////////////////////////////////////////////////

// SPECIAL ACTIONS
export const massUpdateArticles = async (
  articles: { id: string; position: number }[],
  accessToken?: string
) => {
  const config = {
    method: "PATCH",
    headers: {
      ...(accessToken && { Authorization: accessToken }),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      articles: articles,
    }),
  };
  const response = await fetch(`${API}/articles_mass_updates`, config);
  if (!response.ok) {
    const text = await response.text();
    throw { status: response.status, statusMessage: response.statusText, text };
  }
  const data = await response.json();
  const deserializedData = await new Deserializer({
    keyForAttribute: "snake_case",
  }).deserialize(data, (err, res) => postDeserialize(res, "articles"));
  return { result: deserializedData };
};

export const massUpdateCategories = async (
  categories: { id: string; position: number }[],
  accessToken?: string
) => {
  const config = {
    method: "PATCH",
    headers: {
      ...(accessToken && { Authorization: accessToken }),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      categories: categories,
    }),
  };
  const response = await fetch(`${API}/categories_mass_updates`, config);
  if (!response.ok) {
    const text = await response.text();
    throw { status: response.status, statusMessage: response.statusText, text };
  }
  const data = await response.json();
  const deserializedData = await new Deserializer({
    keyForAttribute: "snake_case",
  }).deserialize(data, (err, res) => postDeserialize(res, "categories"));
  return { result: deserializedData };
};

export const validateOne = async (
  model: modelType,
  id: string,
  action: "validate" | "reject",
  accessToken: string | null
) => {
  const response = await fetch(`${API}/${model}/${id}/${action}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(accessToken && { Authorization: accessToken }),
    },
  });
  const res = await response.json();

  return res;
};

// use when you dont care about return
export const actionFnWithAnyReturn = async (
  model: modelType,
  action: "duplicate", // longer list when it comes to
  id: string | null,
  accessToken: string | null
) => {
  if (id) {
    const response = await fetch(`${API}/${model}/${id}/${action}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(accessToken && { Authorization: accessToken }),
      },
    });
    const res = await response.json();

    return res;
  } else {
    return null;
  }
};

export function useEventChartData({
  authentication,
  query,
}: {
  authentication: UserContextType;
  query: {
    eventName: string;
    extraDim?: string;
    filter?: { dimension: string; value: string };
    from: string;
    to: string;
    uniq?: boolean;
  };
}) {
  const config = {
    method: "GET",
    headers: {
      Authorization: authentication.accessToken
        ? authentication.accessToken
        : "",
    },
  };
  return useQuery(["Chart", query], async () => {
    const request = `${API}/events?event_name=${query.eventName}&from=${
      query.from
    }&to=${query.to}${query.extraDim ? `&extra_dim=${query.extraDim}` : ""}${
      query.filter
        ? `&filter_dim=${query.filter.dimension}&filter_value=${query.filter.value}`
        : ""
    }${query.uniq ? `&uniq=${query.uniq}` : ""}`;

    const data = await fetch(request, config);
    const result = await data.json();
    return result;
  });
}
