import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import { Typography, Tabs, Tab, Toolbar } from "@material-ui/core";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import TabPanel from "../shared/TabPanel";
import { useQuery } from "react-query";
import Loader from "../shared/Loader";
import SettingsForm from "./SettingsForm";
import {FormattedMessage, useIntl } from "react-intl";
require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    paper: {
      width: "70%",
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),

    },
    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    tab: {
      color: theme.palette.tab.main
    }
  })
);

const SettingsEdit = () => {
  const intl=useIntl();
  const authentication = useUserContext();
  const classes = useStyles();


  // ROUTING
  const { params } = useRouteMatch<{ id: string }>();

  // DATA QUERY
  const fetchObject = {
    id: params.id,
  };

  const query = useQuery<{
    result: any;
  }>(["app_configurations", fetchObject], () =>
    fetchOne("app_configurations", fetchObject, authentication.accessToken)
  );

  const { status } = query;
  const setting = query?.data?.result;
  const [tab, setTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
 
  let set=""
  switch (params.id) {
    case '1':
     set="momopay"
      break;
    case '2':
      set="email"
      break;
    default:
      console.log("set non défini")
  }


  return (
    <div className={classes.tab}>
      <Toolbar />
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {setting && <Tab label={intl.formatMessage({id:"SETTINGSEDIT.TABLAB_EDITION",defaultMessage:"Édition"})} />}
      </Tabs>
      <TabPanel value={tab} index={0}>
        {status === "loading" && <Loader />}
        {status === "error" && (
          <Typography><FormattedMessage id="SETTINGSEDIT.TEXT_NOTHISSETTING" defaultMessage="Ce settings n'existe pas"/></Typography>
        )}
        {status === "success" && (
          <SettingsForm settings={setting} action={set} />
        )}
      </TabPanel>
    </div>
  );
};

export default SettingsEdit;
