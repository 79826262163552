import React, { useEffect, useState } from "react";
import { Paper, Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CSVLink, CSVDownload } from "react-csv";
import LineChart from "./LineChart";
import DateForm from "./DateForm";
import moment from "moment";
import { useUserContext } from "../../authentication";
import { useEventChartData } from "../../API";
import StackedBar from "./StackedBarChart";
import { Skeleton } from "@material-ui/lab";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    chatsCategories: {
      paddingRight: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    container: {
      // display: "flex",
      // flexWrap: "wrap",
      justifyContent: "center",
      paddingTop: theme.spacing(3),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    datePicker: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexWrap: "wrap",
    },
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    skeletton: {
      marginTop: theme.spacing(1),
    },
    footer: {
      margin: theme.spacing(1)
    }
  })
);

type Dataset = {
  label: string;
  data: number[];
  fill: boolean;
  backgroundColor: string;
  borderColor: string;
};

type Props = {
  eventName: string;
  extraDim?: string;
  filter?: { dimension: string; value: string };
  title: string;
  uniq?: boolean;
  chartType?: "line" | "bar";
  labelTransformerFn?: (arg: string) => string;
};

const EventChart = ({
  eventName,
  filter,
  extraDim,
  title,
  uniq,
  chartType = "line",
  labelTransformerFn,
}: Props) => {
  const authentication = useUserContext();
  // const [displayedLabels, setDisplayedLabels] = useState<string[]>([]);
  // const [displayedDatasets, setDisplayedDatasets] = useState<Dataset[]>([]);
  const [cumulatedValue, setCumulatedValue] = useState<number | null>(null);
  // const [csvData, setCsvData] = useState<any[] | null>(null);
  const today = moment().format("YYYY-MM-DD");
  const aWeekAgo = moment().subtract(7, "d").format("YYYY-MM-DD");
  const aMonthAgo = moment().subtract(30, "d").format("YYYY-MM-DD");
  const aYearAgo = moment().subtract(1, "y").format("YYYY-MM-DD");
  const [dateStart, setDateStart] = useState(aWeekAgo);
  const [dateEnd, setDateEnd] = useState(today);
  const colorLine = [
    "#FFCC00",
    "#63B3ED",
    "#68D391",
    "#ED8936",
    "#805AD5",
    "#ED64A6",
  ];
  const [isActive, setIsActive] = useState("week");

  const query = useEventChartData({
    authentication,
    query: { from: dateStart, to: dateEnd, eventName, filter, extraDim, uniq },
  });

  const datasets: Dataset[] = query.data
    ? query.data.datasets.map((dataset: Dataset, i: number) => ({
        ...dataset,
        borderColor: colorLine[i % 7],
        backgroundColor: colorLine[i % 7],
        fill: false,
      }))
    : [];
  const labels = query.data ? query.data.labels : [];

  useEffect(() => {
    if (datasets && datasets.length > 0) {
      setCumulatedValue(
        datasets.reduce(
          (accDatasets: number, currDataset: Dataset) =>
            accDatasets +
            currDataset.data.reduce((accVal, currVal) => accVal + currVal, 0),
          0
        )
      );
    }
  }, [datasets]);

  const headers = datasets
    ? ["date", ...datasets.map((set: Dataset) => set.label)]
    : null;
  const csvData =
    labels && datasets
      ? [
          ...labels.map((label: string, i: number) => [
            label,
            ...datasets.map((set) => set.data[i]),
          ]),
        ]
      : null;

  const transformedLabelsDatasets = labelTransformerFn
    ? datasets.map((dataset: any) => ({
        ...dataset,
        label: labelTransformerFn(dataset.label),
      }))
    : labels;

  const classes = useStyles();

  const handleStartDateChange = (date: any) => {
    const formatedDate = moment(date._d).format("YYYY-MM-DD");
    setDateStart(formatedDate);
  };

  const handleEndDateChange = (date: any) => {
    const formatedDate = moment(date._d).format("YYYY-MM-DD");
    setDateEnd(formatedDate);
  };

  const viewWeek = () => {
    setIsActive("week");
    setDateStart(aWeekAgo);
  };

  const viewMonth = () => {
    setIsActive("month");
    setDateStart(aMonthAgo);
  };

  const viewYear = () => {
    setIsActive("year");
    setDateStart(aYearAgo);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">{title}</Typography>
        {/* check l'existence de cumulatedValue ++ si elle est égale à zéro on prend quand même */}
        {cumulatedValue || cumulatedValue === 0 ? (
          <Typography variant="subtitle1">
            <FormattedMessage 
            id="EVENTCHART.TEXT_TOTAL_CUMUL"
            defaultMessage="Total cumulé sur la période :"/> <strong>{cumulatedValue}</strong>
          </Typography>
        ) : (
          <Skeleton
            className={classes.skeletton}
            variant="text"
            animation="wave"
            width={280}
          />
        )}
        <div className={classes.flex}>
          <div className={classes.container}>
            <Button
              className={classes.button}
              variant={isActive == "week" ? "contained" : "outlined"}
              onClick={viewWeek}
              size="medium"
              color={isActive == "week" ? "secondary" : "default"}
            >
              <FormattedMessage
                id="EVENTCHART.BTN_WEEK"
                defaultMessage="Depuis une semaine"/>
            </Button>
            <Button
              className={classes.button}
              variant={isActive == "month" ? "contained" : "outlined"}
              onClick={viewMonth}
              size="medium"
              color={isActive == "month" ? "secondary" : "default"}
            >
              <FormattedMessage
              id="EVENTCHART.BTN_MONTH"
              defaultMessage="Depuis un mois"/>
            </Button>
            <Button
              className={classes.button}
              variant={isActive == "year" ? "contained" : "outlined"}
              onClick={viewYear}
              size="medium"
              color={isActive == "year" ? "secondary" : "default"}
            >
              <FormattedMessage
              id="EVENTCHART.BTN_YEAR"
              defaultMessage="Depuis une année"/>
            </Button>
          </div>
          <DateForm
            date_start={dateStart}
            date_end={dateEnd}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
          />
        </div>
        {chartType == "line" ? (
          <LineChart data={{ labels, datasets }} legend={datasets.length > 1} />
        ) : (
          <StackedBar
            data={{ labels, datasets: transformedLabelsDatasets }}
            legend={datasets.length > 1}
          />
        )}
        <div className={classes.footer} style={{display: "flex", justifyContent: "flex-end" }}>
          {headers && csvData ? (
            <CSVLink
              filename={`${eventName}-${filter ? filter.value + "-" : ""}${
                extraDim ? "by-" + extraDim + "-" : ""
              }${dateStart}-to-${dateEnd}`}
              headers={headers}
              data={csvData}
              className="MuiButtonBase-root MuiButton-root MuiButton-contained  MuiButton-containedSecondary"
            >
              <FormattedMessage
              id="EVENTCHART.BTN_TELECHARGER_CVS"
              defaultMessage="Télécharger le .CSV"/>
            </CSVLink>
          ) : null}
        </div>
      </Paper>
    </>
  );
};

export default EventChart;
