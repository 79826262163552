import React, { useContext, useState } from "react";
import { Button, CircularProgress, Paper, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { UserContext } from "../../authentication";
import { User, Location } from "../../types";
import appConfig from "../../appConfig";
import { useLocation as useRouterLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    alert: {
      width: "70%",
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    plan: {
      marginTop: theme.spacing(2),
    },
    typo: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    typoBold: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    container: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
    validationMessage: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
    progressInBtn: {
      marginLeft: theme.spacing(2),
    },
    loader: {
      marginRight: theme.spacing(2),
    },
  })
);

// const today = moment().format("LLL");

type Props = {
  owners: User[];
  locationId: Location["id"];
  location: Location;
  action: "create" | "edit";
};

const LocationClient = ({ owners }: Props) => {
  const classes = useStyles();
  const authentication = useContext(UserContext);
  const API = appConfig.API;
  const { pathname } = useRouterLocation();
  const findPathId = pathname.match(/([1-9][0-9]*)/);
  const locationId = findPathId && findPathId[0];
  const [message, setMessage] = useState<{
    text: string;
    severity: "success" | "warning";
  } | null>(null);

  const [filteredOwners, setFilteredOwners] = useState(owners);
  const [isLoading, setIsLoading] = useState({
    status: false,
    ownerId: "",
  });

  const unassignOwner = async (owner: User) => {
    // locations/{locationId}/remove_owner_from_admin?email={emailOwner}
    try {
      setMessage(null);
      setIsLoading({
        status: true,
        ownerId: owner.id ? owner.id : "",
      });
      const headers = {
        ...(authentication?.accessToken && {
          Authorization: authentication?.accessToken,
        }),
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      };

      const path = `${API}/locations/${locationId}/remove_owner_from_admin?email=${owner.email}`;

      const response = await fetch(path, {
        method: "POST",
        headers: headers,
      });
      const res = await response.json();
      setIsLoading({
        status: false,
        ownerId: "",
      });
      if (res.data) {
        setFilteredOwners(filteredOwners.filter((x) => x.id !== owner.id));
        setMessage({
          severity: "success",
          text: "l'utilisateur a bien été dé-attribué et un mail lui a été envoyé",
        });
      }
    } catch (error: any) {
      console.log(error);
      setIsLoading({
        status: false,
        ownerId: "",
      });
      setMessage({
        severity: "warning",
        text:
          "la dé-attribution n'a pas fonctionné. Voici le code erreur : " +
          error,
      });
    }
  };

  return (
    <>
      <>
        {message && (
          <Alert className={classes.alert} severity={message.severity}>
            {message.text}
          </Alert>
        )}

        {filteredOwners.length > 0 ? (
          filteredOwners.map((owner, i) => (
            <Paper className={classes.paper}>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h6"
              >
                <FormattedMessage id="LOCATIONCLIENT.TEXTE_INFOCLIENT" defaultMessage="Informations client"/>
              </Typography>
              <div className={classes.container}>
                <>
                  {owner?.name && (
                    <Typography className={classes.typoBold}>
                      <FormattedMessage id="LOCATIONCLIENT.TEXTE_NOM" defaultMessage="Nom:"/>
                      <Typography className={classes.typo}>
                        {owner?.name}
                      </Typography>
                    </Typography>
                  )}
                  {owner?.phone && (
                    <Typography className={classes.typoBold}>
                      <FormattedMessage id="LOCATIONCLIENT.TEXTE_TELEPH" defaultMessage="Téléphone:"/>
                      <Typography className={classes.typo}>
                        {owner?.phone}
                      </Typography>
                    </Typography>
                  )}
                  <Typography className={classes.typoBold}>
                    Email:
                    <Typography className={classes.typo}>
                      {owner?.email}
                    </Typography>
                  </Typography>
                  {owner?.device_id && (
                    <Typography className={classes.typoBold}>
                      Device ID:
                      <Typography className={classes.typo}>
                        {owner?.device_id}
                      </Typography>
                    </Typography>
                  )}
                </>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  disabled={isLoading.status && isLoading.ownerId === owner.id}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (owner.email) {
                      window.location.href = `mailto:${owner.email}`;
                    }
                  }}
                >
                  <FormattedMessage id="LOCATIONCLIENT.BTN_CONTACTCLIENT" defaultMessage="Contacter le client"/>
                </Button>
                <Button
                  disabled={isLoading.status && isLoading.ownerId === owner.id}
                  variant="outlined"
                  color="secondary"
                  onClick={() => unassignOwner(owner)}
                >
                  {isLoading.status && isLoading.ownerId === owner.id && (
                    <CircularProgress className={classes.loader} size={20} />
                  )}
                  <FormattedMessage id="LOCATIONCLIENT.BTN_DEATTRIBADMIN" defaultMessage="Dé-attribuer cet administrateur"/>
                </Button>
              </div>
            </Paper>
          ))
        ) : (
          <Typography>
            <FormattedMessage
            id="LOCATIONCLIENT.MSG_PAS_CLIENTASSOC"
            defaultMessage="Cet établissement n'a pas encore de compte client associé"/>
          </Typography>
        )}
      </>
    </>
  );
};

export default LocationClient;
