import React, { useRef } from 'react'
import { Article, Category, Collection } from '../../types'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { useImage } from '../../firebase'
import { Typography, Paper, Divider, Button } from '@material-ui/core'
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd"
import { DndTypes } from '../../utils/dndTypes'
import { ArticleDisplayPreview } from '../../types/article'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      padding: theme.spacing(1),
      flexWrap: "wrap",
      flexDirection: "row"
    },
    tileContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flex: "0 50%",
      cursor: "grab",
    },
    tile: {
      width: 176,
      height: 150,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      margin: "8px auto",
      borderRadius: 4,
      display: "flex",
      justifyContent: "center"
    },
    textTile: {
      borderRadius: 4,
      top: 105,
      height: 38,
      width: "95%",
      textAlign: "center",
      position: "relative",
      backgroundColor: "rgba(242,242,242,.85)",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    category: {
      width: 176,
      height: 150,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      margin: "8px auto",
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
    },
    textCategory: {
      top: 120,
      height: 38,
      width: "100%",
      textAlign: "center",
      position: "relative",
      backgroundColor: "white",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,

    },
    bigTileContainer: {
      height: 150,
      cursor: "grab",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",

    },
    bannerContainer: {
      width: "100%",
      height: 150,
      marginBottom: theme.spacing(2),
    },
    bigTile: {
      width: "100%",
      height: 150,
      margin: theme.spacing(1),
    },
    banner: {
      width: "100%",
      height: 150,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      margin: "0 auto",
    },
    textBg: {
      top: 125,
      textAlign: "center",
      position: "relative",
      backgroundColor: "rgba(242,242,242,.85)",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    cardContainer: {
      width: "100%",
      cursor: "grab",
    },
    card: {
      width: "100%",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      marginTop: 1,
      marginBottom: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    stories: {
      display: "flex",
      flexDirection: "column"
    },
    storiesContainer: {
      // width: "100%",
      height: 150,
    },
    storiesPaper: {
      width: "100%",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),

    },
    storiesText: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),

    },
    storiesBtn: {
      display: "flex",
      justifyContent: "center",
      // marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),

    }
  }))

type Props = {
  category?: Category;
  article?: ArticleDisplayPreview;
  collection: Collection;
  index: number;
  moveArticle: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}


const PhoneBody = ({ category, moveArticle, article, collection, index }: Props) => {
  const classes = useStyles()

  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: DndTypes.CARD,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Time to actually perform the action
      moveArticle && moveArticle(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })


  const id = article && article.id
  const [, drag] = useDrag({
    item: { type: DndTypes.CARD, id, index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const imageArticleUrl = useImage(article ? article.image : "")
  const imageCategoryUrl = useImage(category ? category.image : "")

  return (
    <>

      {(category) && (
        <div ref={ref} className={classes.tileContainer}>
          <div style={{ backgroundImage: `url(${imageCategoryUrl})` }} className={classes.category} >
            <Typography className={classes.textCategory}>
              {category.name}
            </Typography>
          </div>
        </div>
      )}
      {(article && article.format === "tile") && (
        <div ref={ref} className={classes.tileContainer}>
          <div style={{ backgroundImage: `url(${imageArticleUrl})` }} className={classes.tile} >
            <Typography className={classes.textTile}>
              {article.title}
            </Typography>
          </div>
        </div>
      )}
      {article && article.format === "big_tile" && (
        <div ref={ref} className={classes.bigTile}>
          <div style={{ backgroundImage: `url(${imageArticleUrl})` }} className={classes.bigTileContainer} >
            <Typography className={classes.textTile}>
              {article.title}
            </Typography>
          </div>
        </div>
      )}
      {(article && article.format === "banner") && (
        <div className={classes.cardContainer} ref={ref}>
          <Paper square className={classes.card} >
            <div>
              <Typography variant="h6">
                {article.title}
              </Typography >
              <Typography variant="body2">
                {article.headline}
              </Typography>
            </div >
            <KeyboardArrowRightIcon />
          </Paper>
          <Divider />
        </div>
      )}
      {(article && article.format === "card") && (
        <div className={classes.cardContainer} ref={ref}>
          <Paper square className={classes.card} >
            <div>
              <Typography variant="h6">
                {article.title}
              </Typography >
              <Typography variant="body2">
                {article.headline}
              </Typography>
            </div >
            <KeyboardArrowRightIcon />
          </Paper>
          <Divider />
        </div>
      )}
      {article && collection === "Stories" && (
        <Paper className={classes.storiesPaper}>
          <div className={classes.storiesContainer}>
            <div style={{ backgroundImage: `url(${imageArticleUrl})` }} className={classes.banner} >
              <Typography className={classes.textBg}>
                {article.title}
              </Typography>
            </div>
          </div>
          <Typography variant="body2" className={classes.storiesText}>
            {article.text}
          </Typography>
          <div className={classes.storiesBtn}>
            <Button variant="contained" color="secondary">
              Découvrir
          </Button>
          </div>


        </Paper>
      )}


    </>
  )
}

export default PhoneBody