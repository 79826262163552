import React, { useState, useEffect, ChangeEvent } from "react";
import { TextField, Typography, Button, Paper } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  useHistory,
  useLocation as useRouterLocation,
  useRouteMatch,
} from "react-router-dom";
import { useUserContext } from "../../authentication";
import { useMutation, useQueryClient } from "react-query";
import { updateOne } from "../../API";
import { isNotAuthorized } from "../../utils";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(4),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    publishBtn: {
      width: "100%",
    },

    previewImage: {
      maxWidth: 300,
      maxHeight: 180,
      objectFit: "contain",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    buttonWrapper: {
      position: "relative",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    none: {
      display: "none",
    },
    image: {
      display: "flex",
      flexDirection: "column",
    },
    deleteBtnDialog: {
      marginRight: theme.spacing(3),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      width: "100%",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    alert: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
  })
);

const SettingsForm = ({ settings }: { settings?: any; action: any }) => {
  const intl = useIntl();

  
  const classes = useStyles();
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const authentication = useUserContext();
  const { pathname } = useRouterLocation();
  const findPathId = pathname.match(/([1-9][0-9]*)/);
  const [isSaved, setIsSaved] = useState(false);

  // value to create or update
  const [value, setValue] = useState<any>(
    settings
      ? settings
      : {
          setting: "",
        }
  );

  // pour que react-query ait le temps de recevoir l'info après une édition et un retour
  useEffect(() => {
    if (settings) {
      setValue(settings);
    }
  }, [settings]);

  const handleValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const formatValue = (rawValue: any) => {
    const { id, ...attributes } = rawValue;

    const formattedValue = {
      attributes: { ...attributes },
      ...(id && { id }),
    };
    return formattedValue;
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: any) =>
      updateOne(
        "app_configurations",
        formatValue(data),
        authentication.accessToken
      ),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        setIsSaved(true)
        setTimeout(() => {
          history.goBack();
        }, 1500);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("app_configurations");
      },
    }
  );

  const handlePublish = () => {
    mutation.mutate(value);
  };


  return (
    <>
      <Paper className={classes.paper} elevation={1}>
        <form className={classes.form}>
          <Typography
            className={classes.sectionLabel}
            color="textSecondary"
            variant="h6"
          >
           {value.key_name == 'momocode' ? "Code momopay de l'app" : "Email administration de l'app"}
          </Typography>

          <TextField
            className={classes.textField}
            helperText=""
            value={value.value}
            onChange={(event) => handleValue(event)}
            label=""
            name="value"
          />

          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.publishBtn}
              onClick={handlePublish}
            >
              <FormattedMessage
              id="SETTINGSFORM.BTN_SAVE_MODIF"
              defaultMessage="Enregistrer la modification"/>
            </Button>

            {isSaved && (
              <Alert severity="success" className={classes.alert}>
                <FormattedMessage
                id="SETTINGSFORM.ALERTE_SAVE_MODIF_OK"
                defaultMessage="Les modifications ont bien été enregistrées"/>
              </Alert>
            )}

          </div>
        </form>
      </Paper>
    </>
  );
};

export default SettingsForm;
