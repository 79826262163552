import React, { useState } from "react";
import { Typography, Tabs, Tab, Divider } from "@material-ui/core";
import {
  useHistory,
  useLocation as useRouterLocation,
  useRouteMatch,
} from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TabPanel from "../shared/TabPanel";
import LocationsForm from "./LocationsForm";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import LocationPlan from "./LocationPlan";
import LocationClient from "./LocationClient";
import { Location } from "../../types";
import Loader from "../shared/Loader";
import EventChart from "../shared/EventChart";
import { useQuery } from "react-query";
import LocationNewsTable from "./LocationNewsTable";
import LocationComments from "./LocationComments";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      marginTop: 100,
      color: theme.palette.tab.main
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    marginB: {
      marginBottom: theme.spacing(4),
    },
    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
  })
);

const LocationsEdit = () => {
  const intl=useIntl();

  const classes = useStyles();
  const history = useHistory();
  const authentication = useUserContext();
  const { pathname, state } = useRouterLocation<any>();

  const findPathId = pathname.match(/([1-9][0-9]*)/);
  // const pathId = findPathId ? findPathId[0] : "0";
  const { params, url } = useRouteMatch<{ id: string }>();

  // const skip = pathId === "0" ? true : false;
  const action = "edit";

  // ON RÉCUPÈRE L'ÉTABLISSEMENT
  const { status, data: results } = useQuery<{ result: Location }>(
    [
      "locations",
      {
        id: params.id,
        include: ["products", "salespeople", "products.payment", "products.salesperson", "owners", "categories"],
      },
    ],
    () => {
      return fetchOne(
        "locations",
        {
          id: params.id,
          include: ["products", "salespeople", "products.payment", "products.salesperson", "owners", "categories", "comments"],
        },
        authentication.accessToken
      );
    },
    {}
  );
  const location = results?.result && results?.result;
  console.log(location && location)

  const [tab, setTab] = useState(0);


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div className={classes.main}>
      {status === "success" && location?.name && (
        <Typography className={classes.title} variant="h4">
          {location?.name !== "" ? location.name : intl.formatMessage({id:'LOCATIONSEDIT.TEXTE_ETABLISS_NONAME',defaultMessage:"Établissement sans nom"})}
        </Typography>
      )}


      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label={
                <FormattedMessage
                id="LOCATIONSEDIT.TAB_LAB_PARAM"
                defaultMessage="Paramètres"/>}
                 value={0} />
        <Tab label={
                <FormattedMessage
                  id="LOCATIONSEDIT.TAB_LAB_ACTU"
                   defaultMessage="Actualités"/>} value={1}/>
        <Tab label={
              <FormattedMessage
              id="LOCATIONSEDIT.TAB_LAB_FORFAIT"
              defaultMessage="Forfait"/>} 
                  value={2}/>
        <Tab label={
                  <FormattedMessage
                  id="LOCATIONEDIT.TAB_LAB_CLIENT"
                  defaultMessage="Client"/>} 
                  value={3}/>
        {location && location.firebase_id && <Tab label={
            <FormattedMessage
            id="LOCATIONSEDIT.TAB_LAB_STATISTIC"
            defaultMessage="Statistiques"/>} value={4}/>}
        <Tab label={
            <FormattedMessage
            id="LOCATIONSEDIT.TAB_LAB_COMMENT"
            defaultMessage="Commentaires"/>}
             value={5}/>
      </Tabs>
      <Divider />
      <div className={classes.marginB} />
      <TabPanel value={tab} index={0}>
        {status === "loading" && <Loader />}
        {status === "success" &&

          <LocationsForm
            // locations={locations}
            location={location && location}
            action={action}
          />
        }
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <LocationNewsTable />

      </TabPanel>

      <TabPanel value={tab} index={2}>
        {status === "loading" && <Loader />}
        {status === "success" && (
          <LocationPlan
            location={location}
          //locations={locations}
          />
        )}
        {/* {status === "success" && action === "create" && (
          <Alert severity="warning" className={classes.alert}>
            L'établissement doit être créé avant le forfait
          </Alert>
        )} */}
      </TabPanel>

      <TabPanel value={tab} index={3}>
        {status === "loading" && <Loader />}
        {status === "success" && location && (
          <LocationClient
            action={action}
            locationId={location.id && location.id}
            location={location}
            owners={location.owners}
          />
        )}
        {/* {status === "success" && action === "create" && (
          <Alert severity="warning" className={classes.alert}>
            L'établissement doit être créé avant le client
          </Alert>
        )} */}
      </TabPanel>

      {location && location.firebase_id && (
        <TabPanel value={tab} index={4}>
          <EventChart
            eventName="screen_view"
            title={intl.formatMessage({id:'LOCATIONSEDIT.TITRE_VISITE_ETABLISS',defaultMessage:"Visites de l'établissement"})}
             filter={{
              dimension: "unifiedScreenName",
              value: location.firebase_id,
            }}
          />
          <EventChart
            eventName="screen_view"
            title={intl.formatMessage({id:'LOCATIONSEDIT.TITRE_VISIT_UNIQ_JOUR',defaultMessage:"Visiteurs uniques (par jour) de l'établissement"})}
            filter={{
              dimension: "unifiedScreenName",
              value: location.firebase_id,
            }}
            uniq
          />

          <EventChart
            eventName="faved_location"
            title={intl.formatMessage({id:'LOCATIONSEDIT.TITRE_MISE_FAVORI',defaultMessage:"Mises en favoris"})}
            filter={{
              dimension: "customEvent:id",
              value: location.firebase_id,
            }}
          />

          <EventChart
            eventName="article_action"
            title={intl.formatMessage({id:'LOCATIONSEDIT.TITRE_ACTIONLIEE',defaultMessage:"actions liées à l'établissement"})}
            filter={{
              dimension: "customEvent:id",
              value: location.firebase_id,
            }}
            extraDim="customEvent:action"
          />
        </TabPanel>

      )}
      <TabPanel value={tab} index={5}>
        {location && <LocationComments  comments={location.comments} />}
      </TabPanel>

    </div>
  );
};

// LocationsEdit.whyDidYouRender = {
//   logOnDifferentValues: true,
// }

export default LocationsEdit;
