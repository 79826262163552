import React, { useState, useContext, useEffect } from "react";
import {
  Paper,
  TextField,
  Typography,
  Tabs,
  Tab,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { validate } from "validate.js";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { UserContextType, UserContext } from "../authentication";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TabPanel from "./shared/TabPanel";
import clsx from "clsx";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";

type Props = {
  authentication: UserContextType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    container: {
      backgroundColor: theme.palette.grey[200],
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    field: {
      margin: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2),
    },
    caption: {
      textAlign: "center",
    },
    margin: {
      margin: theme.spacing(2),
    },
    checkboxLabel: {
      color: theme.palette.grey[600],
    },
  })
);

function LogIn({ authentication }: Props) {
  const intl= useIntl();
  const history = useHistory();

  const classes = useStyles();
  const [user, setUser] = useState({
    emailAddress: "",
    password: "",
    longExp: false,
  });
  const [errors, setErrors] = useState({ emailAddress: "", password: "" });
  const [serverError, setServerError] = useState<null | string>(null);
  useEffect(() => {
    console.log(user)
 
  }, [user])
  
  const handleValidation = async () => {
    const constraints =     {emailAddress: {
      presence: {
        allowEmpty: false,
        message: intl.formatMessage({id:'CONSTRAINTS.MSG_MAILOBLIG',defaultMessage:"^Entrez une adresse email"})
      },
      email: {
        message: intl.formatMessage({id:'CONSTRAINTS.MSG_MAILVALID',defaultMessage:"^Entrez une adresse email valide"})
      }
    }}
    const validationErrors = validate(user, constraints);
    console.log(validationErrors);
    
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      try {
        console.log("oké");
        
        const authorization = await authentication.logIn(
          user.emailAddress,
          user.password,
          user.longExp ? "long" : "regular"
        );
        // if (authorization) {
        //   history.push("/offers");
        // }
      } catch (error: any) {
        if (error.text) {
          // Peut-être aussi régler le bug qui fait une 500
          setServerError(error.text);
        } else if (error.status === 403) {
          setServerError(
            intl.formatMessage({id:'LOGGER.MSG_MDP_NOMUTIL',defaultMessage:"Le nom d'utilisateur et le mot de passe que vous avez entrés ne correspondent pas à ceux présents dans nos fichiers. Veuillez vérifier et réessayer."})
          );
        }
      }
    }
  };
  useEffect(() => {
    if (authentication.user) {
      if (authentication?.user?.admin){
        history.push("/offers");
      } else {
        setServerError(
          intl.formatMessage({id:'LOGGER.NO_RIGHTS',defaultMessage:"Vous n'avez pas les droits nécessaires"})
        );
      }
    }
  }, [authentication.user])
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <div className={classes.form}>
        <TextField
          error={errors["emailAddress"] ? true : false}
          id="emailAdress"
          value={user.emailAddress}
          onChange={({ currentTarget }) =>
            setUser({ ...user, emailAddress: currentTarget.value })
          }
          helperText={errors["emailAddress"] ? errors.emailAddress[0] : false}
          label={intl.formatMessage({id:'LOGGER.LABEL_ADRESSE_MAIL',defaultMessage:"Adresse email"})}
          className={classes.field}
        />
        {/* <TextField

          id="password"
          error={errors["password"] ? true : false}
          value={user.password}
          onChange={({ currentTarget }) =>
            setUser({ ...user, password: currentTarget.value })
          }
          helperText={errors["password"] ? errors.password[0] : false}
          label="Mot de passe"
          className={classes.field}
        /> */}
        <FormControl className={clsx(classes.margin)}>
          <InputLabel htmlFor="standard-adornment-password">
            <FormattedMessage
            id="LOGGER.MOT_DE_PASSE"
            defaultMessage="Mot de passe"/>
          </InputLabel>
          <Input
            error={errors["password"] ? true : false}
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            value={user.password}
            onChange={({ currentTarget }) =>
              setUser({ ...user, password: currentTarget.value })
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {serverError && (
          <div
            style={{
              margin: "auto",
              width: 200,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              className={classes.caption}
              variant="caption"
              color="error"
            >
              {serverError}
            </Typography>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                name="longExp"
                checked={user.longExp}
                onChange={(event) =>
                  setUser({ ...user, longExp: event.target.checked })
                }
              />
            }
            label={intl.formatMessage({id:'LOGGER.RESTER_CONNECTER',defaultMessage:"Rester connecté 14 jours"})}
            classes={{
              label: classes.checkboxLabel,
            }}
          />
        </div>
        <Button
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={handleValidation}
        >
          <FormattedMessage id="LOGGER.BTN_SECONNECTER" defaultMessage="Se connecter"/>
        </Button>
      </div>
    </>
  );
}

// function SignUp({ authentication }: Props) {
//   const classes = useStyles();
//   const [user, setUser] = useState({ emailAddress: "", password: "" });
//   const [errors, setErrors] = useState({ emailAddress: "", password: "" });
//   const [serverError, setServerError] = useState<null | string>(null);

//   const handleValidation = async () => {
//     const validationErrors = validate(user, loggerConstraints);
//     if (validationErrors) {
//       setErrors(validationErrors);
//     } else {
//       const status = await authentication.signUp(
//         user.emailAddress,
//         user.password
//       );
//       if (status === "Unauthorized") {
//         setServerError("Login ou mot de passe incorrect");
//       } else if (status !== "OK") {
//         setServerError(status);
//       }
//     }
//   };

//   const [showPassword, setShowPassword] = useState(false);
//   const handleClickShowPassword = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleMouseDownPassword = (
//     event: React.MouseEvent<HTMLButtonElement>
//   ) => {
//     event.preventDefault();
//   };

//   return (
//     <>
//       <div className={classes.form}>
//         <TextField
//           error={errors["emailAddress"] ? true : false}
//           id="emailAdress"
//           value={user.emailAddress}
//           onChange={({ currentTarget }) =>
//             setUser({ ...user, emailAddress: currentTarget.value })
//           }
//           helperText={errors["emailAddress"] ? errors.emailAddress[0] : false}
//           label="Adresse email"
//           className={classes.field}
//         />
//         <FormControl className={clsx(classes.margin)}>
//           <InputLabel htmlFor="standard-adornment-password">
//             Mot de passe
//           </InputLabel>
//           <Input
//             error={errors["password"] ? true : false}
//             id="standard-adornment-password"
//             type={showPassword ? "text" : "password"}
//             value={user.password}
//             onChange={({ currentTarget }) =>
//               setUser({ ...user, password: currentTarget.value })
//             }
//             endAdornment={
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   onClick={handleClickShowPassword}
//                   onMouseDown={handleMouseDownPassword}
//                 >
//                   {showPassword ? <Visibility /> : <VisibilityOff />}
//                 </IconButton>
//               </InputAdornment>
//             }
//           />
//         </FormControl>
//         {serverError && (
//           <Typography
//             className={classes.caption}
//             variant="caption"
//             color="error"
//           >
//             {serverError}
//           </Typography>
//         )}
//         <Button
//           color="secondary"
//           variant="contained"
//           className={classes.button}
//           onClick={handleValidation}
//         >
//           Se connecter
//         </Button>
//       </div>
//     </>
//   );
// }

function Logger() {
  const classes = useStyles();
  const authentication = useContext(UserContext);

  const [activeTab, setActiveTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, value: any) =>
    setActiveTab(value);
  return (
    <>
      {authentication && (
        <div className={classes.container}>
          <Paper elevation={3} className={classes.paper}>
            {/* <Tabs
              value={activeTab}
              // indicatorColor="secondary"
              // textColor="secondary"
              color="secondary"
              onChange={handleChange}
            >
              <Tab label="Log In" />
              <Tab label="Sign Up" />
            </Tabs> */}
            {/* <TabPanel value={activeTab} index={0}> */}
            <LogIn authentication={authentication} />
            {/* </TabPanel> */}
            {/* <TabPanel value={activeTab} index={1}>
              <SignUp authentication={authentication} />
            </TabPanel> */}
          </Paper>
        </div>
      )}
    </>
  );
}

export default Logger;
