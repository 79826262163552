import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useIntl } from "react-intl";

import { Toolbar, Typography, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(4),
    },
    left: {
      display: "flex",
      alignItems: "center",
    },
  })
);

type Props = {
  setValue?: (value: any) => void;
  title: string;
  children: React.ReactNode;
  value?: string;
};

function TableActionBar({ setValue, title, children, value }: Props) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Toolbar className={clsx(classes.root)}>
      <div className={classes.left}>
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {title}
        </Typography>
        {setValue && (
          <TextField
            value={value}
            onChange={(event) => setValue(event.target.value)}
            id="search"
            label={intl.formatMessage({
              id: "TABLEACTIONBAR.RECHERC",
              defaultMessage: "Rechercher",
            })}
            type="search"
          />
        )}
      </div>
      {children}
    </Toolbar>
  );
}

export default TableActionBar;
