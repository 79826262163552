import React, { useState, useEffect, ChangeEvent } from "react";
import {
  TextField,
  Typography,
  Button,
  Paper,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  useHistory,
  useLocation as useRouterLocation,
  useRouteMatch,
} from "react-router-dom";
import { Salesperson } from "../../types";
import { useUserContext } from "../../authentication";
import { useMutation, useQueryClient } from "react-query";
import { updateOne, createOne, deletePOST } from "../../API";
import { isNotAuthorized } from "../../utils";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "70%",
      marginTop: theme.spacing(4),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    publishBtn: {
      width: "100%",
    },

    previewImage: {
      maxWidth: 300,
      maxHeight: 180,
      objectFit: "contain",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    buttonWrapper: {
      position: "relative",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    none: {
      display: "none",
    },
    image: {
      display: "flex",
      flexDirection: "column",
    },
    deleteBtnDialog: {
      marginRight: theme.spacing(3),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      width: "100%",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    alertBtn:{
      marginTop: theme.spacing(2),
      width: "100%",
    }
  })
);

const SalespersonForm = ({
  salesperson,
  action,
}: {
  salesperson?: Salesperson;
  action: "edit" | "create";
}) => {
  const intl=useIntl();

  const classes = useStyles();
  const history = useHistory();
  // const { params } = useRouteMatch<{ id: string }>();
  const authentication = useUserContext();
  const { pathname } = useRouterLocation();
  // const findPathId = pathname.match(/([1-9][0-9]*)/);


  // value to create or update
  const [value, setValue] = useState<Partial<Salesperson>>(
    salesperson
      ? salesperson
      : {
          first_name: "",
          last_name: "",
          details: "",
        }
  );

  // pour que react-query ait le temps de recevoir l'info après une édition et un retour
  useEffect(() => {
    if (salesperson) {
      setValue(salesperson);
    }
  }, [salesperson]);

  const handleValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  // mutate request
  // const [trigger, setTrigger] = useState(false);

  // API calls

  // Pour l'instant on laisse ça ici (peut-être abstraire)
  const formatValue = (rawValue: any) => {
    const { id, ...attributes } = rawValue;

    const formattedValue = {
      attributes: { ...attributes },
      ...(id && { id }),
    };
    return formattedValue;
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: any) =>
      action === "edit"
        ? updateOne(
            "salespeople",
            formatValue(data),
            authentication.accessToken
          )
        : createOne(
            "salespeople",
            formatValue(data),
            authentication.accessToken
          ),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        // successAlert();
        setTimeout(() => {
          history.goBack();
        }, 500);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("salespeople");
      },
    }
  );


  const handlePublish = () => {
    mutation.mutate(value);
  };

  const [deleteSucess, setDeleteSucess] = useState(false);

  const handleDelete = async () => {
    try {
      const response = await deletePOST(
        "salespeople",
        authentication.accessToken,
        value.id
      );
      if (response) {
          setDeleteSucess(true);
        setTimeout(() => {
          history.goBack();
        }, 2000);
      }
    } catch (e: any) {
      return { error: e.message };
    }
  };

  return (
    <>
      <Paper className={classes.paper} elevation={1}>
        <form className={classes.form}>
          <Typography
            className={classes.sectionLabel}
            color="textSecondary"
            variant="h6"
          >
            <FormattedMessage
            id="SALESPERSONFORM.TEXT_COMMERCIAL"
            defaultMessage="Commercial"/>
          </Typography>

          <TextField
            className={classes.textField}
            helperText={
              <FormattedMessage
              id="SALESPERSONFORM.HELPERTXT_PRENOM"          
              defaultMessage="Prénom"/>}
            value={value.first_name}
            onChange={(event) => handleValue(event)}
            label={
              <FormattedMessage
              id="SALESPERSONFORM.LABELTXT_PRENOM"  
              defaultMessage="Prénom"/>}
            name="first_name"
          />
          <TextField
            className={classes.textField}
            helperText={
              <FormattedMessage
              id="SALESPERSONFORM.HELPERTXT_NOM"  
              defaultMessage="Nom"/>}
            value={value.last_name}
            onChange={(event) => handleValue(event)}
            label={
              <FormattedMessage
              id="SALESPERSONFORM.LABELTXT_NOM"  
              defaultMessage="Nom"/>}
            name="last_name"
          />
          <TextField
            className={classes.textField}
            helperText={
              <FormattedMessage
              id="SALESPERSONFORM.HELPERTXT_DETAILS"  
              defaultMessage="Détails"/>}
            value={value.details}
            onChange={(event) => handleValue(event)}
            label={
              <FormattedMessage
              id="SALESPERSONFORM.LABELTXT_DETAILS"
              defaultMessage="Détails"/>}
            name="details"
          />

          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.publishBtn}
              onClick={handlePublish}
            >
              {action === "edit" ? <FormattedMessage id="SALESPERSONFORM.ACTION_BTM_MODIF_COMMERCIAL" defaultMessage="Modifier le commercial"/> : <FormattedMessage id="SALESPERSONFORM.ACTION_BTN_CREER" defaultMessage="Créer"/>}
            
            </Button>

            {action !== "create" && (
              <Button
                className={classes.deleteBtnDialog}
                variant={"outlined"}
                size="medium"
                color={"default"}
                onClick={() => handleDelete()}
              >
                <FormattedMessage
                id="SALESPERSONFORM.BTN_SUPPR"
                defaultMessage="Supprimer"/>
              </Button>
            )}
            {deleteSucess && (
              <Alert className={classes.alertBtn} severity="success">
                <FormattedMessage
                id="SALESPERSONFORM.ALERTE_SUPPR_OK"
                defaultMessage="La suppression a été enregistrée"/></Alert>
            )}
          </div>
        </form>
      </Paper>
    </>
  );
};

export default SalespersonForm;
