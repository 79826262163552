import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useHistory, useLocation as useRouterLocation, useRouteMatch } from "react-router-dom";
import {
  Typography,
} from "@material-ui/core";
import ArticleForm from "../shared/ArticleForm";
import { useQuery } from "react-query";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import Notifications from "../shared/Notifications";

require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: 100,
      marginBottom: theme.spacing(4),
    },
    paper: {
      width: "70%",
      padding: theme.spacing(3),
    },
  })
);

const LocationNewsEdit = () => {
  const classes = useStyles();
  const history = useHistory()
  const { params, url } = useRouteMatch<{ id: string, location_id: string }>();
  const authentication = useUserContext();

  const fetchObject = {
    id: params.id,
    // include: ["group", "owner", "group.profile"],
    // fields: {
    //   groups: ["id", "name", "profile"],
    //   owners: ["id", "first_name", "last_name"],
    //   profiles: ["id", "name"],
    // },
  };
  const query = useQuery(["articles", fetchObject], () =>
  fetchOne("articles", fetchObject, authentication.accessToken)
);
const { status } = query;
const article = query?.data?.result;

  return (
    <>
      {/* {!article && action === "create" && <Typography className={classes.title} variant="h4">
        Nouvelle actualité
      </Typography>
      } */}
      {article && <Typography className={classes.title} variant="h4">
        {article?.title && article.title !== "" ? article.title : "Article sans nom"}
      </Typography>
      }
      {article && <ArticleForm article={article} action="edit" />}
      <Notifications online={(article?.displayed_status === "online") ? true : false} firebase_id={article?.firebase_id} firebase_collection={article?.firebase_collection} />
    </>
  );
};

export default LocationNewsEdit;
