import React, { useCallback, useState } from "react";
import { Button, CircularProgress, Paper, Typography } from "@material-ui/core";
import { Article, Category, Collection } from "../../types";
import PhoneBody from "./PhoneBody";
import HomeIcon from "@material-ui/icons/Home";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import update from "immutability-helper";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { isEqual } from "lodash";
import { massUpdateArticles, massUpdateCategories } from "../../API";
import { useUserContext } from "../../authentication";
import PhoneBanner from "./PhoneBanner";
import { ArticleDisplayPreview } from "../../types/article";
import { useMutation, useQueryClient } from "react-query";
import { isNotAuthorized } from "../../utils";
import { FormattedMessage } from "react-intl";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
    },
    extraMargin: {
      marginTop: theme.spacing(4),
    },
    hide: {
      opacity: 0,
    },
    phoneContainer: {
      // marginTop: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      width: "400px",
      minWidth: "400px",
      maxHeight: "84vh",
      overflowY: "scroll",
      // border: "2px grey solid"
    },

    banner: {
      width: "100%",
      height: 70,
    },
    appBar: {
      color: theme.palette.navBar.contrastText,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: "100%",
      height: 50,
      backgroundColor: theme.palette.navBar.main,
    },
    navBar: {
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: "100%",
      height: 50,
      backgroundColor: theme.palette.background.paper,
    },
    navBarText: {
      color: theme.palette.tab.main,
      fontWeight: 500,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    selectedBar: {
      position: "relative",
      top: "11px",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      height: 3,
      backgroundColor: theme.palette.tab.main,
    },
    body: {
      display: "flex",
      flexWrap: "wrap",
    },
    isModification: {
      marginBottom: theme.spacing(2),
      // float: "right",
      // top: -60,
      // position: "relative",
    },
    btns: {
      marginBottom: theme.spacing(2),
    },
    textBg: {
      top: 125,
      textAlign: "center",
      position: "relative",
      backgroundColor: "rgba(242,242,242,.85)",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    bannerContainer: {
      width: "100%",
      height: 150,
      marginBottom: theme.spacing(2),
    },
    loader: {
      position: "relative",
      right: 70,
      top: 4,
    },
  })
);

type Props = {
  categories?: Category[];
  articles?: ArticleDisplayPreview[];
  collection: Collection;
  queryKey?: [string, object];
};
// querykey passée pour l'update dans al mutation
const PhonePreview = ({
  categories,
  articles,
  collection,
  queryKey,
}: Props) => {
  const classes = useStyles();
  const authentication = useUserContext();

  const handleCollectionName = (collectionName: Collection) => {
    switch (collectionName) {
      case "Offers":
        return appConfig.sections.offers;
      case "Services":
        return appConfig.sections.services;
      case "Stories":
        return appConfig.sections.news;
      case "Categories":
        return appConfig.sections.directory;
      default:
        break;
    }
  };

  const [onlineCategories, setOnlineCategories] = useState<Category[] | null>(
    categories || null
  );
  const [prevOnlineCategories, setPrevOnlineCategories] = useState<
    Category[] | null
  >(categories || null);

  useEffect(() => {
    // On récupère les categories, sauf si on édite (!equality)
    if (categories && (!onlineCategories || equality)) {
      setOnlineCategories(categories);
      setPrevOnlineCategories(categories);
    }
  }, [categories]);

  // useEffect(() => {
  //   if (categories) {
  //     const sortedOnlineCatgories =
  //       categories &&
  //       categories
  //         .filter((c) => c.directory_home)
  //         .sort((a: Category, b: Category) => {
  //           if (
  //             a.position !== (undefined || null) &&
  //             b.position !== (undefined || null)
  //           ) {
  //             // pas "if a.position" car 0 serait false
  //             return a.position - b.position;
  //           } else return 0;
  //         });
  //     sortedOnlineCatgories.map((category, i) => {
  //       category.position = i + 1;
  //     });
  //     setOnlineCategories(sortedOnlineCatgories);
  //     setPrevOnlineCategories(sortedOnlineCatgories);
  //   }
  // }, [categories]);

  const [onlineArticles, setOnlineArticles] = useState<
    ArticleDisplayPreview[] | null
  >(articles || null);

  const [prevOnlineArticles, setPrevOnlineArticles] = useState<
    ArticleDisplayPreview[] | null
  >(articles || null);

  useEffect(() => {
    // On récupère les articles, sauf si on édite (!equality)
    if (articles && (!onlineArticles || equality)) {
      setOnlineArticles(articles);
      setPrevOnlineArticles(articles);
    }
  }, [articles]);

  const moveArticle = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (onlineArticles) {
        const dragArticle = onlineArticles[dragIndex];
        const newArticles = update(onlineArticles, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragArticle],
          ],
        });
        newArticles.map((article, i) => {
          article.position = i + 1;
        });
        setOnlineArticles(newArticles);
      }
      if (onlineCategories) {
        const dragCategory = onlineCategories[dragIndex];
        const newCategories = update(onlineCategories, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCategory],
          ],
        });
        newCategories.map((article, i) => {
          article.position = i + 1;
        });

        setOnlineCategories(newCategories);
      }
    },
    [onlineArticles, onlineCategories]
  );

  const [equality, setEquality] = useState<boolean>(true);
  useEffect(() => {
    if (onlineArticles) {
      setEquality(isEqual(onlineArticles, prevOnlineArticles));
    }
    if (onlineCategories) {
      setEquality(isEqual(onlineCategories, prevOnlineCategories));
    }
  }, [categories, onlineCategories, onlineArticles]);

  const deleteModifications = () => {
    if (onlineArticles) {
      setOnlineArticles(prevOnlineArticles);
    }
    if (onlineCategories) {
      setOnlineCategories(prevOnlineCategories);
    }
  };

  // update categories in batch
  // const [trigger, setTrigger] = useState(false);
  // const [mutateMassCategories, { status: mutateCategoriesStatus }] = useMassUpdateCategories({ authentication })
  const queryClient = useQueryClient();
  const articleMutation = useMutation(
    (data: any) =>
      massUpdateArticles(data || [], authentication.accessToken || undefined),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: (data) =>
        queryClient.setQueryData(queryKey || [], data.result),
      onSettled: () => {
        setUpdatingPosition(false);
      },
    }
  );

  const categoryMutation = useMutation(
    (data: any) =>
      massUpdateCategories(data || [], authentication.accessToken || undefined),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: (data) =>
        queryClient.setQueryData(queryKey || [], data.result),
      onSettled: () => {
        setUpdatingPosition(false);
      },
    }
  );

  // useEffect(() => {
  //   if (trigger) {
  //     (async function createLocationPlan() {
  //       try {
  //         const massCategories =
  //           onlineCategories &&
  //           onlineCategories.map(({ id, position }) => ({
  //             id,
  //             position,
  //           }));
  //         if (massCategories) {
  //           // await mutateMassCategories(massCategories)
  //           setTrigger(false);
  //         }
  //       } catch (error: any) {
  //         setTrigger(false);
  //         console.warn(error);
  //       }
  //     })();
  //   }
  // }, [trigger]);

  // update articles in batch
  const [updatingPosition, setUpdatingPosition] = useState(false);
  const saveCategoriesModifications = async () => {
    setUpdatingPosition(true);
    const categoriesWithPosOnly =
      onlineCategories &&
      onlineCategories.map(({ id, position }) => ({
        id,
        position,
      }));

    categoryMutation.mutate(categoriesWithPosOnly);
  };

  const saveArticlesModifications = async () => {
    setUpdatingPosition(true);
    const articlesWithPosOnly =
      onlineArticles &&
      onlineArticles.map(({ id, position }) => ({
        id,
        position,
      }));

    articleMutation.mutate(articlesWithPosOnly);
  };

  // update articles or categories
  const saveModifications = () => {
    if (onlineArticles) {
      saveArticlesModifications();
    }
    if (onlineCategories) {
      saveCategoriesModifications();
    }
  };

  return (
    <div className={classes.container}>
      {collection !== "Categories" && <div className={classes.extraMargin} />}

      {collection !== "Stories" && (
        <>
          <Typography variant="h6" id="tableTitle" component="div">
            <FormattedMessage
              id="PHONE_PREVIEW.ELEMENTS_POSITION"
              defaultMessage="Position des éléments"
            />
          </Typography>
          <div className={classes.btns}>
            <Button
              disabled={
                equality || updatingPosition
                // || mutateCategoriesStatus === "loading"
              }
              onClick={saveModifications}
              variant="outlined"
            >
              <FormattedMessage
              id="PHONE_PREVIEW.BTN_SAUVEGARDE"
              defaultMessage="Sauvegarder"/>
            </Button>
            {updatingPosition && (
              // || (mutateCategoriesStatus === "loading"
              <CircularProgress
                thickness={4.5}
                size={20}
                className={classes.loader}
                color="secondary"
              />
            )}
            <Button
              disabled={
                equality || updatingPosition
                // || mutateCategoriesStatus === "loading"
              }
              onClick={deleteModifications}
            >
              <FormattedMessage
              id="PHONE_PREVIEW.BTN_ANNULER"
              defaultMessage="Annuler"/>
            </Button>
          </div>
        </>
      )}

      <Paper elevation={2} className={classes.phoneContainer}>
        <div className={classes.appBar}>
          <HomeIcon />
          <Typography variant="h6">
            {handleCollectionName(collection)}
          </Typography>
          <MoreVertIcon />
        </div>
        <div className={classes.navBar}>
          <div>
            <Typography className={classes.navBarText}>
              {appConfig.sections.offers}
            </Typography>
            {collection === "Offers" && <div className={classes.selectedBar} />}
          </div>
          <div>
            <Typography className={classes.navBarText}>
            {appConfig.sections.services}
            </Typography>
            {collection === "Services" && (
              <div className={classes.selectedBar} />
            )}
          </div>
          <div>
            <Typography className={classes.navBarText}>
            {appConfig.sections.news}
            </Typography>
            {collection === "Stories" && (
              <div className={classes.selectedBar} />
            )}
          </div>
          <div>
            <Typography className={classes.navBarText}>
            {appConfig.sections.directory}
            </Typography>
            {collection === "Categories" && (
              <div className={classes.selectedBar} />
            )}
          </div>
        </div>
        <div className={classes.body}>
          {collection === "Services" && (
            <PhoneBanner
              articles={
                onlineArticles &&
                onlineArticles.filter((a) => a.format === "banner")
              }
            />
          )}
          {onlineArticles &&
            onlineArticles.map((article, index) => {
              return (
                <PhoneBody
                  moveArticle={moveArticle}
                  collection={collection}
                  key={article.id}
                  article={article}
                  index={index}
                />
              );
            })}

          {onlineCategories &&
            onlineCategories.map((category, index) => {
              return (
                <PhoneBody
                  moveArticle={moveArticle}
                  collection={collection}
                  key={category.id}
                  category={category}
                  index={index}
                />
              );
            })}
        </div>
      </Paper>
    </div>
  );
};

export default PhonePreview;
