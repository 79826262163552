import React from "react";
import {useIntl} from "react-intl";
import {
  Paper,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import EventChart from "../shared/EventChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    marginB: {
      marginBottom: theme.spacing(4),
    },
    spinner: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    largeDivider: {
      width: "80vw",
    },
    paper: {
      width: "100%",
      padding: theme.spacing(3),
    },
  })
);

function StatisticsHome() {
    const classes = useStyles();
    const intl = useIntl();

  return (
    <>
      <Paper className={classes.paper}>
        <EventChart
          eventName="first_open"
          title={intl.formatMessage({id:'STATISTICSHOME.LANCEMENT_PREMIERE_UTILIS',defaultMessage:"Nombre de lancements (première utilisation)"})}
         
        />
        <EventChart
          eventName="session_start"
          title={intl.formatMessage({id:'STATISTICSHOME.VISITE',defaultMessage:"Nombre de visites"})}
         />
        <EventChart
          eventName="session_start"
          title={intl.formatMessage({id:'STATISTICSHOME.VISIT_UNIQUE',defaultMessage:"Nombre de visiteurs uniques"})}
          uniq={true}
        />
      </Paper>
    </>
  );
}

export default StatisticsHome;
