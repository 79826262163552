import React, { useState, ChangeEvent, useEffect } from "react";
import {
  TextField,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SimpleSelect from "./SimpleSelect";
import ActionForm from "./ActionForm";
import moment from "moment";
import { Article, Action, Location, Salesperson } from "../../types";
import { isArticleCollection } from "../../types/toBeDetermined";
import { createOne, fetchList, updateOne } from "../../API";
import { useUserContext } from "../../authentication";
import { isArticle } from "../../types/article";
import { useImage, uploadImage } from "../../firebase";
import { v4 } from "uuid";
import {
  MailAction,
  SMSAction,
  DialAction,
  WebAction,
} from "../../types/action";
import { useHistory, useLocation as useRouterLocation } from "react-router-dom";
import { useValidator } from "../../utils/useValidator";
import ColoredTag from "./ColoredTag";
import { capitalize } from "lodash";
import ReactPlayer from "react-player";
import SlateEditor from "./SlateEditor";
import Alert from "@material-ui/lab/Alert";
import DateForm from "./DateForm";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { isNotAuthorized } from "../../utils";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(4),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    image: {
      display: "flex",
      flexDirection: "column",
    },
    previewImage: {
      maxWidth: 300,
      maxHeight: 180,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    previewMedia: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    none: {
      display: "none",
    },
    divider: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    date: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    datePicker: {
      width: "45%",
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    hidden: {
      display: "none",
    },
    buttons: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
    },
    publishBtn: {
      marginRight: theme.spacing(3),
    },
    draftBtn: {
      marginRight: theme.spacing(3),
      // marginRight: theme.spacing(2)
    },
    deleteBtn: {
      marginRight: theme.spacing(3),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    status: {
      display: "flex",
      flexDirection: "row",
    },
    errorUnderBtn: {
      display: "flex",
      //justifyContent: "center",
    },
    actionsBtns: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      // justifyContent: "space-between",
    },
    addActionBtn: {
      marginRight: theme.spacing(2),
    },
    articleStatus: {
      marginRight: theme.spacing(2),
    },
    // illimited: {
    //   float: "right"
    // },
    alert: {
      marginTop: theme.spacing(2),
    },
  })
);

type Props = {
  article?: Article;
  action: string;
};

interface UseRouterLocationI {
  locationId: Location["id"];
}

const ArticleForm = ({ article, action }: Props) => {
  const intl = useIntl();

  const classes = useStyles();
  const authentication = useUserContext();
  const history = useHistory();

  // depuis l'url on retrouve l'Id et la Collection
  const { pathname } = useRouterLocation();
  const findPathId = pathname.match(/([1-9][0-9]*)/);
  const pathId = findPathId && findPathId[0];
  const splitPath = pathname.split("/");
  const collection = splitPath[1];
  const capitalizedCollection = capitalize(collection);

  const addAction = () => {
    const newAction: WebAction = {
      type: "web",
      format: "cta",
      web: {
        url: "",
      },
    };
    setValue({ ...value, actions: [...value.actions, newAction] });
  };

  const today = moment().format("YYYYMMDD");

  const emptyArticle: Article = {
    id: "",
    plan: null,
    media: "",
    position: null,
    title: "",
    editor: authentication?.user ? { id: authentication.user.id } : null,
    headline: null,
    date_start: collection !== "LocationNews" && today,
    date_end: collection !== "LocationNews" && today,
    actions: [],
    text: "",
    rich_text: null,
    image: "",
    firebase_collection: isArticleCollection(capitalizedCollection)
      ? capitalizedCollection
      : "LocationNews",
    status: "draft",
    format: "",
    displayed_status: "draft",
    // location_id: pathId && +pathId,
    location: null,
    salesperson: null,
  };

  const fullArticle = article || emptyArticle;
  const { salesperson: coreSalesperson, ...coreArticle } = fullArticle;
  const [value, setValue] = useState<Article>(coreArticle);
  const [salesperson, setSalesperson] = useState<string>(
    coreSalesperson?.id || "none"
  );
  const imageUrl = useImage(value.image);
  const mediaUrl = useImage(value.media || "");

  const [localImage, setLocalImage] = useState<null | File>(null);
  const [localMedia, setLocalMedia] = useState<null | File>(null);

  const [deleteDialog, setDeleteDialog] = useState(false);

  

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Pas de "." dans le nom d'image sur firebase, sinon le resize bug (croit que c'est l'extension)
    const imageName = `${value.title.replaceAll(".", " ")}-${v4()}`;
    setValue({ ...value, image: imageName });
    if (event.target.files) {
      setLocalImage(event.target.files[0]);
    }
  };

  const handleMediaUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Pas de "." dans le nom d'image sur firebase, sinon le resize bug (croit que c'est l'extension)
    const mediaName = `${value.title.replaceAll(".", " ")}-${v4()}`;

    setValue({ ...value, media: mediaName });
    if (event.target.files) {
      setLocalMedia(event.target.files[0]);
    }
  };

  const handlePlatformOnlyCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event.target);
    // To be able to select one or the other or nothing, we set it both to false and add the last value on top
    setValue({
      ...value,
      ios_only: false,
      android_only: false,
      [event.target.name]: event.target.checked,
    });
  };
  // console.log("article", article)
  // console.log("value", value)

  const handleValue = (
    event: ChangeEvent<{ name?: any; value: any }>,
    field: string
  ) => {
    switch (field) {
      case "title":
        setErrorMessage({ ...errorMessage, title: null });
        setValue({ ...value, title: event.target.value });
        break;
      case "headline":
        setValue({ ...value, headline: event.target.value });
        break;
      case "text":
        setValue({ ...value, text: event.target.value });
        break;
      case "image":
        setValue({ ...value, image: event.target.value });
        break;
      case "media":
        setValue({ ...value, media: event.target.value });
        break;
      default:
        setValue({ ...value, [field]: event.target.value });
        break;
    }
  };

  const setValueWrapper = (object: Article | Action, index?: number) => {
    if (isArticle(object)) {
      setValue(object);
    } else {
      if (object.type === "mail" && index !== undefined) {
        const mailAction: MailAction = {
          type: "mail",
          format: "cta",
          mail: {
            content: "",
            object: "",
            address: "",
          },
        };
        value.actions.splice(index, 1, mailAction);
      } else if (object.type === "sms" && index !== undefined) {
        const smsAction: SMSAction = {
          type: "sms",
          format: "cta",
          sms: {
            content: "",
            number: "",
          },
        };
        value.actions.splice(index, 1, smsAction);
      } else if (object.type === "phone" && index !== undefined) {
        const phoneAction: DialAction = {
          type: "phone",
          format: "cta",
          phone: {
            number: "",
          },
        };
        value.actions.splice(index, 1, phoneAction);
      } else if (object.type === "web" && index !== undefined) {
        const webAction: WebAction = {
          type: "web",
          format: "cta",
          web: {
            url: "",
          },
        };
        value.actions.splice(index, 1, webAction);
      }
      setValue({ ...value, actions: [...value.actions] });
    }
  };

  const deleteAction = (index: number) => {
    if (index === 0) {
      setErrorMessage({ ...errorMessage, web0: null });
    }
    if (index === 1) {
      setErrorMessage({ ...errorMessage, web1: null });
    }
    value.actions.splice(index, 1);
    setValue({ ...value, actions: [...value.actions] });
  };

  const handleStartDateChange = (date: any) => {
    const formatedDate = moment(date._d).format("YYYY-MM-DD");
    setValue({ ...value, date_start: formatedDate });
  };

  const handleEndDateChange = (date: any) => {
    const formatedDate = moment(date._d).format("YYYY-MM-DD");
    setValue({ ...value, date_end: formatedDate });
  };

  // API calls

  // Pour l'instant on laisse ça ici (peut-être abstraire)
  const formatValue = (rawValue: any) => {
    const { id, location, editor, actions, rich_text, plan, ...attributes } =
      rawValue;

    const formattedValue = {
      // type: "articles",
      attributes: {
        ...attributes,
        plan: !plan || plan === "none" ? null : plan,
        actions: JSON.stringify(actions),
        rich_text: JSON.stringify(rich_text),
      },
      ...(id && { id }),
      relationships: {
        ...(location && {
          location: { data: { type: "locations", id: location.id } },
        }),
        ...(editor && { editor: { data: { type: "users", id: editor.id } } }),
        ...(salesperson && {
          salesperson: {
            data:
              salesperson === "none"
                ? null
                : { type: "salespeople", id: salesperson },
          },
        }),
      },
    };
    return formattedValue;
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: any) =>
      action === "edit"
        ? updateOne("articles", formatValue(data), authentication.accessToken)
        : createOne("articles", formatValue(data), authentication.accessToken),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        // successAlert();
        queryClient.resetQueries();
        queryClient.clear();
        queryClient.refetchQueries();
        queryClient.refetchQueries(collection);
        queryClient.refetchQueries("articles");
        setTimeout(() => {
          history.push(`/${collection}`);
        }, 500);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.resetQueries();
        queryClient.clear();
        queryClient.refetchQueries();
        queryClient.refetchQueries(collection);
        queryClient.refetchQueries("articles");
      },
    }
  );

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (trigger)
      (async () => {
        try {
          const pop = await mutation.mutate(value);
          setTrigger(false);

          // if (value.firebase_collection === "LocationNews") {
          //   history.push(`/locations/${pathId}`, {
          //     locationId: pathId,
          //     action: action === "edit" ? "edit" : "create",
          //     tabIndex: 1,
          //   });
          // } else {
          //   history.push(`/${collection.toLowerCase()}`);
          // }
        } catch (error: any) {
          setTrigger(false);
          console.warn(error);
        }
      })();
  }, [trigger]);

  const handlePublish = async (newStatus: Article["status"]) => {
    setValue({ ...value, status: newStatus });
    if (value.title === "") {
      setErrorMessage({
        ...errorMessage,
        title: intl.formatMessage({id:'ARTICLEFORM.MSG_OUBLITITRE',defaultMessage:"Vous avez oublié de mettre un titre"}),
      });
      return;
    }
    if (value.actions[0]?.web && value.actions[0].web.url === "") {
      setErrorMessage({
        ...errorMessage,
        web0: intl.formatMessage({id:'ARTICLEFORM.MSG_URL_INCORRECT_1',defaultMessage:`L'url de la première action est incorrecte (indice: elle doit commencer par "http://" ou "https://")`}),
      });
      return;
    }
    if (value.actions[1]?.web && value.actions[1].web.url === "") {
      setErrorMessage({
        ...errorMessage,
        web0: intl.formatMessage({id:'ARTICLEFORM.MSG_URL_INCORRECT_2',defaultMessage:`L'url de la première action est incorrecte (indice: elle doit commencer par "http://" ou "https://")`}),
      });
      return;
    }
    if (errorMessage.title || errorMessage.web0 || errorMessage.web1) {
      return;
    }
    if (localImage && newStatus !== "deleted") {
      uploadImage(localImage, value.image);
    }
    if (localMedia && newStatus !== "deleted") {
      uploadImage(localMedia, value.media || "");
    }
    setTrigger(true);
  };

  const { checkField, errorMessage, setErrorMessage } = useValidator();

  const salespersonFetchObject = {
    fields: { salespeople: ["first_name", "last_name", "id"] },
    sort: "last_name",
  };
  const {
    data: salespersonData,
    status,
    isPreviousData,
  } = useQuery<{
    results: Salesperson[];
  }>(
    ["salespeople", salespersonFetchObject],
    () =>
      fetchList(
        "salespeople",
        salespersonFetchObject,
        authentication.accessToken
      ),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  const handleCommercials = (event: any) => {
    setSalesperson(event.target.value as string);
  };

  const dateLimitationFormat = (() => {
    switch (value.displayed_status) {
      case "draft":
        return "will_happen";
      case "online":
        return "is_happening";
      case "expired":
        return "happened";
      default:
        return "none";
    }
  })();



  return (
    <Paper className={classes.paper} elevation={1}>
      <form className={classes.form}>
        <Typography
          className={classes.sectionLabel}
          color="textSecondary"
          variant="h6"
        >
          <FormattedMessage
          id="ARTICLEFORM.INFO_GENE"
          defaultMessage="Informations Générales"/>
        </Typography>
        {value.firebase_collection === "Offers" && (
          <div className={classes.textField}>
            <InputLabel required id="plan-select-label">
              Plan
            </InputLabel>

            <Select
              labelId="plan-select-label"
              id="plan-simple-select"
              value={value.plan || "none"}
              label="Sélectionner"
              onChange={(event) => handleValue(event, "plan")}
            >
              <MenuItem value={"none"} key="none">
                <FormattedMessage
                id="ARTICLEFORM.MENU_ITEM_AUCUN_1"
                defaultMessage="Aucun"/>
              </MenuItem>
              {["Bronze", "Silver", "Gold"].map((x: string) => (
                <MenuItem value={x} key={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        <TextField
          onBlur={(event) => checkField(event.target.value, "title")}
          required
          error={errorMessage.title ? true : false}
          className={classes.textField}
          defaultValue={value.title}
          onChange={(event) => handleValue(event, "title")}
          label={intl.formatMessage({id:'ARTICLEFORM.TEXTFIELD_TITRE',defaultMessage:"Titre"})}
          helperText={errorMessage.title && errorMessage.title}
        />

        {(value.firebase_collection === "Offers" ||
          value.firebase_collection === "Services") && (
          <>
            <TextField
              className={classes.textField}
              defaultValue={value.headline}
              onChange={(event) => handleValue(event, "headline")}
              label={
                value.firebase_collection === "Offers"
                  ? intl.formatMessage({id:'ARTICLEFORM.PHRASE_ACCROCHE',defaultMessage:"Phrase d'accroche de la carte"})
                  : intl.formatMessage({id:'ARTICLEFORM.DESCRIP_SERVICE',defaultMessage:"Description du service"})
              }
              // onBlur={() => checkField("headline")}
            />
            {errorMessage.headline && (
              <Alert severity="warning" className={classes.alert}>
                {errorMessage.headline}
              </Alert>
            )}
          </>
        )}

        <div className={classes.textField}>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="ARTICLEFORM.TEXTE" defaultMessage="Texte"/>
          </Typography>
          <SlateEditor
            field="rich_text"
            text={value?.text ? value.text : ""}
            valueArticle={value}
            setValueArticle={setValue}
          />
        </div>

        {(value.firebase_collection === "Offers" ||
          value.firebase_collection === "Services") && (
          <div className={classes.textField}>
            <SimpleSelect
              object={value}
              objectKey="format"
              value={value.format}
              setValue={setValueWrapper}
              title="Format"
              items={
                value.firebase_collection === "Offers"
                  ? [
                      { value: "tile", name: intl.formatMessage({id:'ARTICLEFORM.COMBO_TUILE',defaultMessage:"Tuile"}) },
                      { value: "big_tile", name: intl.formatMessage({id:'ARTICLEFORM.COMBO_BIG_TUILE',defaultMessage:"Tuile large"}) },
                    ]
                  : [
                      { value: "banner", name: intl.formatMessage({id:'ARTICLEFORM.COMBO_BANNIERE',defaultMessage:"Bannière"}) },
                      { value: "card", name: intl.formatMessage({id:'ARTICLEFORM.COMBO_CARTE',defaultMessage:"Carte"}) },
                    ]
              }
            />
          </div>
        )}

        <div className={classes.sectionLabel}>
          <Typography color="textSecondary" variant="h6">
            <FormattedMessage
            id="ARTICLEFORM.MEDIA_TEXT"
            defaultMessage="Médias"/>
          </Typography>
          {/* <Typography>
            Recommandations de format:
        </Typography> */}
          <Typography variant="body2">
            <FormattedMessage
            id="ARTICLEFORM.TEXT_TUILES"
            defaultMessage="Pour les tuiles l'image doit être au format 1024/768 px ou 720/540
            px"/>
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
            id="ARTICLEFORM.TEXT_BANNIERE"
            defaultMessage="Pour les bannières l'image doit être au format 1024/384 px ou
            720/270 px"/>
          </Typography>
        </div>

        <div className={classes.image}>
          <div className={classes.actionsBtns}>
            <input
              accept="image/*"
              className={classes.none}
              id="contained-button-image"
              multiple
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="contained-button-image">
              <Button
                className={classes.publishBtn}
                size="medium"
                variant="outlined"
                component="span"
              >
                <FormattedMessage
                id="ARTICLEFORM.BTN_AJOUT_IMAGE"
                defaultMessage="Ajouter une image"/>
              </Button>
            </label>
            {value?.image && (
              <Button
                onClick={() => setValue({ ...value, image: "" })}
                size="medium"
                variant="outlined"
                component="span"
              >
                <FormattedMessage
                  id="ARTICLEFORM.BTN_SUPPR_IMAGE"
                  defaultMessage="Supprimer l'image"/>
              </Button>
            )}
          </div>

          {value.image !== "" && (
            <>
              <img
                alt={article?.image ? " " + article.image : "image"}
                className={classes.previewImage}
                style={{ objectFit: "cover" }}
                src={localImage ? URL.createObjectURL(localImage) : imageUrl}
              />
            </>
          )}
        </div>
        {(value.firebase_collection === "Offers" ||
          value.firebase_collection === "Services") && (
          <div className={classes.image}>
            <div className={classes.actionsBtns}>
              <input
                accept="video/*"
                className={classes.none}
                id="contained-button-video"
                // multiple
                type="file"
                onChange={handleMediaUpload}
              />
              <label htmlFor="contained-button-video">
                <Button
                  className={classes.publishBtn}
                  size="medium"
                  variant="outlined"
                  component="span"
                >
                  <FormattedMessage
                  id="ARTICLEFORM.BTN_AJOUT_VIDEO"
                  defaultMessage="Ajouter une vidéo"/>
                </Button>
              </label>
              {value?.media && (
                <Button
                  onClick={() => setValue({ ...value, media: "" })}
                  size="medium"
                  variant="outlined"
                  component="span"
                >
                  <FormattedMessage
                  id="ARTICLEFORM.BTN_SUPPR_VIDEO"
                  defaultMessage="Supprimer la vidéo"/>
                </Button>
              )}
            </div>
            {value.media && value.media !== "" && (
              <MemoizedPlayer localMedia={localMedia} mediaUrl={mediaUrl} />
            )}
          </div>
        )}
        {/* <TextField
          disabled
          defaultValue={value.media}handleEndDateChange
          onChange={(event) => handleValue(event, "media")}
          label="URL (uniquement pour les vidéos)"
        /> */}
        {/* <Typography color="textSecondary" style={{ marginTop: 2 }} variant="caption">La fonctionnalité d'ajout de vidéo sera disponible dans la prochaine version</Typography> */}

        <div className={classes.sectionLabel}>
          <Typography color="textSecondary" variant="h6">
            <FormattedMessage
            id="ARTICLEFORM.BTN_CREER"
            defaultMessage="Créer un bouton"/>
          </Typography>
        </div>

        <div className={classes.textField}>
          {value.actions.map((action, index) => (
            <div key={+value.id + index}>
              <ActionForm
                checkField={checkField}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                setValue={setValue}
                value={value}
                action={action}
                index={index}
                setValueWrapper={setValueWrapper}
              />
              {(value.firebase_collection === "Offers" ||
                value.firebase_collection === "Services") && (
                <div className={classes.actionsBtns}>
                  {index < 1 && (
                    <Button
                      className={classes.addActionBtn}
                      onClick={() => addAction()}
                      size="medium"
                      variant="outlined"
                      component="span"
                    >
                      <FormattedMessage
                      id="ARTICLEFORM.BTN_AJOUT_ACTION_1"
                      defaultMessage="Ajouter une action"/>
                    </Button>
                  )}
                  <Button
                    onClick={() => deleteAction(index)}
                    size="medium"
                    component="span"
                  >
                    <FormattedMessage
                    id="ARTICLEFORM.BTN_SUPPR_ACTION_1"
                    defaultMessage="supprimer l'action"/>
                  </Button>
                </div>
              )}
            </div>
          ))}
          {(value.firebase_collection === "Stories" ||
            value.firebase_collection === "LocationNews") &&
            value.actions &&
            value.actions.length === 1 && (
              <Button
                onClick={() => deleteAction(0)}
                size="medium"
                component="span"
              >
                <FormattedMessage
                id="ARTICLEFORM.BTN_SUPPR_ACTION_2"
                defaultMessage="supprimer l'action"/>
              </Button>
            )}
          {value.actions && value.actions.length === 0 && (
            <Button
              onClick={addAction}
              size="medium"
              variant="outlined"
              component="span"
            >
              <FormattedMessage
              id="ARTICLEFORM.BTN_AJOUT_ACTION_2"
              defaultMessage="Ajouter une action"/>
            </Button>
          )}
        </div>

        {value.firebase_collection !== "Services" && (
          <>
            <div className={classes.sectionLabel}>
              <Typography color="textSecondary" variant="h6">
                Dates
              </Typography>
            </div>

            <DateForm
              format={
                value.firebase_collection === "Offers"
                  ? "weeks_from_start"
                  : "limits"
              }
              date_start={value.date_start}
              date_end={value.date_end}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              limitationFormat={dateLimitationFormat}
            />
          </>
        )}

        <div className={classes.sectionLabel}>
          <Typography color="textSecondary" variant="h6">
            Publication
          </Typography>
          <div className={classes.status}>
            <Typography className={classes.articleStatus}>
              <FormattedMessage
              id="ARTICLEFORM.TEXT_STATUT"
              defaultMessage="statut actuel"/>
                  :{" "}
            </Typography>
            <ColoredTag value={value.displayed_status} />
          </div>
          <Typography className={classes.articleStatus}>
              <FormattedMessage
              id="ARTICLEFORM.IOS_SEUL"
              defaultMessage="seulement ios"/>:{" "}
            <Checkbox
              name="ios_only"
              checked={value.ios_only}
              onChange={(event) => handlePlatformOnlyCheckbox(event)}
            />
              <FormattedMessage
              id="ARTICLEFORM.ANDROID_SEUL"
              defaultMessage="seulement android"/>:{" "}
            <Checkbox
              name="android_only"
              checked={value.android_only}
              onChange={(event) => handlePlatformOnlyCheckbox(event)}
            />
          </Typography>
        </div>

        <div className={classes.sectionLabel}>
          {pathname.includes("offers") && (
            <div className={classes.textField}>
              <InputLabel required id="commercial-select-label">
                <FormattedMessage
                id="ARTICLEFORM.INPUT_LAB_COMMERCIAL"
                defaultMessage="Commercial"/>
              </InputLabel>

              <Select
                labelId="commercial-select-label"
                id="commercial-simple-select"
                value={salesperson}
                label={
                  <FormattedMessage
                  id="ARTICLEFORM.LAB_SELECTION_COMM"  
                  defaultMessage="Sélectionner"/>}
                onChange={handleCommercials}
              >
                <MenuItem value={"none"} key="none">
                  <FormattedMessage
                  id="ARTICLEFORM.MENU_ITEM_AUCUN_2"
                  defaultMessage="Aucun"/>
                </MenuItem>
                {salespersonData?.results.map((x: Partial<Salesperson>) => (
                  <MenuItem value={x.id} key={x.id}>
                    {x.last_name} {x.first_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
      </form>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.publishBtn}
          onClick={() => handlePublish("validated")}
        >
          {value.firebase_collection === "LocationNews" ||
          value.firebase_collection === "Services"
            ? intl.formatMessage({id:'ARTICLEFORM.MSG_PUBLIER',defaultMessage:"Publier"})
            : intl.formatMessage({id:'ARTICLEFORM.BTN_PUBLIER_DU',defaultMessage:"Publier du {date_start} au {date_end} "},{date_start:moment(value.date_start).format("l"),date_end:moment(value.date_end).format("l")})  
          }
            
        </Button>

        <Button
          className={classes.draftBtn}
          onClick={() => handlePublish("draft")}
        >
          {value.status === "draft"
            ? intl.formatMessage({id:'ARTICLEFORM.BTN_ENREGBROUILLON',defaultMessage:"Enregistrer en brouillon"})
            : intl.formatMessage({id:'ARTICLEFORM.BTN_PASSERBROUILLON',defaultMessage:"Passer comme brouillon"})}
        </Button>

        <Button
          className={classes.deleteBtn}
          onClick={() => setDeleteDialog(true)}
          variant="outlined"
        >
          <FormattedMessage
          id="ARTICLEFORM.BTN_SUPPR"
          defaultMessage="Supprimer"/>
        </Button>

        <Dialog
          onClose={() => setDeleteDialog(false)}
          aria-labelledby="simple-dialog-title"
          open={deleteDialog}
        >
          <DialogTitle id="simple-dialog-title">
            <FormattedMessage
            id="ARTICLEFORM.DIALOG_SUPPR_ARTICLE"
            defaultMessage="Supprimer l'article ?"/>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <FormattedMessage
              id="ARTICLEFORM.MSG_SUPPRESSION_ARTICLE"
              defaultMessage="Êtes-vous sûr de vouloir supprimer cet article ? Cette action est
              définitive."/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteDialog(false)}
              color="primary"
              autoFocus
            >
              <FormattedMessage
              id="ARTICLEFORM.BTN_ANNULER"
              defaultMessage="Annuler"/>
            </Button>
            <Button
              variant="outlined"
              className={classes.deleteBtn}
              onClick={() => handlePublish("deleted")}
            >
              <FormattedMessage
              id="ARTICLEFORM.BTN_SUPPR_OK"
              defaultMessage="Supprimer"/>
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {errorMessage.title && (
        <div className={classes.errorUnderBtn}>
          <Alert severity="warning" className={classes.alert}>
            <FormattedMessage
            id="ARTICLEFORM.ALERTE_NOTITRE"
            defaultMessage="Vous avez oublié de mettre un titre"/>
          </Alert>
        </div>
      )}
      {errorMessage.web0 && (
        <div className={classes.errorUnderBtn}>
          <Alert severity="warning" className={classes.alert}>
            <FormattedMessage id="ARTICLEFORM.ALERTE_URL_1" defaultMessage="L'url de la première action est incorrecte indice: elle doit
              commencer par http:// ou https://"/>
          </Alert>
        </div>
      )}
      {errorMessage.web1 && (
        <div className={classes.errorUnderBtn}>
          <Alert severity="warning" className={classes.alert}>
            <FormattedMessage id="ARTICLEFORM.ALERTE_URL_2" defaultMessage="L'url de la deuxième action est incorrecte indice: elle doit
            commencer par http:// ou https://"/>
          </Alert>
        </div>
      )}
    </Paper>
  );
};

// Le player est memoizé pour éviter les flickers lors de l'édition des autres champs (en mode create surtout)
const MemoizedPlayer = React.memo(function ({ localMedia, mediaUrl }: any) {
  const classes = useStyles();
  return (
    <div className={classes.previewMedia}>
      <ReactPlayer
        controls={true}
        url={localMedia ? URL.createObjectURL(localMedia) : mediaUrl}
      />
    </div>
  );
});

// ArticleForm.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default ArticleForm;
