import React, { useState } from "react";
import { Bar } from "@reactchartjs/react-chart.js";
import DateForm from "./DateForm";
import moment from "moment";
import { useUserContext } from "../../authentication";
import { useEventChartData } from "../../API";
import { Paper, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const StackedBar = ({
  data,
  legend = true,
}: {
  legend?: boolean;
  data: any;
}) => {
  const options = {

    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  };



  return (
    <>
      <Bar type={Bar} data={data} options={options} />
    </>
  );
};
export default StackedBar;
