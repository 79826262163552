import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import { Typography, Tabs, Tab, Toolbar } from "@material-ui/core";
import AdministratorForm from "../Administrators/AdministratorForm";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import TabPanel from "../shared/TabPanel";
import { useQuery } from "react-query";
import Loader from "../shared/Loader";
import { User } from "../../types";
import { FormattedMessage, useIntl } from "react-intl";
require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    paper: {
      width: "70%",
      padding: theme.spacing(3),
    },
    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    tab: {
      color: theme.palette.tab.main
    }
  })
);

export interface AdminPreview extends Partial<User> {
  email: string;
  id: string;
}


const AdministratorEdit = () => {
  const intl=useIntl();
  const authentication = useUserContext();
  const classes = useStyles();


  // ROUTING
  const { params } = useRouteMatch<{ id: string }>();
  // DATA QUERY
  const fetchObject = {
    id: params.id,
    fields: { users: ["email", "id"] },
  };

  const query = useQuery<{
    result: AdminPreview;
  }>(["users", fetchObject], () =>
    fetchOne("users", fetchObject, authentication.accessToken)
  );

  const { status } = query;
  const admin = query?.data?.result;

  const [tab, setTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  return (
    <div className={classes.tab}>
      <Toolbar />
      {admin && (
        <Typography className={classes.title} variant="h4">
          {admin.email}
        </Typography>
      )}

      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {admin && <Tab label={intl.formatMessage({id:"ADMINISTRATOREDIT.TABLAB_EDITION",defaultMessage:"Édition"}) } />}
      </Tabs>
      <TabPanel value={tab} index={0}>
        {status === "loading" && <Loader />}
        {status === "error" && (
          <Typography><FormattedMessage id="ADMINISTRATOREDIT.TEXT_NOTHISADMIN" defaultMessage="Cet administrateur n'existe pas"/></Typography>
        )}
        {status === "success" && (
          <AdministratorForm admin={admin} action={"edit"} />
        )}
      </TabPanel> 
     
    </div>
  );
};

export default AdministratorEdit;
