import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import { Typography, Tabs, Tab, Toolbar } from "@material-ui/core";
import AdministratorForm from "../Administrators/AdministratorForm";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import TabPanel from "../shared/TabPanel";
import { useQuery } from "react-query";
import Loader from "../shared/Loader";
import { Salesperson, User } from "../../types";
import { Plans } from "../../types/plans";
import PlanForm from "./PlanForm";
import { FormattedMessage, useIntl } from "react-intl";
require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    paper: {
      marginTop: theme.spacing(4),
      width: "70%",
      padding: theme.spacing(3),
    },
    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    tab: {
      color: theme.palette.tab.main
    }
  })
);



const PlanEdit = () => {
  const intl=useIntl();
  const authentication = useUserContext();
  const classes = useStyles();


  // ROUTING
  const { params } = useRouteMatch<{ id: string }>();
  // DATA QUERY
  const fetchObject = {
    id: params.id,
  };

  const query = useQuery<{
    result: Plans;
  }>(["plans", fetchObject], () =>
    fetchOne("plans", fetchObject, authentication.accessToken)
  );

  const { status } = query;
  const plan = query?.data?.result;

  const [tab, setTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  return (
    <div className={classes.tab}>
      <Toolbar />
      {plan && (
        <Typography className={classes.title} variant="h4">
          {plan.printed_name}
        </Typography>
      )}

      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {plan && <Tab label={intl.formatMessage({id:"PLANEDIT.TABLAB_EDITION",defaultMessage:"Édition"})} />}
      </Tabs>
      <TabPanel value={tab} index={0}>
        {status === "loading" && <Loader />}
        {status === "error" && (
          <Typography><FormattedMessage id="PLANEDIT.TEXT_NOTHISPLAN" defaultMessage="Ce plan n'existe pas"/></Typography>
        )}
        {status === "success" && (
          <PlanForm plan={plan} action={"edit"} />
        )}
      </TabPanel> 
     
    </div>
  );
};
// SalespersonEdit.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default PlanEdit;
