import { Link } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import LinesEllipsis from "react-lines-ellipsis";

import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      fontWeight: 500,
      cursor: "pointer",
    },
  })
);
type Props = {
  onClick?: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
};
export default function TableLink({ onClick, children, style }: Props) {
  const classes = useStyles();
  return (
    <p style={{margin: 0, ...(style && style)}}>
      <Link
        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
        color="textPrimary"
        className={classes.link}
        onClick={onClick}
      >
        {typeof children === "string" ? (
          <LinesEllipsis
            text={children}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        ) : (
          children
        )}
      </Link>
    </p>
  );
}
