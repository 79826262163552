import React, { useState, ChangeEvent } from "react";
import {
  CircularProgress,
  TextField,
  Typography,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Input,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Location } from "../../types";
import { createOne, fetchList, updateOne, validateOne } from "../../API";
import { useUserContext } from "../../authentication";
import { useImage, uploadImage } from "../../firebase";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import FBImage from "../shared/FBImage";
import GMap from "../shared/GMap";
import { useValidator } from "../../utils/useValidator";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { validate } from "validate.js";
import { isNotAuthorized } from "../../utils";
import RejectModal from "../RejectModal";
import { createNumericLiteral } from "typescript";
import { deletePOST } from "../../API";
import { FormattedMessage } from "react-intl";
import { FormatAlignCenter } from "@material-ui/icons";
import {useIntl} from "react-intl";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    imageManager: {
      display: "flex",
      flexDirection: "column",
    },
    none: {
      display: "none",
    },
    divider: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    date: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    datePicker: {
      width: "45%",
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    category: {
      marginTop: theme.spacing(2),
      width: "50%",
    },
    categoryContainer: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
    },
    publishBtn: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    deleteBtn: {
      width: "100%",
      marginTop: theme.spacing(2),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    errorUnderBtn: {},
    btnCategories: {
      display: "flex",
      marginTop: theme.spacing(2),
      justifyContent: "space-between",
    },
    buttonWrapper: {
      position: "relative",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    images: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      margin: theme.spacing(2),
    },
    alert: {
      marginTop: theme.spacing(2),
    },
    alertBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    hidden: {
      opacity: 0,
    },
    modale: {
      position: "absolute",
      width: 800,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modaleSpacing: {
      marginTop: theme.spacing(2),
    },
    textArea: {
      width: 700,
    },
    deleteBtnDialog: {
      marginRight: theme.spacing(3),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
  })
);

type Props = {
  location?: Location;
  action: string;
  //locations?: Location[] | null;
};

const LocationsForm = ({
  // locations,
  location,
  action,
}: Props) => {
  const intl=useIntl();

  const classes = useStyles();
  const authentication = useUserContext();
  const history = useHistory();

  // fetch the categories
  const categoryFetchObject = {
    fields: { categories: ["name", "id"] },
    sort: "name",
    page: {
      number: 1,
      size: 100,
    },
  };
  

  const { data: categoryData } = useQuery<{
    results: { name: string; id: string }[];
    pageCount: number;
    total: number;
  }>(["categories", categoryFetchObject], () =>
    fetchList("categories", categoryFetchObject, authentication.accessToken)
  );

  const categories = categoryData?.results && categoryData?.results;

  const selectableCategories = [
    {
      id: "0",
      name: intl.formatMessage({
        id:'LOCATIONSFORM.CATEGORIE_NONDEF',
        defaultMessage:"Pas de catégorie"
      }),
    }];
  if (categories) {
    categories.forEach((category) => selectableCategories.push(category));
  }

  const emptyLocation: Location = {
    rccm_number: null,
    legal_form: null,
    categories: [],
    id: "",
    image: "",
    images: [],
    name: "",
    description: "",
    short_description: "",
    address: "",
    articles: location?.articles ? location.articles : [],
    owners: null,
    hours: "",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    // geolocation: "",
    // sponsored: false,
    // sponsored_end_at: "",
    // sponsored_start_at: "",
    firebase_collection: "Locations",
    editor: authentication.user,
    longitude: null,
    latitude: null,
    validated: location?.validated && location.validated,
  };

  const [value, setValue] = useState<Location>(
    location ? location : emptyLocation
  );

  const handleValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof Location
  ) => {
    switch (field) {
      case "name":
        setErrorMessage({ ...errorMessage, title: null });
        setValue({ ...value, name: event.target.value });
        break;
      case "description":
        setValue({ ...value, description: event.target.value });
        break;
      case "short_description":
        setValue({ ...value, short_description: event.target.value });
        break;
      case "legal_form":
        setValue({ ...value, legal_form: event.target.value });
        break;
      case "rccm_number":
        setValue({ ...value, rccm_number: event.target.value });
        break;
      case "address":
        setValue({ ...value, address: event.target.value });
        break;
      case "latitude":
        setValue({ ...value, latitude: +event.target.value });
        break;
      case "longitude":
        setValue({ ...value, longitude: +event.target.value });
        break;
      case "phone":
        setValue({ ...value, phone: event.target.value });
        break;
      case "email":
        setValue({ ...value, email: event.target.value });
        break;
      case "website":
        setErrorMessage({ ...errorMessage, webLocation: null });
        setValue({ ...value, website: event.target.value });
        break;
      case "facebook":
        setValue({ ...value, facebook: event.target.value });
        break;
      case "hours":
        setValue({ ...value, hours: event.target.value });
        break;
      default:
        break;
    }
  };

  const handleCategory = (
    event: React.ChangeEvent<{ value: unknown }>,
    i: number
  ) => {
    const categoryId = +(event.target.value as string);
    const newCategories = value.categories;
    newCategories.splice(i, 1, categoryId ? { id: categoryId } : null);
    // Tableau des nouvelles catégories, trous bouchés au cas où
    setValue({
      ...value,
      categories: [...newCategories.filter((el) => el != null)],
    });
  };

  // Pour l'instant on laisse ça ici (peut-être abstraire)
  const formatValue = (rawValue: any) => {
    // ici ce qui est retiré des attributs
    const {
      id,
      owners,
      products,
      categories,
      category_ids,
      firebase_collection,
      editor,
      articles,
      plan,
      rejection_message,
      sponsored,
      sponsored_end_at,
      sponsored_start_at,
      sponsorised,
      updated_at,
      validated,
      workflow_state,
      location_source,
      location_destination,
      firebase_id,
      comments,
      created_at,
      ...attributes
    } = rawValue;

    const formattedValue = {
      // type: "articles",
      attributes: { ...attributes },
      ...(id && { id }),
      relationships: {
        // On n'édite que les catégories
        // ...(owners && { owners: { data: owners.map((owner: any) => ({ type: "users", id: owner.id })) } }),
        // ...(products && { products: { data: products.map((product: any) => ({ type: "products", id: product.id })) } }),
        ...(categories && {
          categories: {
            data: categories.map((category: any) => ({
              type: "categories",
              id: category.id,
            })),
          },
        }),
      },
    };
    return formattedValue;
  };

  const [isSaved, setIsSaved] = useState<"saved" | "not saved" | "">();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) =>
      action === "edit"
        ? updateOne("locations", formatValue(data), authentication.accessToken)
        : createOne("locations", formatValue(data), authentication.accessToken),
    {
      onError: (e: any) => {
        setIsSaved("not saved");
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        setIsSaved("saved");
        setTimeout(() => {
          setIsSaved("");
        }, 3000);
        // successAlert();
        // setTimeout(() => {
        //   history.goBack();
        // }, 500);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("locations");
      },
    }
  );

  const handlePublish = async () => {
    if (errorMessage.title || errorMessage.webLocation) {
      return;
    } else {
      if (localImages.length === 0) {
        // mutateLocation({ ...value, validated });
        // history.push("/locations");

        mutation.mutate(value);
      }

      const promises: Promise<string>[] = [];
      if (localImages.length !== 0) {
        localImages.forEach((img, i) => {
          // On replace les points car le resizer ne supporte pas
          const ref = `${location?.name.replaceAll(".", " ")}-${i}-${Date.now()}`;
          const task = uploadImage(img, ref).then(() => ref);
          if (task) promises.push(task);
        });
        Promise.all(promises).then((newImages) => {
          const finalImages = [...value.images, ...newImages];
          mutation.mutate({ ...value, images: finalImages });
          // mutateLocation({ ...value, images: finalImages });
        });
      }
    }
  };

  const [localImages, setLocalImages] = useState<File[]>([]);

  const imageUrl = useImage(value.image);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const imageName = `${value.name}-${v4()}`;
    if (event.target.files) {
      setLocalImages([...localImages, event.target.files[0]]);
    }
  };

  const removeOnlineImage = (name: string) => {
    const filteredLocationImages = value.images.filter(
      (image: string) => image !== name
    );
    setValue({
      ...value,
      images: filteredLocationImages,
    });
  };

  const removeLocalImage = (img: File) => {
    setLocalImages(
      localImages.filter((localImg) => localImg.name !== img.name)
    );
  };

  const imagesCount: number = localImages.length + value.images.length;

  // URL ERROR

  const { checkField, errorMessage, setErrorMessage } = useValidator();

  // validation de l'établissement
  const [isValid, setIsValid] = useState(false);
  const [validationError, setValidationError] = useState<string>();
  const validateLocation = async () => {
    const response = await validateOne(
      "locations",
      value.id,
      "validate",
      authentication.accessToken
    );
    if (response?.data?.attributes?.workflow_state === "validated") {
      setIsValid(true);
      queryClient.invalidateQueries("locations");
    } else {
      setValidationError("impossible de valider l'établissement");
    }
  };

  // refus de l'établissement
  const [isRejected, setIsRejected] = useState(false);

  //////////////////////////////
  /////////////// DELETE ///////
  //////////////////////////////

  const [deleteOk, setDeleteOk] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleDelete = async () => {
    try {
      const response = await deletePOST(
        "locations",
        authentication.accessToken,
        value.id
      );
      if (response) {
        setDeleteDialog(false);
        setDeleteOk(true);
        setTimeout(() => {
          setDeleteOk(false);
          history.goBack();
        }, 1000);
      }
    } catch (e: any) {
      return { error: e.message };
    }
  };
  /////////////Fin delete //////////////
  //////////////////////////////////////

  return (
    <Paper className={classes.paper} elevation={1}>
      <form className={classes.form}>
        {value?.workflow_state === "rejected" && (
          <Alert className={classes.alert} severity="warning">
            <FormattedMessage
            id="LOCATIONSFORM.ALERTE_REJECT_ETABLISSEMENT_1"
            defaultMessage="Cet établissement à déjà été rejeté"/>
          </Alert>
        )}

        {value?.workflow_state == "awaiting_validation" &&
          !isValid &&
          !isRejected && (
            <Alert className={classes.alert} severity="warning">
              <FormattedMessage
              id="LOCATIONSFORM.ALERTE_VALID_ETABLISSEMENT_1"
              defaultMessage="Cet établissement doit être validé"/>
            </Alert>
          )}
        <Typography
          className={classes.sectionLabel}
          color="textSecondary"
          variant="h6"
        >
          <FormattedMessage
          id="LOCATIONSFORM.INFO_GEN"
          defaultMessage="Informations Générales"/>
        </Typography>
        <TextField
          required
          defaultValue={value.name}
          onChange={(event) => handleValue(event, "name")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_NOMCOMMERCE',defaultMessage:"Nom du commerce"})}
          error={errorMessage.title ? true : false}
          onBlur={(event) => checkField(event.target.value, "title")}
          helperText={errorMessage.title && errorMessage.title}
        />
        <TextField
          className={classes.textField}
          defaultValue={value.short_description}
          onChange={(event) => handleValue(event, "short_description")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_DESCRIPCOURT',defaultMessage:"Description courte du commerce"})}
        />
        <TextField
          className={classes.textField}
          multiline
          rows={4}
          defaultValue={value.description}
          onChange={(event) => handleValue(event, "description")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_DESCRIPLONG', defaultMessage:"Description longue du commerce"})}
        />
        <TextField
          className={classes.textField}
          defaultValue={value.legal_form}
          onChange={(event) => handleValue(event, "legal_form")}
          label={intl.formatMessage({id:"LOCATIONSFORM.FORM_JURI",defaultMessage:"Forme juridique de l'établissement"})}
        />
        <TextField
          className={classes.textField}
          defaultValue={value.rccm_number}
          onChange={(event) => handleValue(event, "rccm_number")}
          label={appConfig.tradeRegisterNumber}
        />
        {/* if there is one or more categories */}
        <div className={classes.categoryContainer}>
          {[0, 1, 2].map((cat) => (
            <div key={`category_${cat}`}>
              <FormControl className={classes.category}>
                <InputLabel id={`category-select-label`}><FormattedMessage id="LOCATIONSFORM.INPUTLABEL_CATEG" defaultMessage="Catégorie"/></InputLabel>

                <Select
                  labelId={`category-select-label`}
                  id={`category-select`}
                  value={value.categories[cat]?.id || 0}
                  onChange={(event) => handleCategory(event, cat)}
                  disabled={value.categories.length < cat}
                >
                  {selectableCategories.map((cat: any) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
        </div>

        {location?.category_suggestion && (
          <Alert severity="info" className={classes.alert}>
            <FormattedMessage
            id="LOCATIONSFORM.ALERTE_SUGGES_CATEG"
            defaultMessage="Ce client suggère de créer la catégorie"/>{" "}
            {location?.category_suggestion.toUpperCase()}
          </Alert>
        )}

        <div className={classes.sectionLabel}>
          <Typography color="textSecondary" variant="h6">
            <FormattedMessage id="LOCATIONSFORM.TEXTE_ADRESSE" defaultMessage="Adresse"/>
          </Typography>
        </div>

        <GMap value={value} setLocation={setValue} />

        <TextField
          className={classes.textField}
          value={value.address}
          onChange={(event) => handleValue(event, "address")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_ADRESSE', defaultMessage:"Adresse"})}
        />
        {/* <TextField
          className={classes.textField}
          value={value.latitude}
          onChange={(event) => handleValue(event, "latitude")}
          label="latitude"
        />
        <TextField
          className={classes.textField}
          value={value.longitude}
          onChange={(event) => handleValue(event, "longitude")}
          label="longitude"
        /> */}
        <div className={classes.sectionLabel}>
          <Typography color="textSecondary" variant="h6">
              <FormattedMessage
              id="LOCATIONSFORM.MEDIA_TEXT"
              defaultMessage="Médias"/>
          </Typography>
          {/* <Typography variant="caption">(il ne peut y avoir qu'un média, la vidéo sera choisie en priorité)
          </Typography> */}
        </div>
        <div className={classes.imageManager}>
          <input
            accept="image/*"
            className={classes.none}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="contained-button-file">
            <Button size="medium" variant="outlined" component="span">
              <FormattedMessage
              id="LOCATIONSFORM.BTN_AJOUT_FILE"
              defaultMessage="Ajouter une fichier"/>
            </Button>
          </label>

          {(value.images.length > 0 || localImages.length > 0) && (
            <div className={classes.images}>
              {value.images &&
                value.images.map((img) => {
                  const width = imagesCount < 2 ? 360 : 180;
                  return (
                    <div key={img} style={{ position: "relative" }}>
                      <IconButton
                        size="small"
                        style={{
                          backgroundColor: "white",
                          position: "absolute",
                          top: 4,
                          right: 4,
                        }}
                        onClick={() => removeOnlineImage(img)}
                        aria-label="delete"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                      {/* <Button style={{position: "absolute"}}>-</Button> */}
                      <FBImage
                        style={{
                          width,
                          height: (width * 3) / 4,
                          objectFit: "cover",
                        }}
                        image={`${img}_360x360`}
                      />
                    </div>
                  );
                })}
              {localImages &&
                localImages.map((image) => {
                  const width = imagesCount < 2 ? 360 : 180;
                  return (
                    <div style={{ position: "relative" }}>
                      <IconButton
                        size="small"
                        style={{
                          backgroundColor: "white",
                          position: "absolute",
                          top: 4,
                          right: 4,
                        }}
                        onClick={() => removeLocalImage(image)}
                        aria-label="delete"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                      <img
                        style={{
                          width,
                          height: (width * 3) / 4,
                          objectFit: "cover",
                        }}
                        src={URL.createObjectURL(image)}
                        // alt="preview-image"
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className={classes.sectionLabel}>
          <Typography color="textSecondary" variant="h6">
            Description
          </Typography>
        </div>
        <TextField
          className={classes.textField}
          defaultValue={value.phone}
          onChange={(event) => handleValue(event, "phone")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_TELEPH',defaultMessage:"Téléphone"})}
        />
        <TextField
          className={classes.textField}
          defaultValue={value.email}
          onChange={(event) => handleValue(event, "email")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_ADRESSMAIL',defaultMessage:"Adresse email"})}
        />
        <TextField
          className={classes.textField}
          defaultValue={value.website}
          onChange={(event) => handleValue(event, "website")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_INTERNETSITE',defaultMessage:"Site internet"})}
          onBlur={(event) => checkField(event.target.value, "webLocation")}
          error={errorMessage.webLocation ? true : false}
          helperText={errorMessage.webLocation && errorMessage.webLocation}
        />
        <TextField
          className={classes.textField}
          defaultValue={value.facebook}
          onChange={(event) => handleValue(event, "facebook")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_PAGEFACEBOOK', defaultMessage:"Page facebook"})}
        />
        <div className={classes.sectionLabel}>
          <Typography color="textSecondary" variant="h6">
            <FormattedMessage id="LOCATIONSFORM.TEXTE_HORAIRE" defaultMessage="Horaires"/>
          </Typography>
        </div>
        <TextField
          className={classes.textField}
          defaultValue={value.hours}
          onChange={(event) => handleValue(event, "hours")}
          label={intl.formatMessage({id:'LOCATIONSFORM.LABEL_HEUREJOUR',defaultMessage:"Heures et jours d'ouvertures"})}
        />

        {value?.workflow_state === "awaiting_validation" ||
          (value?.workflow_state === "validated" && (
            <div className={classes.buttonWrapper}>
              <Typography color="textSecondary" variant="h6">
                Validation
              </Typography>
            </div>
          ))}

        {value?.workflow_state === "awaiting_validation" && (
          <>
            <Button
              disabled={isValid || isRejected}
              variant="contained"
              color="secondary"
              className={classes.publishBtn}
              onClick={() => {
                validateLocation();
              }}
            >
              <FormattedMessage
              id="LOCATIONSFORM.BTN_VALID_ETABLISS"
              defaultMessage="Valider cet établissement"/>
            </Button>

            <RejectModal
              isValid={isValid}
              isRejected={isRejected}
              id={value.id}
              model="locations"
              setAlertReject={setIsRejected}
            />
          </>
        )}

        {value?.workflow_state === "rejected" && (
          <Alert className={classes.alert} severity="warning">
            <FormattedMessage
              id="LOCATIONSFORM.ALERTE_REJECT_ETABLISSEMENT_2"
              defaultMessage="Cet établissement à déjà été rejeté"/>
          </Alert>
        )}

        {!isRejected &&
          !isValid &&
          value?.workflow_state === "awaiting_validation" && (
            <Alert className={classes.alert} severity="warning">
              <FormattedMessage
              id="LOCATIONSFORM.ALERTE_VALID_ETABLISSEMENT_2"
              defaultMessage="Cet établissement doit être validé"/>
            </Alert>
          )}

        {isValid && !isRejected && (
          <Alert className={classes.alert} severity="success">
            <FormattedMessage
            id="LOCATIONSFORM.ALERTE_VALID_ETABLISSEMENT_3"
            defaultMessage="L'établissement est validé"/>
          </Alert>
        )}

        {value?.workflow_state === "validated" && (
          <Alert className={classes.alert} severity="success">
            <FormattedMessage
            id="LOCATIONSFORM.ALERTE_VALID_ETABLISSEMENT_4"
            defaultMessage="L'établissement est validé"/>
          </Alert>
        )}

        {validationError && (
          <Alert className={classes.alert} severity="warning">
            {validationError}
          </Alert>
        )}

        {isRejected && (
          <Alert className={classes.alert} severity="success">
            <FormattedMessage
            id="LOCATIONSFORM.ALERTE_REJECT_ETABLISSEMENT_3"
            defaultMessage="L'email a bien été envoyé, l'établissement est rejeté"/>
          </Alert>
        )}

        {value?.workflow_state !== "rejected" && (
          <div className={classes.buttonWrapper}>
            <Typography color="textSecondary" variant="h6">
              Publication
            </Typography>

            <Button
              className={classes.publishBtn}
              variant="outlined"
              onClick={handlePublish}
            >
                <FormattedMessage
                id="LOCATIONSFORM.BTN_SAVE"
                defaultMessage="Enregistrer"/>
            </Button>
            <div className={classes.errorUnderBtn}>
              {isSaved === "saved" && (
                <Alert severity="success" className={classes.alert}>
                  <FormattedMessage
                  id="LOCATIONSFORM.ALERTE_SAVE_MODIF"
                  defaultMessage="Les modifications ont été enregistrées"/>
                </Alert>
              )}
              {isSaved === "not saved" && (
                <Alert severity="warning" className={classes.alert}>
                  <FormattedMessage
                  id="LOCATIONSFORM.ALERTE_RJECT_MODIF"
                  defaultMessage="Problème, les modifications n'ont pas été enregistrées"/>
                </Alert>
              )}
              {errorMessage.title && (
                <Alert severity="warning" className={classes.alert}>
                  <FormattedMessage
                  id="LOCATIONSFORM.ALERTE_OUBLI_TITRE"
                  defaultMessage="Vous avez oublié de mettre un titre"/>
                </Alert>
              )}

              {errorMessage.webLocation && (
                <Alert severity="warning" className={classes.alert}>
                  <FormattedMessage
                  id="LOCATIONSFORM.ALERTE_URL_INCORRECT"
                  defaultMessage="l'url du site web est incorrecte"/>
                </Alert>
              )}
            </div>
          </div>
        )}

        {/* {value.owners?.length < 1 && ( */}
        {authentication?.user?.superadmin && (
          <>
            <Button
              variant="outlined"
              className={classes.deleteBtn}
              onClick={() => {
                setDeleteDialog(true);
              }}
            >
              <FormattedMessage
              id="LOCATIONSFORM.BTN_SUPPR_ETABLISS"
              defaultMessage="Supprimer cet établissement"/>
            </Button>

            <Dialog
              onClose={() => setDeleteDialog(false)}
              aria-labelledby="simple-dialog-title"
              open={deleteDialog}
            >
              <DialogTitle id="simple-dialog-title">
                <FormattedMessage
                id="LOCATIONSFORM.ALERTE_SUPPR_ETABLISSEMENT"
                defaultMessage="Supprimer l'établissement ?"/>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <FormattedMessage
                  id="LOCATIONSFORM.ALERTE_SUPPR_ETABLISSEMENT_CONFIRM"
                  defaultMessage="
                      Êtes-vous sûr de vouloir supprimer cet établissement ? Cette
                      action est définitive."/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setDeleteDialog(false)}
                  color="primary"
                  autoFocus
                >
                  <FormattedMessage
                  id="LOCATIONSFORM.BTN_ANNULER"
                  defaultMessage="Annuler"/>
                </Button>
                <Button
                  variant="outlined"
                  className={classes.deleteBtnDialog}
                  onClick={() => handleDelete()}
                >
                  <FormattedMessage
                  id="LOCATIONSFORM.BTN_SUPPR"
                  defaultMessage="Supprimer l'établissement"/>
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}

        {deleteOk && (
          <Alert className={classes.alert} severity="success">
            <FormattedMessage
            id="LOCATIONSFORM.ALERTE_SUPPR_ETABLISSEMENT_OK"
            defaultMessage="L'établissement a bien été supprimé"/>
          </Alert>
        )}
      </form>
    </Paper>
  );
};

// LocationsForm.whyDidYouRender = {
//   logOnDifferentValues: true,
// }

export default LocationsForm;
