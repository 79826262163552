import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%"
    }
  })
)

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <CircularProgress size={60} color="secondary" />
    </div>
  )
}

export default Loader
