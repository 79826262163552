import React, { memo, useState } from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { CircularProgress, TextField } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useEffect } from 'react';
import { isEqual } from "lodash"
import { Location } from "../../types"

const containerStyle = {
  maxWidth: "600px",
  height: '500px'
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "50%",
      marginTop: theme.spacing(5),
      marginRight: theme.spacing(2),
    },
    textField2: {
      width: "50%",
      marginTop: theme.spacing(5),

    },
    form: {
      marginBottom: theme.spacing(1),
      // maxWidth: 600,
      display: "flex",
      // flexDirection: "column",
      // marginBottom: theme.spacing(4),
      // paddingBottom: theme.spacing(4),
    },
    mapContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      width: "100%",
      height: 520,
    },
    mapTypo: {
      marginBottom: theme.spacing(2),
    },
    category: {
      marginTop: theme.spacing(2),
    },

  })
);


type Props = {
  value: Location;
  setLocation: React.Dispatch<React.SetStateAction<Location>>;
}

const GMap = ({ value, setLocation }: Props) => {
  const classes = useStyles();

  const googleMapAPIKey = "AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk"
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapAPIKey // ,
  })

  const handleLong = (event: any) => {
    setControlledLongitude(parseFloat(event.target.value) || null)
  }

  const handleLat = (event: any) => {
    setControlledLatitude(parseFloat(event.target.value) || null)
  }

  const [controlledLatitude, setControlledLatitude] = useState(value.latitude)
  const [controlledLongitude, setControlledLongitude] = useState(value.longitude)

  const validatePosition = (event: any) => {
    setLocation({
      ...value,
      latitude: controlledLatitude,
      longitude: controlledLongitude,
    })
  }

  const handleClick = (e: any) => {
    setLocation({
      ...value,
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng()
    });
    setNewGps({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng()
    });
    setControlledLongitude(e.latLng.lng())
    setControlledLatitude( e.latLng.lat())
  }

  const [newGps, setNewGps] = useState<{ latitude: number, longitude: number } | null>(null)
  useEffect(() => {
    if (value?.latitude === newGps?.latitude && value?.longitude === newGps?.longitude) {
      const lat = value.latitude;
      const lng = value.longitude;
      (async () => {
        const data = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk`, { method: "GET" },);
        const result = await data
          .json()
          .then((res: any) => {
            if (res?.results && res?.results[0]?.formatted_address) {
              setLocation({
                ...value,
                address: res.results[0].formatted_address,

              })
            }
          }
          )
      })()

    }
  }, [newGps])

  const locationPosition = () => {
    if (value.latitude && value.longitude) {
      return {
        lat: value.latitude,
        lng: value.longitude
      }
    } else return {
      // COTONOU POSITION
      lat: 6.36,
      lng: 2.40
    }

  };

  const [currentPosition, setCurrentPosition] = useState(locationPosition);

  const success = (position: any) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    setCurrentPosition(currentPosition);
  };

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    // const onLoad = React.useCallback(
    //   function onLoad (mapInstance) {
    //     // do something with map Instance
    //   }
    // ) 
    return <><GoogleMap
      mapContainerStyle={containerStyle}
      center={(isEqual(locationPosition, currentPosition)) ? locationPosition : currentPosition}
      zoom={10}
      onClick={(e) => handleClick(e)}
    >
      {value?.longitude && value?.latitude &&
        <Marker position={{ lat: value.latitude, lng: value.longitude }} />

      }
    </GoogleMap>

      <div className={classes.form}>

        <TextField
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          defaultValue={value.latitude}
          value={controlledLatitude}
          onChange={(event) => handleLat(event)}
          onBlur={(event) => validatePosition(event)}
          label={`latitude`}
          rows={1}
          type="number"
          inputProps={{ step: 0.01 }}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          className={classes.textField2}
          defaultValue={value.longitude}
          value={controlledLongitude}
          onBlur={(event) => validatePosition(event)}
          onChange={(event) => handleLong(event)}
          label={`longitude`}
          rows={1}
          type="number"
          inputProps={{ step: 0.01 }}
        />
      </div>
    </>
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <CircularProgress color="secondary" />

}

export default memo(GMap)
