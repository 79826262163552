import React from "react";
import { useQuery } from "react-query";
import { fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import { Article, Category } from "../../types";
import { ArticleDisplayPreview } from "../../types/article";
import { isNotAuthorized } from "../../utils";
import PhonePreview from "../shared/PhonePreview";

export default function CategoryPhone() {
  const authentication = useUserContext();

  // REQUEST (à partir des états et de columns)
  const fetchObject = {
    page: {
      number: 1,
      size: 50,
    },
    filters: { directory_home: true },
    sort: "position"
  };


  const { data, status } = useQuery<{
    results: Category[];
    pageCount: number;
    total: number;
  }>(
    ["categories", fetchObject],
    () => fetchList("categories", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e:any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  return  <PhonePreview queryKey={["categories", fetchObject]} categories={data?.results} collection="Categories" />;
}