import React, { useState } from "react";
import validate from "validate.js";
import appConfig from "../appConfig";
import { useIntl } from "react-intl";

export const useValidator = () => {
  const intl = useIntl();
  const constraints = {
    text: {
      presence: {
        allowEmpty: false,
        message: intl.formatMessage({id:'CONSTRAINTS.MSG_CHAMPOBLIG',defaultMessage:"^Ce champ ne peut pas être vide"})
      },
    },
    isEmpty: {
      presence: {
        allowEmpty: false,
        message: "^can be empty"
      }
    },
    web: {
      url: {
        schemes: ["http", "https"],
        message: intl.formatMessage({id:'CONSTRAINTS.MSG_URLINCORRECT',defaultMessage:`^L'url  est incorrecte (indice: elle doit commencer par "http://" ou "https://")`})
      },
    },
    mail: {
      email: {
        message: intl.formatMessage({id:'CONSTRAINTS.MSG_NOVALID',defaultMessage:"^Le mail n'est pas valide"}),
      },
    },
  }
  const [errorMessage, setErrorMessage] = useState<{
    title: null | string;
    headline: null | string;
    text: null | string;
    web0: null | string;
    web1: null | string;
    email: null | string;
    webLocation: null | string;
  }>({
    title: null,
    headline: null,
    text: null,
    web0: null,
    web1: null,
    email: null,
    webLocation: null,
  });

  const checkField = (value: any, field: string) => {
    let check;
    switch (field) {
      case "title":
        check = validate({ text: value }, constraints);
        if (check) {
          setErrorMessage({ ...errorMessage, title: check.text[0] });
        }
        break;
      case "web0":
      case "web1":
      case "web2":
      case "web3":
      case "web4":
        if (value.startsWith(appConfig.deeplinkPrefix)) {
          break;
        }
        console.log("hey", field);
        
        check = validate({ web: value }, constraints);
        if (check) {
          setErrorMessage({ ...errorMessage, [field]: check.web[0] });
        }
        break;
      case "webLocation":
        if (validate({ isEmpty: value }, constraints)) {
          setErrorMessage({ ...errorMessage, webLocation: null });
          break;
        }
        check = validate({ web: value }, constraints);
        if (check) {
          setErrorMessage({ ...errorMessage, webLocation: check.web[0] });
        }
        break;
      case "email":
        if (validate({ isEmpty: value }, constraints)) {
          setErrorMessage({ ...errorMessage, email: null });
          break;
        }
        check = validate({ mail: value }, constraints);
        if (check) {
          setErrorMessage({ ...errorMessage, email: check.mail[0] });
        }
      default:
        break;
    }
  };

  return { checkField, errorMessage, setErrorMessage };
};
