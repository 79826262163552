import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import ServicesHome from "./Services/ServicesHome";
import NewsHome from "./News/NewsHome";
// import LocationsHome from "./Locations/LocationsHome";
import LocationsEdit from "./Locations/LocationsEdit";
import LocationNewsEdit from "./Locations/LocationNewsEdit";
import ArticleEdit from "./shared/ArticleEdit";
import OffersHome from "./Offers/OffersHome";
import StatisticsHome from "./Statistics/StatisticsHome";
import ArticleCreate from "./shared/ArticleCreate";
import CategoryEdit from "./Categories/CategoryEdit";
import CategoryCreate from "./Categories/CategoryCreate";
import LocationsCreate from "./Locations/LocationsCreate";
import SalespersonCreate from "./Salesperson/SalespersonCreate";
import SalespersonTable from "./Salesperson/SalespersonTable";
import SalespersonEdit from "./Salesperson/SalespersonEdit";
import { useUserContext } from "../authentication";
import AdministratorCreate from "./Administrators/AdministratorCreate";
import AdministratorTable from "./Administrators/AdministratorTable";
import CategoriesTable from "./Categories/CategoriesTable";
import CategoryPhone from "./Categories/CategoryPhone";
import LocationsTable from "./Locations/LocationsTable";
import RouterTabs from "./shared/RouterTabs";
import AdministratorEdit from "./Administrators/AdministratorEdit";
import PlanTable from "./Plans/PlanTable";
import PlanCreate from "./Plans/PlanCreate";
import PlanEdit from "./Plans/PlanEdit";
import SettingsTable from "./Settings/SettingsTable";
import SettingsEdit from "./Settings/SettingsEdit";
import LocationStats from "./Locations/LocationStats";
import Reporting from "./Reporting";
import appConfig from "../appConfig";
import { useIntl } from "react-intl";
import {FormattedMessage } from "react-intl";

function Main() {
  const authentication = useUserContext();
  const intl= useIntl();
  return (
    <>
      <main>
        {/* Ci-dessous les sous-répertoires (tabs routés) */}
        <RouterTabs
          tabs={[
            { label: intl.formatMessage({id :'MAIN.ROUTERTAB_ETABLISS',defaultMessage :"Établissement"}), path: "/locations" },
            { label: intl.formatMessage({id:'MAIN.ROUTERTAB_CATEGORIE',defaultMessage : "Catégories"}), path: "/categories" },
            { label: intl.formatMessage({id:'MAIN.ROUTERTAB_STATISTIQUE',defaultMessage : "Statistiques"}), path: "/location_statistics" },
          ]}
        />

        <Switch>

          <Route exact path="/offers">
            <OffersHome />
          </Route>
          <Route exact path="/offers/create">
            <ArticleCreate collection="offers" />
          </Route>
          <Route path="/offers/:id">
            <ArticleEdit collection="offers" />
          </Route>

          <Route exact path="/services">
            <ServicesHome />
          </Route>
          <Route exact path="/services/create">
            <ArticleCreate collection="services" />
          </Route>
          <Route path="/services/:id">
            <ArticleEdit collection="services" />
          </Route>

          <Route exact path="/stories">
            <NewsHome />
          </Route>
          <Route exact path="/stories/create">
            <ArticleCreate collection="stories" />
          </Route>
          <Route path="/stories/:id">
            <ArticleEdit collection="stories" />
          </Route>

          <Route exact path="/locations/create">
            <LocationsCreate />
          </Route>
          <Route path="/locations/:id">
            <LocationsEdit />
          </Route>

          <Route
            exact
            path="/locations/:location_id/location_news/:id"
            render={() => {
              return <LocationNewsEdit />;
            }}
          ></Route>

          <Route exact path="/categories/create">
            <CategoryCreate />
          </Route>

          <Route path="/categories/:id">
            <CategoryEdit />
          </Route>

          <Route exact path="/locations">
            <LocationsTable />
          </Route>
          <Route exact path="/location_statistics">
            <LocationStats />
          </Route>

          <Route exact path="/categories">
            <div style={{ display: "flex", width: "80vw" }}>
              <CategoriesTable />
              <CategoryPhone />
            </div>
          </Route>

          <Route exact path="/statistiques">
            <StatisticsHome />
          </Route>
          {authentication.user && authentication.user.can_get_reportings && (
              <Route exact path="/reporting">
                <Reporting />
              </Route>
          )}
          {authentication.user && authentication.user.superadmin && (
            <>
              <RouterTabs
                tabs={[
                  { label: intl.formatMessage({id:'MAIN.ROUTERTAB_COMMERCIAUX',defaultMessage:"Commerciaux"}), path: "/salespeople" },
                  { label: intl.formatMessage({id:'MAIN.ROUTERTAB_ADMIN',defaultMessage :"Administrateurs"}), path: "/administrators" },
                  { label: intl.formatMessage({id:'MAIN.ROUTERTAB_PLANS',defaultMessage :"Plans"}), path: "/plans" },
                  { label: intl.formatMessage({id:'MAIN.ROUTERTAB_PARAMETRE',defaultMessage:"Paramètres"}), path: "/settings" },
                ]}
              />
              <Switch>
                <Route exact path="/plans">
                  <PlanTable />
                </Route>

                {/* <Route exact path="/plans/create">
                  <PlanCreate />
                </Route> */}

                <Route exact path="/plans/:id">
                  <PlanEdit />
                </Route>

                <Route exact path="/salespeople">
                  <SalespersonTable />
                </Route>

                <Route exact path="/salespeople/create">
                  <SalespersonCreate />
                </Route>

                <Route path="/salespeople/:id">
                  <SalespersonEdit />
                </Route>

                <Route exact path="/administrators">
                  <AdministratorTable />
                </Route>

                <Route exact path="/administrators/create">
                  <AdministratorCreate />
                </Route>

                <Route path="/administrators/:id">
                  <AdministratorEdit />
                </Route>

                <Route exact path="/settings">
                  <SettingsTable />
                </Route>

                <Route exact path="/settings/:id">
                  <SettingsEdit />
                </Route>
                {/* <Route exact path="/settings/email">
                  <SettingsEdit />
                </Route> */}
              </Switch>
            </>
          )}
        </Switch>
      </main>
    </>
  );
}
// Main.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default Main;
