import React, { useState, useEffect, ChangeEvent } from "react";
import {
  TextField,
  Typography,
  Button,
  Paper,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  useHistory,
  useLocation as useRouterLocation,
  useRouteMatch,
} from "react-router-dom";
import { v4 } from "uuid";
import { Category } from "../../types";
import { useUserContext } from "../../authentication";
import { useImage, uploadImage } from "../../firebase";
import { useMutation, useQueryClient } from "react-query";
import { updateOne, createOne } from "../../API";
import { isNotAuthorized } from "../../utils";
import { deletePOST } from "../../API";
import { FormattedMessage } from "react-intl";
import{useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(4),

    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    publishBtn: {
      width: "100%",
    },
    previewImage: {
      maxWidth: 300,
      maxHeight: 180,
      objectFit: "contain",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    buttonWrapper: {
      position: "relative",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    none: {
      display: "none",
    },
    image: {
      display: "flex",
      flexDirection: "column",
    },
    deleteBtn: {
      width: "100%",
      marginTop: theme.spacing(2),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    deleteBtnDialog: {
      marginRight: theme.spacing(3),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
  })
);

const CategoryForm = ({
  category,
  action,
}: {
  category?: Category;
  action: "edit" | "create";
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const authentication = useUserContext();
  // from the url one find the id
  const { pathname } = useRouterLocation();
  const findPathId = pathname.match(/([1-9][0-9]*)/);

  const intl=useIntl();

  // fetch the good category
  // const { data: category }: { data: any } = useCategories({
  //   authentication,
  //   categoryId: pathId ? pathId : "skip",
  // });
  // const query = action === "create" ? useQuery(["categories", fetchObject], () =>
  //   fetchOne("categories", fetchObject, authentication.accessToken)
  // ) :

  // value to create or update
  const [value, setValue] = useState<Category>(
    category
      ? category
      : {
          directory_home: false,
          id: "",
          image: "",
          name: "",
          position: 0,
        }
  );

  // pour que react-query ait le temps de recevoir l'info après une édition et un retour
  useEffect(() => {
    if (category) {
      setValue(category);
    }
  }, [category]);

  const handleValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue({ ...value, name: event.target.value });
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, directory_home: event.target.checked });
  };

  // mutate request
  const [trigger, setTrigger] = useState(false);

  // API calls

  // Pour l'instant on laisse ça ici (peut-être abstraire)
  const formatValue = (rawValue: any) => {
    const { id, location, editor, actions, rich_text, ...attributes } =
      rawValue;

    const formattedValue = {
      // type: "articles",
      attributes: { ...attributes },
      ...(id && { id }),
      // relationships: {
      //   ...(location && {
      //     location: { data: { type: "locations", id: location.id } },
      //   }),
      //   ...(editor && { editor: { data: { type: "users", id: editor.id } } }),
      // },
    };
    return formattedValue;
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: any) =>
      action === "edit"
        ? updateOne("categories", formatValue(data), authentication.accessToken)
        : createOne(
            "categories",
            formatValue(data),
            authentication.accessToken
          ),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        // successAlert();
        setTimeout(() => {
          history.goBack();
        }, 500);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("categories");
      },
    }
  );

  useEffect(() => {
    if (trigger)
      (async () => {
        try {
          const pop = await mutation.mutate(value);
          if (localImage) {
            uploadImage(localImage, value.image);
          }
          setTrigger(false);
          // history.push("/categories");
          history.goBack()
        } catch (error: any) {
          setTrigger(false);
          console.warn(error);
        }
      })();
  }, [trigger]);
  const handlePublish = () => {
    setTrigger(true);
  };

  const [localImage, setLocalImage] = useState<null | File>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imageName = `${value.name}-${v4()}`;
    setValue({ ...value, image: imageName });
    if (event.target.files) {
      setLocalImage(event.target.files[0]);
    }
  };

  const imageUrl = useImage(`${value.image}_360x360`);

  //////////////////////////////
  /////////////// DELETE ///////
  //////////////////////////////

  const [deleteOk, setDeleteOk] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleDelete = async () => {
    if (action === "edit") {
      try {
        const response = await deletePOST(
          "categories",
          authentication.accessToken,
          value.id
        );
        if (response) {
          setDeleteDialog(false);
          setDeleteOk(true);
          setTimeout(() => {
            setDeleteOk(false);
            history.goBack()
          }, 1000);
        }
      } catch (e: any) {
        return { error: e.message };
      }
    }
  };
  /////////////Fin delete //////////////
  //////////////////////////////////////

  return (
    <>
      <Paper className={classes.paper} elevation={1}>
        <form className={classes.form}>
          <Typography
            className={classes.sectionLabel}
            color="textSecondary"
            variant="h6"
          >
            Catégorie
          </Typography>

          <TextField
            className={classes.textField}
            helperText={
              <FormattedMessage
              id="CATEGORYFORM.HELPER_TEXT_TITRE_OBLIG"    
              defaultMessage="le titre est obligatoire"/>}
            value={value.name}
            onChange={(event) => handleValue(event)}
            label={
              <FormattedMessage
              id="CATEGORYFORM.HELPER_TEXT_NOM_CATEG"  
              defaultMessage="Nom de la catégorie"/>}
          />

          <div className={classes.sectionLabel}>
            <Typography color="textSecondary" variant="h6">
              <FormattedMessage
                id="CATEGORYFORM.MEDIA_TEXT"
                defaultMessage="Médias"/>
            </Typography>
            <Typography variant="caption">
              <FormattedMessage
              id="CATEGORYFORM.CATION_MEDIA"
              defaultMessage="(il ne peut y avoir qu'un média, la vidéo sera choisie en
              priorité)"/>
            </Typography>
          </div>

          <div className={classes.image}>
            <input
              accept="image/*"
              className={classes.none}
              id="contained-button-image"
              multiple
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="contained-button-image">
              <Button
                className={classes.textField}
                size="medium"
                variant="outlined"
                component="span"
              >
                <FormattedMessage
                id="CATEGORYFORM.BTN_AJOUT_FILE"
                defaultMessage="Ajouter une fichier"/>
              </Button>
            </label>
            {value.image !== "" && (
              <>
                <img
                  className={classes.previewImage}
                  src={localImage ? URL.createObjectURL(localImage) : imageUrl}
                  alt={value.image}
                />
              </>
            )}
          </div>

          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                checked={value.directory_home}
                onChange={(event) => handleCheckbox(event)}
                name="checkbox"
              />
            }
            label= {intl.formatMessage({id:'CATEGORYFORM.PRESENCE_CATEG_ACCUEIL',defaultMessage:"Présent sur la page d'accueil des catégories ?"})}
          />

          <div className={classes.buttonWrapper}>
            <Button
              // disabled={status === "loading"}
              variant="contained"
              color="secondary"
              className={classes.publishBtn}
              onClick={handlePublish}
            >
              {value.id === "" ? intl.formatMessage({id:'CATEGORYFORM_BTN_CREERCATEG',defaultMessage:"Créer la catégorie"})
               : intl.formatMessage({id:'CATEGORYFORM.BTN_MODIFCATEG',defaultMessage:"Modifier la catégorie"})}
            </Button>

            {action === "edit" && authentication?.user?.superadmin && (
              <>
                <Button
                  variant="outlined"
                  className={classes.deleteBtn}
                  onClick={() => {
                    setDeleteDialog(true);
                  }}
                >
                  <FormattedMessage
                  id="CATEGORYFORM.BTN_SUPPRCATEG"
                  defaultMessage="Supprimer cette catégorie"/>
                </Button>

                <Dialog
                  onClose={() => setDeleteDialog(false)}
                  aria-labelledby="simple-dialog-title"
                  open={deleteDialog}
                >
                  <DialogTitle id="simple-dialog-title">
                    <FormattedMessage
                    id="CATEGORYFORM.DIALOG_SUPPR_CATEG"
                    defaultMessage="Supprimer la catégorie ?"/>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <FormattedMessage
                      id="CATEGORYFORM.MSG_SUPPRESSION_CATEG"
                      defaultMessage="
                      Êtes-vous sûr de vouloir supprimer cette catégorie ? Cette
                      action est définitive."/>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setDeleteDialog(false)}
                      color="primary"
                      autoFocus
                    >
                      <FormattedMessage
                          id="CATEGORYFORM.BTN_ANNULER"
                          defaultMessage="Annuler"/>
                    </Button>
                    <Button
                      variant="outlined"
                      className={classes.deleteBtnDialog}
                      onClick={() => handleDelete()}
                    >
                      <FormattedMessage
                      id="CATEGORYFORM.BTN_SUPPR"
                      defaultMessage="Supprimer la catégorie"/>
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {/* {status === "loading" && (
              <CircularProgress
                color="secondary"
                size={24}
                className={classes.buttonProgress}
              />
            )} */}
          </div>
        </form>
      </Paper>
    </>
  );
};

export default CategoryForm;
