import React, { useState, useEffect, ChangeEvent } from "react";
import {
  TextField,
  Typography,
  Button,
  Paper,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  useHistory,
  useLocation as useRouterLocation,
  useRouteMatch,
} from "react-router-dom";
import { useUserContext } from "../../authentication";
import { useMutation, useQueryClient } from "react-query";
import { updateOne, createOne, deletePOST } from "../../API";
import { isNotAuthorized } from "../../utils";
import Alert from "@material-ui/lab/Alert";
import { AdminPreview } from "./AdministratorTable";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "70%",
      marginTop: theme.spacing(4),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    publishBtn: {
      width: "50%",
    },
    dialogBtn: {
      width: "100%",
    },

    previewImage: {
      maxWidth: 300,
      maxHeight: 180,
      objectFit: "contain",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    buttonWrapper: {
      position: "relative",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    none: {
      display: "none",
    },
    image: {
      display: "flex",
      flexDirection: "column",
    },
    deleteBtnDialog: {
      marginRight: theme.spacing(3),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      width: "100%",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    deleteBtn: {
      width: "100%",
      marginTop: theme.spacing(2),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    alertBtn:{
      width: "100%",
      marginTop: theme.spacing(2),
    }
  })
);

const AdministratorForm = ({
  admin,
  action,
}: {
  admin?: AdminPreview;
  action: "edit" | "create";
}) => {
  const intl=useIntl();

  const classes = useStyles();
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const authentication = useUserContext();
  const { pathname } = useRouterLocation();
  const findPathId = pathname.match(/([1-9][0-9]*)/);


  // value to create or update
  const [value, setValue] = useState<Partial<AdminPreview>>(
    admin
      ? admin
      : {
          email: "",
        }
  );

  // pour que react-query ait le temps de recevoir l'info après une édition et un retour
  useEffect(() => {
    if (admin) {
      setValue(admin);
    }
  }, [admin]);

  const handleValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  // mutate request
  // const [trigger, setTrigger] = useState(false);

  // API calls

  // Pour l'instant on laisse ça ici (peut-être abstraire)
  const formatValue = (rawValue: any) => {
    const { id, ...attributes } = rawValue;

    const formattedValue = {
      attributes: {
        ...attributes,
        ...(action === "create" && { admin: true }),
      },
      ...(id && { id }),
    };
    return formattedValue;
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: any) =>
      action === "edit"
        ? updateOne("users", formatValue(data), authentication.accessToken)
        : createOne("users", formatValue(data), authentication.accessToken),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        // successAlert();
        setTimeout(() => {
          history.goBack();
        }, 500);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("users");
      },
    }
  );

  const handlePublish = () => {
    mutation.mutate(value);
  };

  const [deleteSucess, setDeleteSucess] = useState(false);

  const handleDelete = async () => {
    try {
      const response = await deletePOST(
        "users",
        authentication.accessToken,
        value.id
      );
      if (response) {
        setDeleteSucess(true);
        setTimeout(() => {
          history.goBack();
        }, 2000);
      }
    } catch (e: any) {
      return { error: e?.message };
    }
  };
  const [deleteDialog, setDeleteDialog] = useState(false);

  return (
    <>
      <Paper className={classes.paper} elevation={1}>
        <form className={classes.form}>
          <Typography
            className={classes.sectionLabel}
            color="textSecondary"
            variant="h6"
          >
           <FormattedMessage id="ADMINISTRATORFORM.TEXT_ADMIN" defaultMessage="Administrateur"/>
          </Typography>

          <TextField
            className={classes.textField}
            helperText="Email"
            value={value.email}
            onChange={(event) => handleValue(event)}
            label="Email"
            name="email"
          />

          <TextField
            className={classes.textField}
            helperText="Mot de passe"
            value={value.password || ""}
            onChange={(event) => handleValue(event)}
            label={
                <FormattedMessage
                id="ADMINISTRATORFORM.TEXT_MDP"  
                defaultMessage="Mot de passe"/>}
            name="password"
          />

          <div className={classes.buttonWrapper}>
            {value.password && action !== "create" ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.dialogBtn}
                  onClick={() => {
                    setDeleteDialog(true);
                  }}
                >
                  <FormattedMessage id="ADMINISTRATORFORM.BTN_MODIFADMIN" defaultMessage="Modifier l'administrateur"/>
                </Button>

                <Dialog
                  onClose={() => setDeleteDialog(false)}
                  aria-labelledby="simple-dialog-title"
                  open={deleteDialog}
                >
                  <DialogTitle id="simple-dialog-title">
                    <FormattedMessage id="ADMINISTRATORFORM.DIALOGTITRE_MODIFADMIN" defaultMessage="Modifier l'administrateur ?"/>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <FormattedMessage
                      id="ADMINISTRATORFORM.DIALOG_TEXT_MDP"
                      defaultMessage="Un mot de passe est déjà enregistré pour cet utilisateur.
                      Vous etes sur le point de le changer. Voulez-vous
                      continuer?"/>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => history.goBack()}
                      color="primary"
                      autoFocus
                    >
                      <FormattedMessage
                      id="ADMINISTRATORFORM.BTN_ANNULER"
                      defaultMessage="Annuler"/>
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.publishBtn}
                      onClick={() => handlePublish()}
                    >
                      <FormattedMessage id="ADMINISTRATORFORM.BTN_MODIF_ADMIN" defaultMessage="Modifier l'administrateur"/>
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                className={classes.dialogBtn}
                onClick={() => handlePublish()}
              >
                 {action == "create"? intl.formatMessage({id:'ADMINISTRATORFORM.ACTION_CREER_ADMIN',defaultMessage:"Créer l'administrateur"}) : intl.formatMessage({id:'ADMINISTRATORFORM.ACTION_MODIF_ADMIN',defaultMessage:"Modifier l'administrateur"})  } 
              </Button>
            )}

            {action !== "create" && (
              <Button
                className={classes.deleteBtnDialog}
                variant={"outlined"}
                size="medium"
                color={"default"}
                onClick={() => handleDelete()}
              >
                <FormattedMessage
                id="ADMINISTRATORFORM.BTN_SUPPR_ADMIN"
                defaultMessage="Supprimer"/>
              </Button>
            )}
            {deleteSucess && (
              <Alert className={classes.alertBtn} severity="success">
                La suppression a été enregistrée
              </Alert>
            )}
          </div>
        </form>
      </Paper>
    </>
  );
};

export default AdministratorForm;
