import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Chip } from "@material-ui/core";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    online: {
      backgroundColor: "#68D391",
      color: "#F0FFF4",
    },
    draft: {
      backgroundColor: "#e6e6e3",
      color: "#3f3e39",
    },
    deleted: {
      backgroundColor: "#FC8181",
      color: "#FFF5F5",
    },
    programmed: {
      backgroundColor: "#ED64A6",
      color: "#FFF5F7",
    },
    expired: {
      backgroundColor: "#ED8936",
      color: "#FEEBC8",
    },
    banner: {
      minWidth: 80,
      color: "#E9D8FD",
      backgroundColor: "#805AD5",
    },
    tile: {
      minWidth: 80,
      backgroundColor: "#63B3ED",
      color: "#EBF8FF",
    },
    bigTile: {
      minWidth: 80,
      color: "#FEFCBF",
      backgroundColor: "#ECC94B",
    },
    card: {
      minWidth: 80,
      backgroundColor: "#ED64A6",
      color: "#FED7E2",
    },
    gold: {
      minWidth: 80,
      backgroundColor: "#D69E2E",
      color: "#FAF089",
    },
    silver: {
      minWidth: 80,
      backgroundColor: "#A0AEC0",
      color: "#EDF2F7",
    },
    bronze: {
      minWidth: 80,
      backgroundColor: "#DD6B20",
      color: "#FBD38D",
    },
  })
);

type Props = {
  value?: string;
  text?: string;
};

const ColoredTag = ({ value, text }: Props) => {
  const intl = useIntl();

  const classes = useStyles();

  const displayText = (value?: string) => {
    switch (value) {
      case "online":
        return intl.formatMessage({
          id: "COLOREDTAG.ENLIGNE",
          defaultMessage: " en ligne",
        });
      case "draft":
        return intl.formatMessage({
          id: "COLOREDTAG.BROUILLON",
          defaultMessage: "brouillon",
        });
      case "deleted":
        return intl.formatMessage({
          id: "COLOREDTAG.SUPPRIME",
          defaultMessage: "supprimé",
        });
      case "programmed":
        return intl.formatMessage({
          id: "COLOREDTAG.PROGRAMME",
          defaultMessage: "programmé",
        });
      case "expired":
        return intl.formatMessage({
          id: "COLOREDTAG.EXPIRE",
          defaultMessage: "expiré",
        });
      case "banner":
        return intl.formatMessage({
          id: "COLOREDTAG.BANNIERE",
          defaultMessage: "bannière",
        });
      case "tile":
        return intl.formatMessage({
          id: "COLOREDTAG.TUILE",
          defaultMessage: "tuile",
        });
      case "big_tile":
        return intl.formatMessage({
          id: "COLOREDTAG.TUILE_LARGE",
          defaultMessage: "tuile large",
        });
      case "card":
        return intl.formatMessage({
          id: "COLOREDTAG.CARTE",
          defaultMessage: "carte",
        });
      case "waiting_for_validation":
        return intl.formatMessage({
          id: "COLOREDTAG.WAITING",
          defaultMessage: "en attente de validation",
        });
      case "absent":
        // return intl.formatMessage({id:'COLOREDTAG.WAITING',defaultMessage:"absent"})
        return "absent";
      case "validated":
        return intl.formatMessage({id:'COLOREDTAG.VALIDATED',defaultMessage:"validé"})
      case "present":
          return intl.formatMessage({id:'COLOREDTAG.PRESENT',defaultMessage:"présent"})
      default:
        return value;
    }
  };

  return (
    <Chip
      classes={{
        root: clsx(
          {
            [classes.online]:
              value === "online" ||
              value === "validated" ||
              value === "present",
          },
          { [classes.silver]: value === "draft" },
          { [classes.expired]: value === "expired" || value === "absent" },
          {
            [classes.programmed]:
              value === "programmed" || value === "waiting_for_validation",
          },
          { [classes.deleted]: value === "deleted" },
          { [classes.banner]: value === "banner" },
          { [classes.tile]: value === "tile" },
          { [classes.bigTile]: value === "big_tile" },
          { [classes.card]: value === "card" },
          { [classes.gold]: value === "Gold" },
          { [classes.silver]: value === "Silver" },
          { [classes.bronze]: value === "Bronze" }
        ),
      }}
      label={displayText(text ? text : value)}
      size="small"
    />
  );
};

export default ColoredTag;
