import React, { useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SortingRule } from "react-table";
import ActionsIcon from "@material-ui/icons/MoreVert";
import { fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import PhonePreview from "../shared/PhonePreview";
import Table from "../Table";
import ColoredTag from "../shared/ColoredTag";
import moment from "moment";
import { getSortValue, isNotAuthorized } from "../../utils";
import { useQuery } from "react-query";
import { Article } from "../../types";
import TableActionBar from "../TableActionBar";
import AddIcon from "@material-ui/icons/Add";
import TableLink from "../Table/TableLink";
import TabPanel from "../shared/TabPanel";
import EventChart from "../shared/EventChart";
import { Location } from "../../types/location";
import { uniqBy } from "lodash";
import appConfig from "../../appConfig";

import{FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: "calc(100vw - 320px)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    spinner: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    link: {
      // fontWeight: 500,
      // cursor: "pointer",
    },
  })
);

function LocationsTable() {
  const intl = useIntl();

  const classes = useStyles();
  const authentication = useUserContext();
  // ROUTING
  const { path } = useRouteMatch();
  const { state } = useLocation<{
    sortBy: SortingRule<object>[];
    pageSize: number;
    pageIndex: number;
    searchWith: string;
  } | null>();
  // NAVIGATION STATE
  // Les éléments liés à la navigation sont tiré de l'état de location
  // Où ils sont directement définis par Table || ou par un Link / goBack() etc avec state
  const pageIndex = state?.pageIndex ? state.pageIndex : 0;
  const pageSize = state?.pageSize ? state.pageSize : 10;
  const sortBy = state?.sortBy ? state.sortBy : [];
  const searchWith = state?.searchWith ? state.searchWith : "";
  const [controlledSearchWith, setControlledSearchWith] = useState(searchWith);

  // COMPONENT STATE
  // État lié au composant (indépendant de la navigation)
  // const [selectionList, setSelectionList] = useState<string[]>([]);
  // const [isSelectionNegative, setIsSelectionNegative] = useState(false);

  const history = useHistory();

  // DATA
  // définition des headers
  const columns = [
    {
      accessor: "actions",
      Header: "",
      disableSortBy: true,
    },
    {
      accessor: "name",
      Header: intl.formatMessage({id:'LOCATIONSTABLE.HEADER_NAME',defaultMessage:"Nom"}),
      width: "100"
    },
    {
      accessor: "categories",
      Header: intl.formatMessage({id:'LOCATIONSTABLE.HEADER_CATEGORIE',defaultMessage:"Catégories"}),
      disableSortBy: true,
    },
    {
      accessor: "short_description",
      Header: intl.formatMessage({id:'LOCATIONSTABLE.HEADER_DESCRIP',defaultMessage:"Description"}),
    },
    {
      accessor: "validated",
      Header: intl.formatMessage({id:'LOCATIONSTABLE.HEADER_STATUT',defaultMessage:"Statut"}),
    },
    {
      accessor: "plan",
      Header: intl.formatMessage({id:'LOCATIONSTABLE.HEADER_FORFAIT',defaultMessage:"Forfait"}),
      sortValue: "plan.name",
    },
    {
      accessor: "owners",
      Header: intl.formatMessage({id:'LOCATIONSTABLE.HEADER_PROPRIO',defaultMessage:"Propriétaire"}),
      // sortValue: "owners[0].name",
      disableSortBy: true,
    },
    {
      accessor: "editor",
      Header: intl.formatMessage({id:'LOCATIONSTABLE.HEADER_CONTACT_MTN',defaultMessage:"Contact Admin"}),
      sortValue: "editor.name",
    },
    {
      accessor: "id",
      Header: "Id",
      // disableSortBy: true,
    },
  ];

  // REQUEST (à partir des états et de columns)
  const fetchObject = {
    include: ["products.payment", "editor", "categories", "owners", "plan"],
    fields: {
      users: ["name", "id", "email"],
      // categories: ["name"]
    },
    page: {
      number: pageIndex + 1,
      size: pageSize,
    },
    filters: {
      ...(searchWith && searchWith.length > 2 && { search: searchWith }),
    },
    ...(sortBy[0] && { sort: getSortValue(sortBy[0], columns) }),
  };

  interface LocationPreview extends Partial<Location> {
    id: string;
    name: string;
    categories: any[];
  }
  const { data, status, isPreviousData } = useQuery<{
    results: LocationPreview[];
    pageCount: number;
    total: number;
  }>(
    ["locations", fetchObject],
    () => fetchList("locations", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  const [actionsAnchorEl, setActionsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [actionsMenuItem, setActionsMenuItem] =
    useState<LocationPreview | null>(null);
  const getItemMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    article: LocationPreview
  ) => {
    setActionsMenuItem(article);
    setActionsAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setActionsAnchorEl(null);
    setActionsMenuItem(null);
  };

  const formatDate = (date: any) => {
    return moment(date).format("L");
  };

  const transformedData = React.useMemo(() => {
    if (data?.results) {
      const getStatus = (location: LocationPreview) => {
        const toBePayed = location?.products?.filter(
          (product) => product.workflow_state === "awaiting_payment"
        );

        // s'il y a des plans à payer
        if (
          location.workflow_state !== "awaiting_validation" &&
          toBePayed &&
          toBePayed.length > 0
        ) {
          // mais avant si ses plans ont des paiements à valider
          if (toBePayed[0].payment) {
            let payments;
            const uniqToBePayed = uniqBy(toBePayed, "payment.id");
            payments = uniqToBePayed.map((tbp) => {
              if (tbp.payment !== undefined) {
                return { ...tbp.payment };
              }
            });
            if (payments && payments.length > 0) {
              const payementstoBeValidated = payments?.find(
                (payment) => payment?.workflow_state === "awaiting_validation"
              );
              if (payementstoBeValidated)
                return (
                  <ColoredTag
                    value="tile"
                    text={intl.formatMessage({id:'LOCATIONSTABLE.TEXTE_PAY_ATTENTE_VALID',defaultMessage:"paiement en attente de validation"})}
                  />
                );
            }
          }

          return (
            <ColoredTag
              value="banner"
              text={intl.formatMessage({id:'LOCATIONSTABLE.TEXTE_SOUSCRIP_ATTENTE',defaultMessage:"souscription en attente de paiement"})}
            />
          );
        }

        if (location.workflow_state) {
          switch (location.workflow_state) {
            case "validated":
              return <ColoredTag value="validated" text={intl.formatMessage({id:'LOCATIONSTABLE.TEXTE_ETABLISS_VALID',defaultMessage:"établissement validé"})} />;
            case "awaiting_validation":
              return (
                <ColoredTag
                  value="programmed"
                  text={intl.formatMessage({id:'LOCATIONSTABLE.TEXTE_ETABLISS_ATTENTE_VALID',defaultMessage:"établissement en attente de validation"})}
                />
              );
            case "rejected":
              return <ColoredTag value="deleted" text={intl.formatMessage({id:'LOCATIONSTABLE.TEXTE_ETABLISS_REJECT',defaultMessage:"établissement rejeté"})} />;
            case null:
              return "";
            default:
              return (
                <ColoredTag value="draft" text={location.workflow_state} />
              );
          }
        }
      };

      return data.results.map((location: LocationPreview) => ({
        name: (
          <TableLink
            onClick={() => {
              history.push(`${path}/${location.id}`, { action: "edit" });
            }}
          >
            {location?.name ? location.name : intl.formatMessage({id:'LOCATIONTABLE.TABLINK_SANSNOM',defaultMessage:"sans nom"})}
          </TableLink>
        ),
        validated: getStatus(location),
        categories:
          `${location?.categories[0] ? location?.categories[0].name : intl.formatMessage({id:'LOCATIONSTABLE.NONE_CATEGORIE',defaultMessage:"aucune catégorie"})} ${location.categories?.length > 1
            ?
            ` (+ ${(location.categories.length - 1).toString()} ${location.categories.length - 1 === 1
              ? intl.formatMessage({id:'LOCATIONSTABLE.AUTRE',defaultMessage:"autre"})
              : intl.formatMessage({id:'LOCATIONSTABLE.AUTRES',defaultMessage:"autres"})
            })`
            : ""
        }`,
        short_description: <i>{location.short_description}</i>,
        plan: location?.plan?.name ? <ColoredTag value={location?.plan?.name} /> : "",
        owners: (location?.owners && location.owners[0]) ? <div>{location.owners[0]?.name ? location.owners[0]?.name : intl.formatMessage({id:'LOCATIONSTABLE.NONAME',defaultMessage:"pas de nom"})} <br/> {location.owners[0]?.email ? location.owners[0]?.email : intl.formatMessage({id:'LOCATIONTABLE.NO_MAIL',defaultMessage:"pas d'email"})}</div> : intl.formatMessage({id:'LOCATIONSTABLE.NONEPROPRIO',defaultMessage:"aucun propriétaire"}),
        editor: location?.editor?.name,
        id: location.id,
        actions: (
          <IconButton
            onClick={(e) => getItemMenu(e, location)}
            size="small"
            color="primary"
          >
            <ActionsIcon />
          </IconButton>
        ),
      }));
    } else {
      return [];
    }
  }, [data]);
  // pagination
  const pageCount =
    status === "success" && data?.pageCount ? data.pageCount : 0;
  const totalElements = status === "success" ? data?.total : undefined;

  const actionBar = (
    <TableActionBar
      title={appConfig.sections.directory}
      setValue={(p) => setControlledSearchWith(p)}
    >
      {/* Bouton retiré  car c'est pas le flow souhaité*/}
      {/* <Button
        variant="contained"
        color="secondary"
        endIcon={<AddIcon />}
        onClick={() => {
          history.push(`${path}/create`, {
            action: "create",
          });
        }}
      >
        Créer
      </Button> */}
    </TableActionBar>
  );

  // Tab navigation
  const [tab, setTab] = React.useState(0);

  return (
    <>
      <div className={classes.container}>
        <Menu
          id="basic-menu"
          anchorEl={actionsAnchorEl}
          open={Boolean(actionsAnchorEl)}
          onClose={closeMenu}
          MenuListProps={{
            dense: true,
          }}
        >
          {actionsMenuItem?.firebase_id && (
            <MenuItem
              onClick={() => {
                actionsMenuItem?.firebase_id &&
                  navigator.clipboard.writeText(
                    `${appConfig.deeplinkPrefix}locations/${actionsMenuItem.firebase_id}`
                  );
              }}
            >
              <FormattedMessage id="LOCATIONSTABLE.MENUITEM_COPIEDEEPLINK" defaultMessage="Copier le deeplink"/>
            </MenuItem>
          )}
        </Menu>
        <Table
          data={transformedData}
          columns={columns}
          initialPageIndex={pageIndex}
          initialPageSize={pageSize}
          initialSortBy={sortBy}
          pageCount={pageCount}
          totalElements={totalElements}
          actionBar={actionBar}
          searchWith={controlledSearchWith}
          isFetching={isPreviousData}
        />
      </div>
    </>
  );
}

export default LocationsTable;
