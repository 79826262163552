import { Action } from "./action";
import { User } from "./user";
import { ArticleCollection } from "./collection";
import { Salesperson } from "./salesperson";

export interface Article {
  ios_only?: boolean;
  android_only?: boolean;
  plan: string | null;
  id: string;
  title: string;
  headline: string | null;
  text: string;
  rich_text: any;
  image: string;
  media: string | null;
  position: number | null;
  actions: Array<Action>;
  status: "draft" | "validated" | "deleted";
  created_at?: any;
  displayed_status: string;
  firebase_collection: ArticleCollection;
  firebase_id?: string;
  format: "card" | "big_tile" | "tile" | "banner" | "";
  date_start: any;
  date_end: any;
  editor: Partial<User> | null;
  // location_id?: string | number | null;
  location: Partial<Location> | null;
  salesperson?:Salesperson | null
}

export interface ArticleDisplayPreview extends Partial<Article> {
  image: string;
  position: number;
  id: string;
  displayed_status: string;
  format: "card" | "big_tile" | "tile" | "banner" | "";
  title: string;
  headline: string | null;
}

export interface ArticleDataPreview extends Partial<Article> {}

export const isArticle = (tbd: any): tbd is Article => {
  return tbd.firebase_collection &&
    (tbd.firebase_collection === "Offers" || "Stories" || "Services")
    ? true
    : false;
};
