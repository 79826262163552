import React, { ChangeEvent, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { createOne, fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import { Notification, Collection } from "../../types";
import NotificationsHistory from "./NotificationsHistory";
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { isNotAuthorized } from "../../utils";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionLabel: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
    dialogActions: {
      marginBottom: theme.spacing(1),
    },
    dialogDeleteAction: {
      marginRight: theme.spacing(2),
    },
    notifPreview: {
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    loader: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
  })
);

type Props = {
  firebase_id?: string;
  firebase_collection?: Collection;
  online: boolean;
};

const queryCache = new QueryClient();

const Notifications = ({ firebase_id, firebase_collection, online }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const intl = useIntl();

  const authentication = useUserContext();

  const [notification, setNotification] = useState<Notification>({
    title: "",
    text: "",
    firebase_id: firebase_id ? firebase_id : "",
    firebase_collection: firebase_collection ? firebase_collection : "Offers",
    created_at: "",
  });

  const [checkFields, setCheckFields] = useState({
    title: false,
    text: false,
  });

  const handleValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    switch (field) {
      case "notifTitle":
        setCheckFields({ ...checkFields, title: false });
        setNotification({ ...notification, title: event.target.value });
        break;
      case "notifText":
        setCheckFields({ ...checkFields, text: false });
        setNotification({ ...notification, text: event.target.value });
        break;
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    switch (true) {
      case notification.title === "" && notification.text === "":
        setCheckFields({ title: true, text: true });
        break;
      case notification.title === "":
        setCheckFields({ ...checkFields, title: true });
        break;
      case notification.text === "":
        setCheckFields({ ...checkFields, text: true });
        break;
      default:
        break;
    }
    if (notification.title !== "" && notification.text !== "") {
      setOpen(!open);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isLoading, setIsLoading] = useState<string>("no");


  // REQUEST (à partir des états et de columns)
  const fetchObject = {
    // include: ["editor"],
    fields: {
      notifications: ["title", "text", "id", "created_at"],
    },

    filters: { firebase_id: notification.firebase_id },
    // sort: "",
  };

  const { data, status } = useQuery<{
    results: Notification[];
  }>(
    ["notifications", fetchObject],
    () => fetchList("notifications", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e:any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  const queryClient = useQueryClient();




  const mutation = useMutation(
    (data: any) =>
      createOne("notifications", {attributes: data}, authentication.accessToken),
    {
      onError: (e:any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        // successAlert();
        setTimeout(() => {
          handleClose();
        }, 500);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries(["notifications", fetchObject]);
      },
    }
  );



  const sendNotification = async () => {
    setIsLoading("loading");
    try {
      mutation.mutate(notification)
      setIsLoading("validate");

    } catch (error: any) {
      console.warn(error);
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <form className={classes.form}>
          <Typography
            className={classes.sectionLabel}
            color="textSecondary"
            variant="h6"
          >
            Notifications
          </Typography>
          {online ? (
            <>
              <TextField
                helperText={checkFields.title && intl.formatMessage({id:'NOTIFICATIONS.MSG_TITRE_OBLIG',defaultMessage:"le titre est obligatoire"})}
                error={checkFields.title}
                required
                className={classes.textField}
                onChange={(event) => handleValue(event, "notifTitle")}
                label={intl.formatMessage({id:'NOTIFICATIONS.LABEL_TEXTCHAMP_TITRE',defaultMessage: "Titre"})}
              />
              <TextField
                helperText={checkFields.text && intl.formatMessage({id:'NOTIFICATIONS.MSG_TEXT_OBLIG',defaultMessage:"le texte est obligatoire"})}
                error={checkFields.text}
                required
                className={classes.textField}
                onChange={(event) => handleValue(event, "notifText")}
                label={intl.formatMessage({id:'NOTIFICATIONS.TEXT_CHAMP_TEXTE',defaultMessage:"Texte"})}
              />
              <div className={classes.button}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpen}
                >
                  <FormattedMessage
                  id="NOTIFICATIONS.BTN_VALID"
                  defaultMessage="valider"/>
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography>
                <FormattedMessage
                id="NOTIFICATIONS.MSG_PUBLICATION_ARTICLE"
                defaultMessage="L'article doit être publié pour pouvoir faire des notifications"/>
              </Typography>
            </>
          )}
        </form>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              <FormattedMessage
              id="NOTIFICATIONS.ALERTE_VALID_NOTIFICATION"
              defaultMessage="Valider la notification ?"/>}
          </DialogTitle>
          <DialogContent>
            {isLoading === "no" && (
              <>
                <DialogContentText id="alert-dialog-description">
                  <FormattedMessage 
                    id="NOTIFICATIONS.DIALOGTEXT_NOTIFENSUTILISAT"
                    defaultMessage="Vous allez envoyer cette notification à l'ensemble utilisateurs."/>
                  {/* <br />
            C'est la Xème notification de la semaine
            <br />
            Cet article n'a pas déjà été notifié */}
                </DialogContentText>
                <Paper className={classes.notifPreview} elevation={3}>
                  <Typography variant="h6">{notification.title}</Typography>
                  <Typography>{notification.text}</Typography>
                </Paper>
              </>
            )}
            {isLoading === "loading" && (
              <>
                <DialogContentText id="alert-dialog-description">
                  <FormattedMessage id="NOTIFICATIONS.DIALOGTEXT_ENVOIECOURS" defaultMessage="Envoie en cours"/>
                </DialogContentText>
                <div className={classes.loader}>
                  <CircularProgress color="secondary" />
                </div>
              </>
            )}
            {isLoading === "validate" && (
              <>
                <DialogContentText id="alert-dialog-description">
                  <FormattedMessage id="NOTIFICATIONS.DIALOGTEXT_NOTIF_SENDED" defaultMessage="Notification envoyée"/>{" "}
                  <span aria-label="ok-emji" role="img">
                    👍
                  </span>
                </DialogContentText>
              </>
            )}
          </DialogContent>
          {isLoading === "no" && (
            <DialogActions className={classes.dialogActions}>
              <Button
                className={classes.dialogDeleteAction}
                onClick={handleClose}
                color="primary"
              >
                <FormattedMessage
                id="NOTIFICATIONS.BTN_ANNULER"
                defaultMessage="annuler"/>
              </Button>
              <Button
                onClick={sendNotification}
                variant="contained"
                color="secondary"
                autoFocus
              >
                <FormattedMessage
                id="NOTIFICATIONS.BTN_VALID_OK"
                defaultMessage="valider"/>
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </Paper>

      {online && data?.results?.length !== 0 && (
        <NotificationsHistory notifications={data?.results || []} />
      )}
    </>
  );
};
// Notifications.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default Notifications;
