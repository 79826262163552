import React, { ChangeEvent, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useUserContext } from "../../authentication";
import { useHistory } from "react-router-dom";
import { isNotAuthorized } from "../../utils";
import { useQuery } from "react-query";
import { fetchList } from "../../API";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    marginB: {
      marginBottom: theme.spacing(4),
    },
    spinner: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    largeDivider: {
      width: "80vw",
    },
    paper: {
      width: "100%",
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    textField: {
      marginTop: theme.spacing(1),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    publishBtn: {
      marginTop: theme.spacing(4),
      width: "50%",
    },
    tableau: {
      marginTop: theme.spacing(6),
    },
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    secondTitle: {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    alert: {
      marginTop: theme.spacing(2),
      width: "50%",
    },
    alertEdit: {
      width: "100%",
    },
    cardStyle: {
      width: "50%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),

      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
    },
    info: {
      marginTop: theme.spacing(2),
    },
    editBtn: {
      marginBottom: theme.spacing(1),
      width: "50%",
    },
  })
);

function SettingsTable() {
  const classes = useStyles();
  const authentication = useUserContext();
  const history = useHistory();

  const fetchObject = {};

  const { data } = useQuery<{
    results: any[];
  }>(
    ["app_configurations", fetchObject],
    () =>
      fetchList("app_configurations", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  const momopay = data?.results.find(
    (element) => element.key_name == "momocode"
  );
  const email = data?.results.find(
    (element) => element.key_name == "email_admins"
  );

  const [value, setValue] = useState(momopay?.value);

  const handleValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(event.target.value);
  };

  return (
    <>
      <div className={classes.paper}>
        {/* <Typography className={classes.title} variant="h4">
          Paramètres
        </Typography> */}

        {data?.results.map((settin) => {
          return (
            <Card className={classes.cardStyle}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {settin.key_name}
                </Typography>

                <Typography className={classes.info}>
                  {settin?.value}
                </Typography>

              </CardContent>
              <CardActions>
                <Button
                  className={classes.editBtn}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    history.push(`settings/${settin.id}`);
                  }}
                >
                  <FormattedMessage id="SETTINGSTABLE.BTN_MODIF" defaultMessage="Modifier"/>
                </Button>
              </CardActions>
            </Card>
          );
        })}



        {/* <Card className={classes.cardStyle}>
          <CardContent>
            <Typography variant="h5" component="div">
              Code Momopay
            </Typography>

            <TextField
              className={classes.textField}
     
              value={value}
              onChange={(event) => handleValue(event)}
       
              name="momocode"
            />

          </CardContent>
          <CardActions>
            <Button
              className={classes.editBtn}
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(`settings/${data?.results[0].id}`);
                // history.push(`settings/${momopay?.key_name}`, {});
              }}
            >
              Modifier
            </Button>
          </CardActions>
        </Card>

        <Card className={classes.cardStyle}>
          <CardContent>
            <Typography variant="h5" component="div">
              Email
            </Typography>
            <Typography className={classes.info}>{email?.value}</Typography>
          </CardContent>
          <CardActions>
            <Button
              className={classes.editBtn}
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(`settings/${momopay?.key_name}`, {});
              }}
            >
              Modifier
            </Button>
          </CardActions>
        </Card> */}
      </div>
    </>
  );
}

export default SettingsTable;
