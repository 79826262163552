import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import { Typography, Tabs, Tab, Toolbar } from "@material-ui/core";
import SalespersonForm from "../Salesperson/SalespersonForm";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import TabPanel from "../shared/TabPanel";
import { useQuery } from "react-query";
import Loader from "../shared/Loader";
import { Salesperson } from "../../types";
import { FormattedMessage,useIntl } from "react-intl";

require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    paper: {
      width: "70%",
      padding: theme.spacing(3),
    },
    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    tab: {
      color: theme.palette.tab.main
    }
  })
);

const SalespersonEdit = () => {
  const intl=useIntl();
  const authentication = useUserContext();
  const classes = useStyles();


  // ROUTING
  const { params } = useRouteMatch<{ id: string }>();
  // DATA QUERY
  const fetchObject = {
    id: params.id,
  };

  const query = useQuery<{
    result: Salesperson;
  }>(["salespeople", fetchObject], () =>
    fetchOne("salespeople", fetchObject, authentication.accessToken)
  );

  const { status } = query;
  const salesperson = query?.data?.result;

  const [tab, setTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  return (
    <div className={classes.tab}>
      <Toolbar />
      {salesperson && (
        <Typography className={classes.title} variant="h4">
          {salesperson.first_name} {salesperson.last_name}
        </Typography>
      )}

      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {salesperson && <Tab label={intl.formatMessage({id:"SALESPERSON.TABLAB_EDITION", defaultMessage:"Édition"})} />}
        {/* {salesperson && salesperson.firebase_id && <Tab label="Statistiques" />} */}
      </Tabs>
      <TabPanel value={tab} index={0}>
        {status === "loading" && <Loader />}
        {status === "error" && (
          <Typography>
            <FormattedMessage
            id="SALESPERSONEDIT.MSG_NO_EXIST_COM"
            defaultMessage="Ce commercial n'existe pas"/>
          </Typography>
        )}
        {status === "success" && (
          <SalespersonForm salesperson={salesperson} action={"edit"} />
        )}
      </TabPanel>
    </div>
  );
};
// SalespersonEdit.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default SalespersonEdit;
