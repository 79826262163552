import {
  Button,
  CircularProgress,
  Paper,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import appConfig from "../../appConfig";
import { useUserContext } from "../../authentication";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(3),
    },
    element: {
      padding: theme.spacing(3),
    },
    loader: {
      marginRight: theme.spacing(2),
    },
  })
);

function Reporting() {
  const classes = useStyles();

  return (
    <>
      <Toolbar />
      <Paper className={classes.paper}>
        <div className={classes.element}>
          <Typography variant="h6">{appConfig.sections.offers}</Typography>
          <CSVDownloader
            endpoint="reportings/offers_xlsx.xlsx"
            fileName={appConfig.sections.offers}
          />
        </div>
        <div className={classes.element}>
          <Typography variant="h6">
            <FormattedMessage
              id="INDEX.NAME_ETABLISS"
              defaultMessage="Établissements"
            />
          </Typography>
          <CSVDownloader
            endpoint="reportings/products_xlsx.xlsx"
            fileName={appConfig.sections.directory}
          />
        </div>
      </Paper>
    </>
  );
}

function CSVDownloader({
  endpoint,
  fileName,
}: {
  endpoint: string;
  fileName: string;
}) {
  const classes = useStyles();
  const authentication = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [dateStart, setDateStart] = useState("2020-01-01");
  const today = moment().format("L");
  const prettyDate = moment(dateStart).format("L");

  const downloadCSV = async (endpoint: string, fileName: string) => {
    if (authentication.accessToken) {
      setIsLoading(true);
      fetch(endpoint, {
        headers: {
          Authorization: authentication.accessToken,
          contentType: "application/vnd.ms-excel",
        },
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${fileName}-${prettyDate}-${today}.xlsx`
          );
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link?.parentNode?.removeChild(link);
          setIsLoading(false);
        });
    }
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Button
        onClick={() =>
          downloadCSV(
            `${appConfig.API}/${endpoint}?date_start=${dateStart}`,
            fileName
          )
        }
        color="secondary"
        variant="contained"
        disabled={isLoading && fileName === "bons plans"}
      >
        {isLoading && <CircularProgress className={classes.loader} size={20} />}
        <FormattedMessage
          id="INDEX.BTN_TELECHARGER_FILE"
          defaultMessage="Télécharger le fichier"
        />
      </Button>
      <TextField
        id="offers_date_start"
        label={
          <FormattedMessage
            id="DATE_PICKER.DEBUT"
            defaultMessage="Date de début"
          />
        }
        type="date"
        defaultValue="2020-01-01"
        onChange={(e) => setDateStart(e.target.value)}
        style={{ marginLeft: 20 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}

export default Reporting;
