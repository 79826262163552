import React, { useEffect, useState } from "react";
import { Line } from "@reactchartjs/react-chart.js";

const LineChart = ({
  data,
  legend = true,
}: {
  legend?: boolean;
  data: any;
}) => {
  const options = {
    legend: { display: legend },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <div>
        <Line type={Line} data={data} options={options} />
      </div>
    </>
  );
};

export default LineChart;
