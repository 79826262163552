export function jsUcfirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getSortValue = (sortBy:any, columns:any) => {
  if (sortBy) {
    const sorterCol = columns.find((c:any) => c.accessor === sortBy.id) || columns.find((c:any) => c.sortValue === sortBy.id)
    const sorterAttribute = sorterCol.sortValue ? sorterCol.sortValue : sorterCol.accessor
    return  `${sortBy.desc ? "" : "-" }${sorterAttribute}`
  }
}


function isError(error: unknown): error is Error {
  return error instanceof Error;
}
export type ServerError = {status: number, text: string, statusMessage: string}

export const isNotAuthorized = (error: ServerError) => {
  if (error.status === 403 || error.statusMessage === "Forbidden" || error.statusMessage === "Unauthorized") {
    return true
  } else {
    return false
  }
}
