import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Typography,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import moment from "moment";
import { validateOne } from "../../API";
import { useUserContext } from "../../authentication";
import { Location, LocationPlanI } from "../../types";
import Alert from "@material-ui/lab/Alert";
import { PaymentI, ProductI } from "../../types/location";
import { uniqBy } from "lodash";
import RejectModal from "../RejectModal";
import { Salesperson } from "../../types/salesperson";
import { fetchList } from "../../API";
import { isNotAuthorized } from "../../utils";
import { updateOne } from "../../API";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useConfigurationContext } from "../../configurationContext";
import { useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import appConfig from "../../appConfig";
import {useIntl} from "react-intl";

require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionLabel: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
    date: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    datePicker: {
      width: "45%",
    },
    plan: {
      marginTop: theme.spacing(2),
    },
    oldPlanTypo: {
      marginBottom: theme.spacing(1),
    },
    oldPlanContainer: {
      marginBottom: theme.spacing(2),
    },
    validationMessage: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    alert: {
      marginTop: theme.spacing(2),
    },
    bold: {
      fontWeight: "bold",
    },
    deleteBtn: {
      // marginRight: theme.spacing(3),
      // marginLeft: theme.spacing(3),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
    buttons: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    btn: {
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
    },
    spaceY2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    noProdAlert: {
      maxWidth: 300,
    },
    checkbox: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    space: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    associateBtn: {
      width: "50%",
      marginTop: theme.spacing(2),
    },
  })
);

const today = moment().format();

const ProductInfos = ({ product }: { product: ProductI }) => {
  moment.locale(appConfig.locale);
  const intl = useIntl();
  const classes = useStyles();
  const active = product.workflow_state === "active";
  const authentication = useUserContext();
  const [salesperson, setSalesperson] = useState<string | string>(
    product.salesperson?.id || "none"
  );
  const queryClient = useQueryClient();

  const handleSalesperson = (event: any) => {
    setSalesperson(event.target.value as string);
  };

  const salespersonFetchObject = {
    fields: { salespeople: ["first_name", "last_name", "id"] },
    sort: "last_name",
  };
  const { data: salespersonData } = useQuery<{
    results: Salesperson[];
  }>(
    ["salespeople", salespersonFetchObject],
    () =>
      fetchList(
        "salespeople",
        salespersonFetchObject,
        authentication.accessToken
      ),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  // const id=product
  const formatValue = (rawValue: any) => {
    const formattedValue = {
      type: "products",
      id: product.id,
      attributes: {},
      relationships: {
        ...(salesperson && {
          salesperson: {
            data:
              salesperson === "none"
                ? null
                : { type: "salespeople", id: salesperson },
          },
        }),
      },
    };
    return formattedValue;
  };

  const [isValidate, setIsValidate] = useState(false);
  const mutation = useMutation(
    (data: any) =>
      updateOne("products", formatValue(data), authentication.accessToken),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        setIsValidate(true);

        // successAlert();
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("products");
      },
    }
  );

  const handleValidate = () => {
    mutation.mutate({ salesperson_id: salesperson, id: product.id });
  };

  return (
    <div>
      <Typography
        color="textSecondary"
        className={active ? classes.sectionLabel : classes.spaceY2}
        variant="h6"
      >
        {product.relation_type === "add_sponso"
          ? intl.formatMessage({
              id: "LOCATIONPLAN.SPONSORISATION",
              defaultMessage: "Sponsorisation",
            })
          : intl.formatMessage({
              id: "LOCATIONPLAN.PLAN",
              defaultMessage: "Plan",
            })}
      </Typography>

      {product.relation_type !== "add_sponso" && (
        <Typography>Plan {product.relation_value}</Typography>
      )}
      {product.duration_in_months && (
        <Typography>
          <FormattedMessage id="LOCATIONPLAN.DUREE" defaultMessage="Durée:" />{" "}
          {product.duration_in_months}{" "}
          <FormattedMessage id="LOCATIONPLAN.MOIS" defaultMessage="mois" />
        </Typography>
      )}
      {active ? (
        <>
          <Typography>
            <FormattedMessage
              id="LOCATIONPLAN.DEBUT_1"
              defaultMessage="Début: "
            />{" "}
            {moment(product.date_start).format("DD MMMM YYYY")}
          </Typography>
          <Typography>
            <FormattedMessage id="LOCATIONPLAN.FIN_1" defaultMessage="Fin: " />{" "}
            {moment(product.date_end).format("DD MMMM YYYY")}
          </Typography>
          <Typography>
            <FormattedMessage id="LOCATIONPLAN.PAYMENT_METHOD" defaultMessage="Payé via: " />{" "}
            {product.payment?.payment_method}
          </Typography>
        </>
      ) : (
        <>
          <Typography>
            <FormattedMessage
              id="LOCATIONPLAN.DEBUT_2"
              defaultMessage="Début: "
            />
            {moment(product.date_start).format("DD MMMM YYYY")}
          </Typography>

          <Typography>
            <FormattedMessage id="LOCATIONPLAN.FIN_2" defaultMessage="Fin: " />
            {moment(product.date_end).format("DD MMMM YYYY")}
          </Typography>
          <Typography>
            <FormattedMessage id="LOCATIONPLAN.PAYMENT_METHOD" defaultMessage="Payé via: " />{" "}
            {product.payment?.payment_method}
          </Typography>
          {product.salesperson?.first_name &&
            product.salesperson?.last_name && (
              <Typography>
                <FormattedMessage
                  id="LOCATIONPLAN.TEXTE_COMMERCIAL"
                  defaultMessage="Commercial: "
                />{" "}
                {product.salesperson?.first_name}{" "}
                {product.salesperson?.last_name}
              </Typography>
            )}
        </>
      )}

      {active && (
        <>
          <div className={classes.space}>
            <InputLabel id="commercial-select-label">
              <FormattedMessage
                id="LOCATIONPLAN.INPUTLABEL_COMMERCIAL"
                defaultMessage="Commercial"
              />
            </InputLabel>

            <Select
              labelId="commercial-select-label"
              id="commercial-simple-select"
              value={salesperson}
              label={intl.formatMessage({
                id: "LOCATIONPLAN.LABEL_COMMERCIAL",
                defaultMessage: "Commercial",
              })}
              onChange={handleSalesperson}
            >
              <MenuItem value={"none"} key="none">
                <FormattedMessage
                  id="LOCATIONPLAN.MENU_ITEM_AUCUN"
                  defaultMessage="Aucun"
                />
              </MenuItem>
              {salespersonData?.results.map((x) => (
                <MenuItem value={x.id} key={x.id}>
                  {x.last_name} {x.first_name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <Button
            variant="contained"
            color="secondary"
            className={classes.associateBtn}
            onClick={handleValidate}
          >
            <FormattedMessage
              id="LOCATIONPLAN.BTN_ASSOCCOMMERCIAL"
              defaultMessage="Associer le commercial"
            />
          </Button>
          {isValidate && (
            <Alert className={classes.alert} severity="success">
              <FormattedMessage
                id="LOCATIONPLAN.ALERTE_COMM_ASSO"
                defaultMessage="Le commercial est associé"
              />
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

type Props = {
  location?: Location;
  //locations: Location[] | null
};

const LocationPlan = ({
  location,
}: //locations
Props) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { params } = useRouteMatch<{ id: string }>();

  const classes = useStyles();
  const authentication = useUserContext();
  const configuration = useConfigurationContext();

  // On filtre les produits payés
  const activeProducts = location?.products?.filter(
    (product) => product.workflow_state === "active"
  );

  // On filtre les produits qui attendent un paiement
  const toBePayed = location?.products?.filter(
    (product) => product.workflow_state === "awaiting_payment"
  );

  // On vérifie qu'il n'y a qu'un seul paiement
  const [payments, setPayments] = useState<any>();
  useEffect(() => {
    if (toBePayed && toBePayed.length !== 0 && toBePayed[0].payment) {
      const uniqToBePayed = uniqBy(toBePayed, "payment.id");
      const uniqPayments = uniqToBePayed.map((tbp) => {
        if (tbp.payment !== undefined) {
          return { ...tbp.payment };
        }
      });
      setPayments(uniqPayments);
    }
  }, []);

  // Fonction pour valider le paiement
  const [isValid, setIsValid] = useState(false);
  const handleValidate = async (id: string) => {
    const response = await validateOne(
      "payments",
      id,
      "validate",
      authentication.accessToken
    );
    if (response?.data) {
      setIsValid(true);
    }
    queryClient.invalidateQueries([
      "locations",
      {
        id: params.id,
        include: [
          "products",
          "salespeople",
          "products.payment",
          "products.salesperson",
          "owners",
          "categories",
        ],
      },
    ]);
  };

  // Alert paiment refusé
  const [isRejected, setIsRejected] = useState(false);

  // On trie les les établissements payés
  const futurProducts = location?.products
    ?.filter((product) => product.workflow_state === "paied")
    .filter((pp) => {
      if (
        moment(pp.date_start ? Date.parse(pp.date_start) : "").isAfter(today)
      ) {
        return pp;
      }
    });

  // On trie les les établissements inactif
  const inactiveProducts = location?.products?.filter(
    (product) => product.workflow_state === "inactive"
  );

  const getActionType = (actionType: string) => {
    switch (actionType) {
      case "add_sponso":
        return intl.formatMessage({
          id: "LOCATIONPLAN.SPONSORISATION",
          defaultMessage: "Sponsorisation",
        });
      case "renew_plan":
        return intl.formatMessage({
          id: "LOCATIONPLAN.RENOUVELLEMENT",
          defaultMessage: "Renouvellement",
        });
      case "upgrade_plan":
        return intl.formatMessage({
          id: "LOCATIONPLAN.MISE_A_NIVEAU",
          defaultMessage: "Mise à niveau",
        });
      case "first_plan":
        return intl.formatMessage({
          id: "LOCATIONPLAN.PREMIERE_SOUSCRIPTION",
          defaultMessage: "Première souscription",
        });
    }
  };

  ///////////////// SalesPerson

  const [isChecked, setIsChecked] = useState(false);
  const [isSaved, setIsSaved] = useState<"saved" | "not saved" | "">();

  const handleCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const formatValue = (rawValue: any) => {
    const { id, location, editor, actions, rich_text, ...attributes } =
      rawValue;

    const formattedValue = {
      type: "locations",
      attributes: { ...attributes },
      ...(id && { id }),
    };
    return formattedValue;
  };

  const mutation = useMutation(
    (data: any) =>
      updateOne("locations", formatValue(data), authentication.accessToken),
    {
      onError: (e: any) => {
        setIsSaved("not saved");
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        setIsSaved("saved");
        setTimeout(() => {
          setIsSaved("");
        }, 3000);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("categories");
      },
    }
  );

  return (
    <>
      {!activeProducts ||
        activeProducts.length === 0 ||
        !toBePayed ||
        toBePayed.length === 0 ||
        !location?.products ||
        (location?.products.length === 0 && (
          <Alert severity="info" className={classes.noProdAlert}>
            <FormattedMessage
              id="LOCATIONPLAN.ALERTE_NONE_ACTIVPLAN_1"
              defaultMessage="L'établissement n'a pas de plan actif"
            />
          </Alert>
        ))}
      {!location?.products ||
        (location?.products?.length === 0 && (
          <Alert severity="info" className={classes.noProdAlert}>
            <FormattedMessage
              id="LOCATIONPLAN.ALERTE_NONE_ACTIVPLAN_2"
              defaultMessage="L'établissement n'a pas de plan actif"
            />
          </Alert>
        ))}

      {activeProducts && activeProducts.length > 0 && (
        <Paper className={classes.paper}>
          <div className={classes.form}>
            <Typography className={classes.sectionLabel} variant="h5">
              <FormattedMessage
                id="LOCATIONPLAN.CURRENT_OFFER"
                defaultMessage="Offre en cours"
              />
            </Typography>
            {activeProducts.map((activeP) => (
              <ProductInfos product={activeP} />
            ))}
          </div>
        </Paper>
      )}

      {toBePayed?.length !== 0 && (
        <Paper className={classes.paper}>
          <div className={classes.form}>
            <Typography className={classes.sectionLabel} variant="h5">
              <FormattedMessage
                id="LOCATIONPLAN.TEXTE_SOUSCRIP_PAYEMENT"
                defaultMessage="Souscription et paiement"
              />
            </Typography>
            {/* ACTION */}
            <Typography
              color="textSecondary"
              className={classes.sectionLabel}
              variant="h6"
            >
              Action
            </Typography>

            {toBePayed?.map((tbp) => (
              <>
                <Typography>{getActionType(tbp.relation_type)}</Typography>
              </>
            ))}
            {/* STATUT */}
            {payments?.map((payment: PaymentI, i: number) => (
              <>
                <Typography
                  color="textSecondary"
                  className={classes.sectionLabel}
                  variant="h6"
                >
                  <FormattedMessage
                    id="LOCATIONPLAN.TEXT_STATUT"
                    defaultMessage="Statut"
                  />
                </Typography>

                {payments.length > 1 && (
                  <Typography className={classes.oldPlanTypo}>
                    <FormattedMessage
                      id="LOCATIONPLAN.MSG_PAIEMENTNUM"
                      defaultMessage="Paiement n°"
                    />
                    {i + 1}
                  </Typography>
                )}

                {payment?.workflow_state &&
                  payment?.workflow_state === "awaiting_payment" &&
                  !isRejected && (
                    <Typography>
                      <FormattedMessage
                        id="LOCATIONPLAN.MSG_ATTENTE_PAY"
                        defaultMessage="En attente du paiement"
                      />
                    </Typography>
                  )}
                {payment?.workflow_state &&
                  payment?.workflow_state === "awaiting_validation" &&
                  !isRejected && (
                    <Typography>
                      <FormattedMessage
                        id="LOCATIONPLAN.MSG_VALIDATION_OPERATEUR"
                        defaultMessage="À valider par l'opérateur"
                      />
                    </Typography>
                  )}
                {payment?.workflow_state === "rejected" ||
                  (isRejected && (
                    <Typography color="error">
                      <FormattedMessage
                        id="LOCATIONPLAN.MSG_REJECT"
                        defaultMessage="Rejeté"
                      />
                    </Typography>
                  ))}
              </>
            ))}
            {/* PRODUCT */}
            <Typography
              color="textSecondary"
              className={classes.sectionLabel}
              variant="h6"
            >
              <FormattedMessage
                id="LOCATIONPLAN.TEXTE_PAIEMENT"
                defaultMessage="Paiement"
              />
            </Typography>
            {toBePayed?.map((tbp) => (
              <div key={tbp.id}>
                <Typography color="textSecondary">
                  {tbp.relation_type === "add_sponso"
                    ? intl.formatMessage({
                        id: "LOCATIONPLAN.SPONSORISATION",
                        defaultMessage: "Sponsorisation",
                      })
                    : intl.formatMessage({
                        id: "LOCATIONPLAN.PLAN_1",
                        defaultMessage: "Plan",
                      })}
                </Typography>
                {tbp.relation_type !== "add_sponso" && (
                  <Typography>
                    <FormattedMessage
                      id="LOCATIONPLAN.TEXTE_PLAN"
                      defaultMessage="Plan"
                    />{" "}
                    {tbp.relation_value}
                  </Typography>
                )}
                {tbp.duration_in_months && (
                  <Typography>
                    {tbp.duration_in_months}{" "}
                    <FormattedMessage
                      id="LOCATIONPLAN.TEXTE_MOIS"
                      defaultMessage="mois"
                    />
                  </Typography>
                )}
                {tbp.duration_in_months && (
                  <Typography>
                    <FormattedMessage
                      id="LOCATIONPLAN.TEXTE_PRIX"
                      defaultMessage="Prix:"
                    />{" "}
                    {tbp.displayed_price}
                  </Typography>
                )}
                {tbp.salesperson?.first_name && tbp.salesperson?.last_name && (
                  <Typography>
                    <FormattedMessage
                      id="LOCATIONPLAN.TEXTE_COMMERCIAL"
                      defaultMessage="Commercial: "
                    />
                    {tbp.salesperson.first_name} {tbp.salesperson.last_name}
                  </Typography>
                )}
              </div>
            ))}

            {/* CODES */}
            {payments?.map((payment: PaymentI, i: number) => (
              <div key={payment.id} className={classes.sectionLabel}>
                {payments.length > 1 && (
                  <Typography className={classes.oldPlanTypo}>
                    Paiement n°{i + 1}
                  </Typography>
                )}
                <div className={classes.oldPlanTypo}>
                  <Typography className={classes.bold}>Total</Typography>
                  <Typography>{payment.displayed_price}</Typography>
                </div>
                {process.env.REACT_APP_CLIENT === "mtn-benin" && (
                  <div className={classes.oldPlanTypo}>
                    <Typography className={classes.bold}>
                      <FormattedMessage
                        id="LOCATIONPLAN.NUM_MOMOPAY"
                        defaultMessage="Numéro MoMoPay"
                      />
                    </Typography>
                    <Typography>{configuration?.momocode}</Typography>
                  </div>
                )}

                {payment?.payment_method === "default" &&
                  payment?.workflow_state === "awaiting_validation" && (
                    <>
                      <Typography className={classes.bold}>
                        {appConfig.default_payment_wording.momo_transaction_id}
                      </Typography>
                      <Typography color="error">
                        {payment?.momo_transaction_id}
                      </Typography>
                      <Typography className={classes.bold}>
                        {appConfig.default_payment_wording.momo_phone_used}
                      </Typography>
                      <Typography color="error">
                        {payment?.momo_phone_used}
                      </Typography>
                    </>
                  )}

                <div className={classes.buttons}>
                  <Typography color="textSecondary" variant="h6">
                    Validation
                  </Typography>

                  <Button
                    disabled={
                      payment.workflow_state !== "awaiting_validation" ||
                      isValid ||
                      isRejected
                    }
                    onClick={() => handleValidate(payment.id)}
                    className={classes.btn}
                    variant="contained"
                    color="secondary"
                  >
                    <FormattedMessage
                      id="LOCATIONPLAN.BTN_VALID"
                      defaultMessage="Valider"
                    />
                  </Button>

                  <RejectModal
                    disabeld={Boolean(
                      payment.workflow_state !== "awaiting_validation"
                    )}
                    isValid={isValid}
                    isRejected={isRejected}
                    id={payment?.id}
                    model="payments"
                    setAlertReject={setIsRejected}
                  />

                  {isValid && (
                    <Alert className={classes.alert} severity="success">
                      <FormattedMessage
                        id="LOCATIONPLAN.ALERTE_VALID_PAYMENT"
                        defaultMessage="Le paiement est validé"
                      />
                    </Alert>
                  )}

                  {isRejected && (
                    <Alert className={classes.alert} severity="success">
                      <FormattedMessage
                        id="LOCATIONPLAN.ALERTE_REJECT_PAYMENT"
                        defaultMessage="L'email a bien été envoyé. le paiement est rejeté"
                      />
                    </Alert>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Paper>
      )}

      {futurProducts && futurProducts?.length > 0 && (
        <Paper className={classes.paper}>
          <div className={classes.form}>
            {futurProducts && futurProducts?.length > 0 && (
              <>
                <Typography className={classes.sectionLabel} variant="h5">
                  <FormattedMessage
                    id="LOCATIONPLAN.SOUSCRIP_FUTUR"
                    defaultMessage="Souscriptions futures"
                  />
                </Typography>
                {futurProducts?.map((fp) => (
                  <>
                    <ProductInfos product={fp} />
                  </>
                ))}
              </>
            )}
          </div>
        </Paper>
      )}

      {inactiveProducts && inactiveProducts.length > 0 && (
        <Paper className={classes.paper}>
          <div className={classes.form}>
            {inactiveProducts && inactiveProducts.length > 0 && (
              <>
                <Typography className={classes.sectionLabel} variant="h5">
                  <FormattedMessage
                    id="LOCATIONPLAN.SOUSCRIP_PASSE"
                    defaultMessage="Souscriptions passées"
                  />
                </Typography>
                {inactiveProducts?.map((ip) => (
                  <>
                    <ProductInfos product={ip} />
                  </>
                ))}
              </>
            )}
          </div>
        </Paper>
      )}
    </>
  );
};

export default LocationPlan;
