import React, { Ref, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import { cx, css } from 'emotion'


interface BaseProps {
  className: string
  [key: string]: unknown
}
type OrNull<T> = T | null

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean
        reversed: boolean
      } & BaseProps
    >,
    ref: any
  ) => (
      <span
        {...props}
        ref={ref}
        className={cx(
          className,
          css`
          cursor: pointer;
          color: ${reversed
              ? active
                ? 'white'
                : '#aaa'
              : active
                ? 'black'
                : '#ccc'};
        `
        )}
      />
    )
)

export const EditorValue = React.forwardRef(
  (
    {
      className,
      value,
      ...props
    }: PropsWithChildren<
      {
        value: any
      } & BaseProps
    >,
    ref: any
  ) => {
    const textLines = value.document.nodes
      .map((node: any) => node.text)
      .toArray()
      .join('\n')
    return (
      <div
        ref={ref}
        {...props}
        className={cx(
          className,
          css`
            margin: 30px -20px 0;
          `
        )}
      >
        <div
          className={css`
            font-size: 14px;
            padding: 5px 20px;
            color: #404040;
            border-top: 2px solid #eeeeee;
            background: #f8f8f8;
          `}
        >
          Slate's value as text
        </div>
        <div
          className={css`
            color: #404040;
            font: 12px monospace;
            white-space: pre-wrap;
            padding: 8px 16px;
            div {
              margin: 0 0 0.5em;
            }
          `}
        >
          {textLines}
        </div>
      </div>
    )
  }
)

export const Icon = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: any
  ) => (
      <span
        {...props}
        ref={ref}
        className={cx(
          'material-icons',
          className,
          css`
          font-size: 18px;
          vertical-align: text-bottom;
        `
        )}
      />
    )
)

export const Instruction = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: any
  ) => (
      <div
        {...props}
        ref={ref}
        className={cx(
          className,
          css`
          white-space: pre-wrap;
          margin: 0 -20px 8px;
          padding: 8px 16px;
          font-size: 14px;
          background: #f8f8e8;
        `
        )}
      />
    )
)

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: any
  ) => (
      <div
        {...props}
        ref={ref}
        className={cx(
          className,
          css`
          & > * {
            display: inline-block;
          }
          & > * + * {
            margin-left: 16px;
          }
        `
        )}
      />
    )
)

export const Portal = ({ children }: { children: any }) => {
  return ReactDOM.createPortal(children, document.body)
}

export const Toolbar = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
      <Menu
        {...props}
        ref={ref}
        className={cx(
          className,
          css`
          position: relative;
          padding: 1px 16px 8px;
          margin: 0 -20px;
          margin-bottom: 8px;
        `
        )}
      />
    )
)
