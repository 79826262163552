import React from "react";
import { useQuery } from "react-query";
import { fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import { Article } from "../../types";
import { ArticleDisplayPreview } from "../../types/article";
import { isNotAuthorized } from "../../utils";
import PhonePreview from "../shared/PhonePreview";

export default function ServicesPhone() {
  const authentication = useUserContext();

  // REQUEST (à partir des états et de columns)
  const fetchObject = {
    fields: {
      articles: ["image", "id", "position", "displayed_status", "format", "title", "headline"],
    },
    page: {
      number: 1,
      size: 50,
    },
    filters: { firebase_collection: "Services", displayed_status: "online" },
    sort: "position"
  };


  const { data, status } = useQuery<{
    results: ArticleDisplayPreview[];
    pageCount: number;
    total: number;
  }>(
    ["services", fetchObject],
    () => fetchList("articles", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e:any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  return  <PhonePreview queryKey={["services", fetchObject]} articles={data?.results} collection="Services" />;
}
