import React, { useContext, useState } from "react";
import {
  Button,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Location } from "../../types";
import { UserContext } from "../../authentication";
import moment from "moment";
import appConfig from "../../appConfig";
import { useLocation as useRouterLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { FormattedMessage } from "react-intl";

require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    container: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
    typo: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    typoBold: {
      fontWeight: 'bold',
    },
    typoItalic: {
      fontStyle: "italic",
    },
  })
);


type Props = {
  comments: Location["comments"];
};

const LocationComments = ({ comments }: Props) => {
  moment.locale(appConfig.locale);
  const classes = useStyles();
  const authentication = useContext(UserContext);
  const queryClient = useQueryClient()
  // on reverse l'array de commentaire pour l'avoir du plus récents au plus vieux
  // dates.sort((date1, date2) => new Date(date1).setHours(0, 0, 0, 0) - new Date(date2).setHours(0, 0, 0, 0));

  const reversedComs = comments?.sort((a, b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf())


  // on touve l'id de la location
  const { pathname } = useRouterLocation();
  const findPathId = pathname.match(/([1-9][0-9]*)/);
  const locationId = findPathId && findPathId[0];


  // formatage de la et de l'heure
  const formatDate = (date: string) => {
    return moment(date).format("l");
  };
  const formatHour = (date: string) => {
    return moment(date).format("LT");
  };

  // pour l'input du commentaire
  const [newComment, setNewComment] = useState("")
  const handleChange = (value: string) => {
    setNewComment(value)
  }


  const mutation = useMutation(
    (comment: any) => fetch(`${appConfig.API}/locations/${locationId}/add_comment?text=${comment}`, {
      method: "POST",
      headers: {
        ...(authentication?.accessToken
          && {
          Authorization: authentication?.accessToken
        }),
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      }
    }),
    {
      onError: (e: any) => {
        console.log(e)
      },
      onSuccess: () => {
        setNewComment("");
      },
      onSettled: () => {
        queryClient.invalidateQueries();
      },
    }
  );


  return (
    <>
      <Paper className={classes.paper}>
        <Typography
          className={classes.title}
          color="textSecondary"
          variant="h6"
        >
            <FormattedMessage
            id="LOCATIONCOMMENTS.MSG_ADDCOMMENT"
            defaultMessage="Ajouter un commentaire"/>
        </Typography>
        <div className={classes.container}>
          <TextField
            // className={classes.textField}
            multiline
            rows={4}
            value={newComment}
            // defaultValue={newComment}
            onChange={(event) => handleChange(event.target.value)}
            label={
              <FormattedMessage
              id="LOCATIONCOMMENTS.WRITE_COMMENT"  
              defaultMessage="Écrivez votre commentaire"/>}
          />
        </div>
        <Button
          // disabled={isLoading.status && isLoading.ownerId === owner.id}
          variant="contained"
          color="secondary"
          onClick={() => mutation.mutate(newComment)}
        >
          {/* {isLoading.status && isLoading.ownerId === owner.id && <CircularProgress className={classes.loader} size={20} />} */}
          <FormattedMessage
            id="LOCATIONCOMMENTS.BTN_PUBLICOMMENT"
            defaultMessage="Publier un commentaire"/>
        </Button>
      </Paper>


      <Paper className={classes.paper}>
        <Typography
          className={classes.title}
          color="textSecondary"
          variant="h6"
        >
            <FormattedMessage
            id="LOCATIONCOMMENTS.MSG_COMMENT_LOGS"
            defaultMessage="Commentaires et Logs"/>
        </Typography>
        <ul>
          {reversedComs?.length !== 0 && reversedComs?.map(comment => <li key={comment.id} className={classes.container}>

            <Typography className={classes.typo}>
              <span className={classes.typoBold}>{comment.bot ? "Log" : "Commentaire"} </span>
              <FormattedMessage
                id="LOCATIONCOMMENTS.CREATED_AT"
                defaultMessage="le {date} à {hour}"
                values={{ date: formatDate(comment.created_at), hour: formatHour(comment.created_at) }}
              />
            </Typography>

            <Typography className={classes.typo}>
              <span className={classes.typoItalic}>{comment.text}</span>{" "}
              <FormattedMessage id="LOCATIONCOMMENTS.FROM" defaultMessage="par"/>{" "}
              {comment?.user?.email}
            </Typography>
          </li>)}
        </ul>
      </Paper>

    </>
  );
};

export default LocationComments;
