import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useImage } from "../../firebase";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Article } from "../../types";
import FBImage from "./FBImage";
import { ArticleDisplayPreview } from "../../types/article";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerContainer: {
      width: "100%",
      // height: 150,
      // backgroundColor: "blue",
      // marginBottom: theme.spacing(2),
    },
    banner: {
      width: "100%",
      // height: 360,
      margin: "0 auto",
    },
    textBg: {
      bottom: 0,
      width: "100%",
      textAlign: "center",
      position: "absolute",
      backgroundColor: "rgba(242,242,242,.85)",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  })
);

type Props = {
  articles: ArticleDisplayPreview[] | null;
};

const PhoneBanner = ({ articles }: Props) => {
  const classes = useStyles();

  // const imageUrl = useImage(banner.image);





  const renderIndicator = (
    clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    index: number,
    label: string
) => {
  const indicatorStyles = {
    marginBottom: -10,
    marginRight: 4,
    marginLeft: 4,
    background: 'black',
    width: 6,
    height: 6,
    display: 'inline-block',
    borderRadius: 4,
    opacity: isSelected ? 1 : 0.2
};
  return (
    <div style={indicatorStyles}>
    </div>
  )
};

  return (
    <div className={classes.bannerContainer}>
      {articles && <Carousel
        renderIndicator={renderIndicator}
        // renderIndicator={(clickHandler, isSelected, index) => (
        //   <div style={{ height: 10, width: 10, backgroundColor: "blue" }}></div>
        // )}
        showThumbs={false}
        infiniteLoop
        autoPlay
        showStatus={false}
        showArrows={false}
      >
        {articles.map((article) => (
          <div className={classes.banner}>
            <FBImage
              style={{ height: 240, objectFit: "cover" }}
              image={`${article.image}_360x360`}
            />
            <div style={{ backgroundColor: "red" }}>
              <Typography className={classes.textBg}>
                {article.title}
              </Typography>
            </div>
          </div>
        ))}
      </Carousel>}
    </div>
  );
};

export default PhoneBanner;
