import React from "react";
import { Toolbar, Tabs, Tab, Divider } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      color: theme.palette.tab.main
    },
  })
  );

type Props = {
  tabs: { label: string; path: string }[];
};


function RouterTabs({ tabs }: Props) {
  const classes = useStyles();

  const { pathname } = useLocation();
  const history = useHistory();
  const paths = tabs.map((tab) => tab.path);
  const active = paths.indexOf(pathname);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    history.push(tabs[newValue].path);
  };
  if (active === -1) return null;
  return (
    <div className={classes.tab}>
      <Toolbar />
      <Tabs
        value={active}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {tabs.map((tab) => (
          <Tab key={tab.label} label={tab.label} />
        ))}
      </Tabs>
      <Divider />
    </div>
  );
}

export default RouterTabs;
