import React, { useState, useEffect } from "react";
import {
  Drawer as MaterialDrawer,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  DrawerProps,
} from "@material-ui/core";
import {
  Whatshot,
  LocalPlay,
  Storefront,
  History,
  TrendingUp,
  Build,
  Assessment,
} from "@material-ui/icons/";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  useLocation as useRouterLocation,
  Link as RouterLink,
} from "react-router-dom";
import { useUserContext } from "../authentication";
import appConfig from "../appConfig";
import { FormattedMessage } from "react-intl";

const drawerWidth = 190;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      marginTop: theme.spacing(2),
      overflow: "auto",
    },
    active: {
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    inactive: {
      color: theme.palette.text.disabled,
    },
  })
);

function Drawer(props: DrawerProps) {
  const [isActive, SetIsActive] = useState("offers");
  const { pathname } = useRouterLocation();
  const classes = useStyles();
  const authentication = useUserContext();

  useEffect(() => {
    if (pathname.search(/(offers)/g) === 1) {
      SetIsActive("offers");
    } else if (pathname.search(/(services)/g) === 1) {
      SetIsActive("services");
    } else if (pathname.search(/(stories)/g) === 1) {
      SetIsActive("stories");
    } else if (
      pathname.search(/(locations)/g) === 1 ||
      pathname.search(/(location_statistics)/g) === 1 ||
      pathname.search(/(categories)/g) === 1
    ) {
      SetIsActive("locations");
    } else if (pathname.search(/(statistiques)/g) === 1) {
      SetIsActive("statistiques");
    } else if (pathname.search(/(reporting)/g) === 1) {
      SetIsActive("reporting");
    } else if (
      pathname.search(/(salespeople)/g) === 1 ||
      pathname.search(/(administrators)/g) === 1 ||
      pathname.search(/(plans)/g) === 1 ||
      pathname.search(/(settings)/g) === 1
    ) {
      SetIsActive("admin");
    }
  }, [pathname]);

  const Icon = ({ section }: {section: string}) => {    
    switch (section) {
      case "offers":
        return <Whatshot/>;
      case "services":
        return <LocalPlay/>;
      case "news":
        return <History/>;
      case "directory":
        return <Storefront/>;
      default:
        return <Storefront/>;
    }
  };
  


  return (
    <MaterialDrawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {Object.entries(appConfig.sections).map(([key, value]) => (
            <div key={key}>
              <ListItem
                button
                component={RouterLink}
                to={`/${key === "news" ? "stories" : key === "directory" ? 'locations' : key}`}
              >
                <ListItemIcon>
                  <Icon section={key} />
                </ListItemIcon>
                  {(isActive === "stories" && key === 'news') || (isActive === "locations" && key === 'directory') || isActive === key ? (
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.active} variant="body1">
                          {value}
                        </Typography>
                      }
                    />
                  ) : (
                    <ListItemText
                      className={classes.inactive}
                      primary={value}
                    />
                  )}
              </ListItem>
              
            </div>
          ))}

          <Divider />

          <ListItem button component={RouterLink} to="/statistiques">
            <ListItemIcon>
              <TrendingUp />
            </ListItemIcon>

            <ListItemText
              disableTypography={isActive === "statistiques"}
              primary={
                <Typography
                  className={
                    isActive === "statistiques"
                      ? classes.active
                      : classes.inactive
                  }
                  {...(isActive === "statistiques" && { variant: "body1" })}
                >
                  <FormattedMessage
                    id="DRAWER.STATISTIQUES"
                    defaultMessage="Statistiques"
                  />
                </Typography>
              }
            />
          </ListItem>
          {authentication.user && authentication.user.can_get_reportings && (
            <ListItem button component={RouterLink} to="/reporting">
              <ListItemIcon>
                <Assessment />
              </ListItemIcon>
              <ListItemText
                disableTypography={isActive === "reporting"}
                primary={
                  <Typography
                    className={
                      isActive === "reporting"
                        ? classes.active
                        : classes.inactive
                    }
                    {...(isActive === "reporting" && { variant: "body1" })}
                  >
                    Reporting
                  </Typography>
                }
              />
            </ListItem>
          )}
          {authentication?.user?.superadmin && (
            <ListItem button component={RouterLink} to="/salespeople">
              <ListItemIcon>
                <Build />
              </ListItemIcon>

              {isActive === "admin" ? (
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.active} variant="body1">
                      Administration
                    </Typography>
                  }
                />
              ) : (
                <ListItemText
                  className={classes.inactive}
                  primary="Administration"
                />
              )}
            </ListItem>
          )}
        </List>
      </div>
    </MaterialDrawer>
  );
}



export default Drawer;
