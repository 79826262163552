import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Article, Action } from '../../types'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    simpleSelect: {
      marginTop: theme.spacing(1),
      width: "45%"

    },
    formControl: {
      width: "100%"
    }
  }))

function SimpleSelect<O extends Article | Action, K extends Extract<keyof O, string>>({ index, object, objectKey, value, title, setValue, items }: {
  index?: number,
  object: O,
  objectKey: K,
  value: Extract<O[K], string | number | null>,
  title: string,
  items: {
    readonly value: Extract<O[K], string | number>;
    name: string;
  }[]
  setValue: (x: Article | Action, index?: number) => void;
}) {
  const classes = useStyles()

  const handleSelectValue = (value: Extract<O[K], string | number | null>) => {
    if (index === undefined) {
      const newObject = { ...object, [objectKey]: value } as Article;
      setValue(newObject)
    } else if (index !== undefined) {
      const newObject = {...object, [objectKey]: value} as Action;
      setValue(newObject, index)
  
    }
  }

  return (
    <FormControl className={classes.simpleSelect} >
      <InputLabel required id={`${objectKey}-select-label`}>{title}</InputLabel>
      <Select
        labelId={`${objectKey}-select-label`}
        id={`${objectKey}-select`}
        value={value ? value : ""}
        onChange={event => handleSelectValue(event.target.value as Extract<O[K], string | number | null>)}
      >
        {items.map(item => (
          <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SimpleSelect
