// src/firebase.js

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import appConfig from "./appConfig";// Add the Firebase products that you want to use
import "firebase/firestore";
import 'firebase/storage';

import { useState, useEffect } from "react";

import { WhereFilterOp } from "@firebase/firestore-types";
import { Collection, Article, Location, LocationNewsItem } from "./types";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = appConfig.firebaseConfig

firebase.initializeApp(firebaseConfig);

export const { firestore } = firebase;

const storageRef = firebase.storage().ref();

export const useImage = (name: string) => {
  const [image, setImage] = useState<string>("");
  useEffect(() => {
    if (name && name.length > 0) {
      const getImage = async () => {
        try {
          const storedImage = await storageRef.child(name).getDownloadURL();
          setImage(storedImage);
        } catch { }
      };
      getImage();
    }
  }, [name]);

  return image;
};

export const uploadImage = (file: any, name: string): any => {
  // if (file) {
  const ref = storageRef.child(name);
  const task = ref.put(file).then(function (snapshot) {
    //      console.log("Uploaded a blob or file!");
    // });
  })
  return task

};


export const flatten = (document: firebase.firestore.DocumentSnapshot) => {
  return {
    ...document.data(),
    collection: document.ref.parent.path,
    id: document.id,
  };
};

const getSelectedData = async (
  collection: string,
  where: [string, WhereFilterOp, any]
) => {
  return await firestore()
    .collection(collection)
    .where(...where)
    .get()
    .catch((err) => console.log(err));
};

export const getCollection = async (collection: string) => {
  return await firestore()
    .collection(collection)
    .get()
    .catch((err) => console.log(err));
};

export const getOneDocument = async (
  collection: string,
  documentId: string
) => {
  return await firestore()
    .collection(collection)
    .doc(documentId)
    .get()
    .catch((err) => console.log(err));
};

// FirebaseFetchType retrouve le type en fonction d'une string indiquant la collection
type FirebaseFetchType<C> = C extends "Services" | "Offers" | "News"
  ? Article | null
  : C extends "Locations"
  ? Location | null
  : C extends "LocationNews"
  ? LocationNewsItem | null
  : null;

// Récupérer un document et le mettre en forme
export const getDocument = async <C extends Collection>({
  collection,
  id,
  parentId,
}: {
  collection: C;
  id: string;
  parentId?: string;
}): Promise<FirebaseFetchType<C>> => {
  // Les subcollections doivent être prises en charge à part pour que typescript s'y retrouve
  if (collection === "LocationNews") {
    const locationNewsItem = await getOneDocument(
      `Locations/${parentId}/LocationNews`,
      id
    );
    return (locationNewsItem && locationNewsItem.exists
      ? flatten(locationNewsItem)
      : null) as FirebaseFetchType<C>;
  } else {
    const document = await getOneDocument(collection, id);
    return (document && document.exists
      ? flatten(document)
      : null) as FirebaseFetchType<C>;
  }
};

type FirebaseArrayFetchType<C> = C extends "Services" | "Offers" | "News"
  ? Article[] | []
  : C extends "Locations"
  ? Location[] | []
  : C extends "LocationNews"
  ? LocationNewsItem[] | []
  : [];

export const getDocumentList = async <C extends Collection>({
  collection,
  where,
  parentId,
}: {
  collection: C;
  where?: [string, WhereFilterOp, any];
  parentId?: string;
}): Promise<FirebaseArrayFetchType<C>> => {
  // Les subcollections doivent être prises en charge à part pour que typescript s'y retrouve
  if (collection === "LocationNews") {
    return (await fetchCollection(
      `Locations/${parentId}/LocationNews`,
      where
    )) as FirebaseArrayFetchType<C>;
  } else {
    return (await fetchCollection(collection, where)) as FirebaseArrayFetchType<
      C
    >;
  }
};

// Helpers
export const getReference = (collection: string, id: string) =>
  firestore().collection(collection).doc(id);

export async function fetchCollection(
  collection: string,
  where?: [string, WhereFilterOp, any]
) {
  const query = where
    ? await getSelectedData(collection, where)
    : await getCollection(collection);

  return query ? query.docs.map(flatten) : [];
}

// ----- DATA HOOKS -------
// Différenciés car typés

// export const useCategories = (where?: [string, WhereFilterOp, any]) => {
//   const [data, setData] = useState<any[]>([]);
//   useEffect(() => {
//     const asyncQuery = async () => {
//       const results = await getDocumentList({
//         collection: "LocationCategories",
//         where,
//       });
//       setData(results);
//     };
//     asyncQuery();
//   }, []);
//   return data;
// };

export const useNews = (where?: [string, WhereFilterOp, any]) => {
  const [data, setData] = useState<Article[]>([]);
  useEffect(() => {
    const asyncQuery = async () => {
      const results = await getDocumentList({
        collection: "Stories",
        where,
      });
      setData(results);
    };
    asyncQuery();
  }, []);
  return data;
};

export const useOffers = (where?: [string, WhereFilterOp, any]) => {
  const [data, setData] = useState<Article[]>([]);
  useEffect(() => {
    const asyncQuery = async () => {
      const results = await getDocumentList({
        collection: "Offers",
        where,
      });
      setData(results);
    };
    asyncQuery();
  }, []);
  return data;
};

export const useServices = (where?: [string, WhereFilterOp, any]) => {
  const [data, setData] = useState<Article[]>([]);
  useEffect(() => {
    const asyncQuery = async () => {
      const results = await getDocumentList({
        collection: "Services",
        where,
      });
      setData(results);
    };
    asyncQuery();
  }, []);
  return data;
};

export const useLocations = (where?: [string, WhereFilterOp, any]) => {
  const [data, setData] = useState<Location[]>([]);
  useEffect(() => {
    const asyncQuery = async () => {
      const results = await getDocumentList({
        collection: "Locations",
        where,
      });
      setData(results);
    };
    asyncQuery();
  }, []);
  return data;
};

export const useLocationNews = (
  locationId: string,
  where?: [string, WhereFilterOp, any]
) => {
  const [data, setData] = useState<LocationNewsItem[]>([]);
  useEffect(() => {
    const asyncQuery = async () => {
      const results = await getDocumentList({
        collection: `LocationNews`,
        parentId: locationId,
        where,
      });
      setData(results);
    };
    asyncQuery();
  }, []);
  return data;
};
