import React, { useEffect, useState } from "react";
import { CssBaseline, Container } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { IntlProvider, MessageFormatElement } from "react-intl";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  createStyles,
  makeStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { UserContext, useAuth } from "./authentication";
import { ConfigurationContext, useConf } from "./configurationContext";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import Logger from "./components/Logger";
import Main from "./components/Main";
import MTNLogoFont from "./fonts/MTNBrighterSans-MediumItalic.otf";
import appConfig from "./appConfig";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    navBar: Palette["primary"];
    tab: Palette["primary"];
  }
  interface PaletteOptions {
    navBar: PaletteOptions["primary"];
    tab: PaletteOptions["primary"];
  }
}


const theme = createTheme({
  palette: {
    tab: {
      main: appConfig.colors.tabFont,
    },
    navBar: {
      main: appConfig.colors.navBarBg,
      contrastText: appConfig.colors.navBarFont,
    },
    secondary: {
      main: appConfig.colors.btnBg,
      light: appConfig.colors.navBarBg,
    },
    primary: {
      main: "#212121",
    },
  },
  typography: {
    fontFamily: "Roboto, MTN",
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       "@font-face": [mtn],
  //     },
  //   },
  // },
});

const drawerWidth = 190;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "100vw",
      marginLeft: drawerWidth + theme.spacing(3),
      marginTop: theme.spacing(6),
    },
  })
);

const queryClient = new QueryClient();

function App() {
  // dynamic favicon and title
  function getFaviconEl(): any {
    return document.getElementById("favicon");
  }
  function getTitleEl(): any {
    return document.getElementById("title");
  }
  const favicon = getFaviconEl();
  favicon.href = appConfig.brand.favicon;
  const title = getTitleEl();
  title.innerText = appConfig.brand.name;

  const locale = process.env.REACT_APP_LOCALE || appConfig.locale
  const [localeData, setLocaleData] = useState<Record<
    string,
    MessageFormatElement[]
  > | null>(null);

  useEffect(() => {
    async function loadLocaleData() {
      const messages = () => import(`../${process.env.NODE_ENV !== 'development' ? 'compiled-' : ''}lang/${locale}.json`);
      setLocaleData(await messages());
    }
    loadLocaleData();
  }, []);

  
  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        {localeData && (
          <IntlProvider
            messages={localeData}
            locale={locale}
            defaultLocale="en"
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter>
                <Authenticator />
              </BrowserRouter>
            </ThemeProvider>
          </IntlProvider>
        )}
      </DndProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
// Parcequ'on doit set le queryclient avant l'auth
const Authenticator = () => {
  const classes = useStyles();
  const authentication = useAuth();
  const configuration = useConf({ authentication });
  return (
    <UserContext.Provider value={authentication}>
      {authentication.initialized && (
        <Switch>
          <Route exact path="/authentication">
            <Logger />
          </Route>
          {!authentication.accessToken && (
            <Redirect
              to={{
                pathname: "/authentication",
              }}
            />
          )}
          {authentication.user && authentication.user.admin && (
            <ConfigurationContext.Provider value={configuration}>
              <Route path="/">
                <AppBar />
                <Drawer variant="permanent" />
                <Container className={classes.mainContainer}>
                  <Main />
                </Container>
              </Route>
            </ConfigurationContext.Provider>
          )}

          <Redirect
            exact
            from="/"
            to={`/${Object.keys(appConfig.sections)[0]}`}
          />
        </Switch>
      )}
    </UserContext.Provider>
  );
};

export default App;
