import React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SortingRule } from "react-table";

import { fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Table from "../Table";
import ColoredTag from "../shared/ColoredTag";
import moment from "moment";
import { getSortValue, isNotAuthorized } from "../../utils";
import { useQuery } from "react-query";
import { Article } from "../../types";
import TableActionBar from "../TableActionBar";
import { Alert } from "@material-ui/lab";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    spinner: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    link: {
      // fontWeight: 500,
      // cursor: "pointer",
    },
  })
);

function LocationNewsTable() {
  const classes = useStyles();
  const authentication = useUserContext();
  const intl = useIntl();

  // ROUTING
  const { path, params, url } = useRouteMatch<{ id: string }>();
  const { state } = useLocation<{
    sortBy: SortingRule<object>[];
    pageSize: number;
    pageIndex: number;
  } | null>();
  // NAVIGATION STATE
  // Les éléments liés à la navigation sont tiré de l'état de location
  // Où ils sont directement définis par Table || ou par un Link / goBack() etc avec state
  const pageIndex = state?.pageIndex ? state.pageIndex : 0;
  const pageSize = state?.pageSize ? state.pageSize : 10;
  const sortBy = state?.sortBy
    ? state.sortBy
    : [{ id: "displayed_status", desc: false }];

  // COMPONENT STATE
  // État lié au composant (indépendant de la navigation)
  // const [selectionList, setSelectionList] = useState<string[]>([]);
  // const [isSelectionNegative, setIsSelectionNegative] = useState(false);

  const history = useHistory();

  // DATA
  // définition des headers
  const columns = [
    { accessor: "title", Header: intl.formatMessage({id : 'LOCATIONNEWSTABLE.HEADER_TITRE',defaultMessage :"Titre"}), width: "100" },
    {
      accessor: "displayed_status",
      Header: intl.formatMessage({id :'LOCATIONNEWSTABLE.HEADER_STATUT',defaultMessage:"Statut"}),
    },
    {
      accessor: "created_at",
      Header: intl.formatMessage({id:'LOCATIONNEWSTABLE.HEADER_CREELE',defaultMessage :"Crée le"}),
    },
    {
      accessor: "editor",
      Header: intl.formatMessage({id:'LOCATIONNEWSTABLE.HEADER_AUTEUR',defaultMessage :"Auteur"}),
      sortValue: "editor.name",
    },
    {
      accessor: "id",
      Header: "Id",
      disableSortBy: true,
    },
  ];

  // REQUEST (à partir des états et de columns)
  const fetchObject = {
    include: ["editor"],
    fields: {
      users: ["name", "id"],
    },
    page: {
      number: pageIndex + 1,
      size: pageSize,
    },
    filters: { firebase_collection: "LocationNews", location_id: params.id },
    sort: getSortValue(sortBy[0], columns),
  };

  interface ArticlePreview extends Partial<Article> {
    editor: { name: string; id: string };
  }
  const { data, status } = useQuery<{
    results: ArticlePreview[];
    pageCount: number;
    total: number;
  }>(
    ["locationNews", fetchObject],
    () => fetchList("articles", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e:any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  // const { data: plopek } = useArticles({
  //   authentication,
  //   collection: "Offers",
  // });

  const formatDate = (date: any) => {
    return moment(date).format("L");
  };

  const transformedData = React.useMemo(() => {
    if (data?.results) {
      return data.results.map((locationNews: any) => ({
        title: (
          // <TableLink
          //   onClick={() => {
          //     history.push(`${url}/location_news/${locationNews.id}`, {
          //       action: "edit",
          //     });
          //   }}
          // >
            // {
              locationNews.title ? locationNews.title : intl.formatMessage({id:'LOCATIONNEWSTABLE.MSG_SANSTITRE',defaultMessage:"sans titre"})
            // }
          // </TableLink>
        ),
        displayed_status: <ColoredTag value={locationNews.displayed_status} />,
        created_at: formatDate(locationNews.created_at),
        editor: locationNews?.editor?.name,
        id: locationNews.id,
      }));
    } else {
      return [];
    }
  }, [data]);
  // pagination
  const pageCount =
    status === "success" && data?.pageCount ? data.pageCount : 0;
  const totalElements = status === "success" ? data?.total : undefined;

  const actionBar = (
    <TableActionBar title="Actualités d'établissement">
      {/* <Button
        variant="contained"
        color="secondary"
        endIcon={<AddIcon />}
        onClick={() => {
          history.push(`${path}/create`, {
            action: "create",
          });
        }}
      >
        Créer
      </Button> */}
    </TableActionBar>
  );

  return (
    <div className={classes.container}>
      {transformedData.length === 0 ? (
        <Alert severity="info">
          <FormattedMessage
          id="LOCATIONNEWSTABLE.ALERTE_NONE_ACTU"
          defaultMessage="Cet établissement n'a pas encore d'actualités"/>
        </Alert>
      ) : (
        <Table
          data={transformedData}
          columns={columns}
          initialPageIndex={pageIndex}
          initialPageSize={pageSize}
          initialSortBy={sortBy}
          pageCount={pageCount}
          totalElements={totalElements}
          actionBar={actionBar}
        />
      )}
    </div>
  );
}

export default LocationNewsTable;
